<script setup></script>

<template>
  <div class="pt-32 text-paper">
    <input
      type="search"
      placeholder="Søk etter begreper"
      class="mb-6 w-full border-b-2 border-paper bg-blue-100 p-4 placeholder-paper"
    />
    <template
      v-for="char in ['A', 'B', 'C', 'D', 'E', 'F']"
      :key="char"
    >
      <h2 class="font-national2compressed text-9xl font-extrabold">
        {{ char }}
      </h2>
      <ul class="text-2xl">
        <li v-for="n in 5" :key="n" class="mb-6">
          <strong class="font-extrabold">Pseudovitenskap</strong> en
          ikke-vitenskapelig praksis som utgir seg for å være
          vitenskapelig. Kalles også kvasivitenskap eller falsk
          vitenskap.
        </li>
      </ul>
    </template>
  </div>
</template>

<route lang="yaml">
name: begreper
meta:
  layout: product
</route>

import type { ProductPage } from "./../../types/productPages.d"

import GraphqlProductPageDocument from "../../graphql/documents/productPage"

import { graphqlClient } from "../../graphql/client"

export default async (
  urlAlias: string,
): Promise<ProductPage | null> => {
  const { data } = await graphqlClient({
    query: GraphqlProductPageDocument,
    variables: {
      urlAlias,
    },
  })

  const query = data._repository.location

  if (!query) return null

  const { id, priority, content, urlAliases } = query
  const { title, header, theme } = content

  return {
    id,
    priority,
    title,
    urlAliases,
    header,
    theme,
    route: {
      name: title.toLowerCase(),
      path: `/${title.toLocaleLowerCase().replace(/ /g, "-")}`,
    },
  }
}

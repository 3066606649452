<script setup lang="ts">
import { watch } from "vue"

const productContent = useProductContentStore()
const personalization = usePersonalizationStore()

watch(
  () => productContent.activeArticle,
  () => {
    personalization.activeArticle = productContent.activeArticle
  },
  { immediate: true },
)

onUnmounted(() => {
  personalization.activeArticle = undefined
})
</script>

<template>
  <u-dynamic-article
    :data="productContent.activeArticle"
    :preview="false"
  />
  <u-feature-flag>
    <div
      class="absolute bottom-0 right-5 bg-paper font-national2compressed text-sm text-black"
    >
      {{ personalization.elapsedSeconds }} s
    </div>
  </u-feature-flag>
</template>

<route lang="yaml">
name: article
meta:
  layout: article
  is: article
  authorize: true
</route>

<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  currentTime: {
    type: Number as PropType<number>,
    default: 0,
  },
  max: {
    type: Number as PropType<number>,
    default: 0,
  },
})

const emit = defineEmits(["currentTimeEvent"])
const currentTimeLocal = ref(0)

watch(props, () => {
  currentTimeLocal.value = props.currentTime
})

const seek = (e: Event) => {
  const target = <HTMLInputElement>e.target
  currentTimeLocal.value = target.value as unknown as number
  emit("currentTimeEvent", currentTimeLocal.value)
}
</script>

<template>
  <input
    v-model="currentTimeLocal"
    type="range"
    :min="0"
    :max="max"
    step="0.01"
    @input="seek"
  />
</template>

<style scoped lang="postcss">
input {
  @apply h-[2px]
    w-full
    appearance-none
    bg-white
    opacity-80
    transition-opacity
    duration-200
    hover:opacity-100;
}

input::-webkit-slider-thumb {
  @apply h-[15px]
    w-[15px]
    appearance-none
    rounded-full
    bg-white;
}
</style>

<script setup lang="ts">
import type { PropType, Ref } from "vue"
import type { ResetPasswordForm } from "../../../types/auth"

/**
 * @see https://accessibilityinsights.io/info-examples/web/aria-input-field-name/
 * @see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
 */
defineProps({
  inputs: {
    type: Array as PropType<Array<any>>,
    default: () => [],
  },
})

const emit = defineEmits(["submit"])

const route = useRoute()
const router = useRouter()

const form = ref({
  username: route.query.username,
  token: route.query.code,
  password: "",
}) as Ref<ResetPasswordForm>

const error = ref({
  password: "",
})

function submit() {
  error.value.password = ""
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
  const complexity = form.value.password.match(regex)

  if (!complexity) {
    return (error.value.password =
      "Passordet må inneholde minst 8 tegn, en stor bokstav, en liten bokstav og et tall.")
  }

  emit("submit", form.value, router)
}
</script>

<template>
  <u-form>
    <u-input-group
      v-model="form.password"
      label="Nytt passord"
      placeholder="••••••"
      name="password"
      type="password"
    />

    <u-button
      shape="normal"
      class="mt-5 max-w-[219px] bg-u-contrast font-numeraWebRegular hover:border-red-40 hover:bg-red-40"
      :wave="true"
      @click="submit"
    >
      {{ "Endre passord" }}
    </u-button>
  </u-form>
</template>

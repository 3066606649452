<script setup lang="ts">
import type { PropType } from "vue"
import type { Article } from "~/types/article"
import { formatYearRange, formatDate } from "~/utilities/format"

const props = defineProps({
  keyVal: {
    type: Number as PropType<number>,
  },
  data: {
    type: Object as PropType<Article> | undefined,
    default: () => {},
  },
  navState: {
    type: Boolean as PropType<boolean>,
  },
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

provide("article", props.data)

const route = useRoute<"article">()
const router = useRouter()
const activeNote = computed(() => {
  return props.data.relations?.find(
    ({ resourceUrl }) => `#${resourceUrl}` === route.hash,
  )
})

route.meta.index = Number(props.keyVal)

const articleEl = ref<{ SIDENOTE_OFFSET: number } | null>(null)
const article = computed(() => {
  try {
    const templateName = props.data.template
    return defineAsyncComponent(
      () => import(`../UArticle/${templateName}.vue`),
    )
  } catch (e) {
    throw new Error(
      `Article template ${props.data.template} not found`,
    )
  }
})

function calcArticleOffset(): number {
  return articleEl.value?.SIDENOTE_OFFSET ?? 0
}

async function closeNote() {
  // TODO: Check if there is no back, in which case remove # from url by replace state
  router.back()
}
</script>

<template>
  <div
    v-if="!props.data"
    class="flex h-full w-full items-center justify-center bg-paper p-2 font-national2compressed text-4xl text-black"
  >
    <h1 class="h-fit w-fit">
      Beklager, vi har problemer med å vise artikkelen du etterspurte.
    </h1>
  </div>
  <div
    v-else
    :id="`article-page-${data.id}`"
    tabindex="0"
    class="grid h-full min-h-0 w-full overflow-hidden"
    :style="{
      '--sidebar-width': '36rem',
      '--offset': calcArticleOffset(),
    }"
  >
    <component
      :is="article"
      :ref="(el: any) => (articleEl = el)"
      :data="data"
      :preview="preview"
      class="col-start-1 row-start-1 min-h-full transform transition-transform"
      :class="{
        sidenoteactive: activeNote != null,
        'overflow-hidden': activeNote != null,
      }"
    />

    <transition name="sidenote" mode="out-in">
      <div
        v-if="activeNote != null"
        style="box-shadow: 0 0 8px rgba(0, 0, 0, 0.25)"
        class="sticky z-50 col-start-1 row-start-1 grid h-full grid-rows-[max-content,1fr] items-center place-self-end overflow-auto bg-grey-20 !font-national2 md:top-0 md:w-[var(--sidebar-width)] <md:fixed <md:inset-0 <md:overflow-auto <md:rounded"
        :class="{
          '!bg-yellow-20': activeNote.identifier === 'explainer',
        }"
      >
        <u-button
          class="sticky top-5 m-5 place-self-start bg-inherit"
          pill
          aria-label="Lukk sidenotat"
          @click="closeNote()"
        >
          <template #prepend>
            <u-icon name="close" size="xs" class="m-1" />
          </template>
        </u-button>
        <div
          v-for="note in props.data.relations"
          v-show="activeNote === note"
          :id="note.resourceUrl"
          :key="note.resourceUrl"
          class="flex flex-col place-content-center gap-8 place-self-stretch break-words p-10"
        >
          <template v-if="note.identifier === 'source'">
            <header class="grid gap-2">
              <small class="font-national2 text-lg font-extrabold">
                Kilde
              </small>

              <h2 class="font-numeraWebRegular text-4xl text-red-80">
                {{ note.title }}
              </h2>
            </header>

            <div v-if="note.resource?.type === 'Website'">
              {{ note.resource.author }}
              <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
              ({{ note.resource.publishYear
              }}<template v-if="note.resource.publishDate"
                >,
                {{ formatDate(note.resource.publishDate) }}</template
              >). <span class="italic">{{ note.resource.title }}</span
              >.
              {{ note.resource.publisher }}
              <a
                class="subtle-link whitespace-nowrap"
                :href="note.resource.url"
                target="_blank"
              >
                {{ note.resource.url }}
                <u-icon
                  class="inline-block"
                  name="external-link"
                  size="xs"
                />
              </a>
            </div>

            <div v-if="note.resource?.type === 'Article'">
              {{ note.resource.author }}
              ({{ note.resource.publishYear }}).
              {{ note.resource.title }}.
              <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
              <span class="italic">{{ note.resource.publisher }}</span
              >{{ note.appendText ? `, ${note.appendText}.` : "." }}
              <a
                v-if="note.resource.url"
                class="subtle-link inline-block"
                style="word-break: break-word"
                :href="note.resource.url"
                target="_blank"
              >
                {{ note.resource.url }}
                <u-icon
                  class="inline-block"
                  name="external-link"
                  size="xs"
                />
              </a>
            </div>

            <div v-if="note.resource?.type === 'Book'">
              {{ note.resource.author }}
              ({{ note.resource.publishYear }}).
              <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
              <span class="italic">{{ note.resource.title }}</span
              ><template v-if="note.resource.edition">
                ({{ note.resource.edition }})</template
              >.
              {{ note.resource.publisher }}
            </div>
          </template>

          <template v-else-if="note.identifier === 'term'">
            <header class="grid gap-2">
              <small class="font-national2 text-lg font-extrabold">
                Begrep
              </small>

              <h2 class="font-numeraWebRegular text-4xl text-blue-80">
                {{ note.title }}
              </h2>
            </header>

            <blockquote
              class="ml-2 border-l-2 border-blue-80 pl-4 text-2xl"
              v-html="note.resource.definition"
            />

            <div class="text-xl" v-html="note.resource.explanation" />
          </template>

          <template v-else-if="note.identifier === 'person'">
            <header class="grid gap-2">
              <small class="font-national2 text-lg">
                <span class="font-extrabold">Person</span>
                | ({{
                  formatYearRange(
                    note.resource.birthYear,
                    note.resource.deceasedYear,
                  )
                }})
              </small>

              <h2
                class="font-numeraWebRegular text-4xl text-blue-100"
              >
                {{ note.title }}
              </h2>
            </header>

            <div class="text-xl" v-html="note.resource.explanation" />
          </template>

          <header v-else class="grid gap-2">
            <small class="font-national2 text-lg font-extrabold">
              Forklaring
            </small>

            <h2 class="font-numeraWebRegular text-4xl text-red-100">
              {{ note.title }}
            </h2>
          </header>

          <div
            v-if="note.html"
            class="break-all font-national2 text-xl"
            v-html="note.html"
          />

          <figure class="place-self-start">
            <img
              v-if="note.image"
              :src="note.image.variation?.uri || note.image.uri"
              :width="note.image.width"
              :height="note.image.height"
              :alt="note.image.alternativeText"
              class="h-[164px] w-auto"
            />

            <figcaption class="mt-4" v-text="note.imageCaption" />
          </figure>

          <u-video
            v-if="note.video?.metadata"
            :src="note.video.metadata.elementURI"
            :title="note.video.title"
            :poster="note.video.img.src || note.video.metadata.image"
            :video-controls="!preview"
            :captions="note.video.captions"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.sidenoteactive {
  @screen md {
    --tw-translate-x: calc(var(--sidebar-width) * var(--offset) * -1);
  }
}

.subtle-link {
  font: inherit;
  @apply underline decoration-grey-40 decoration-2;
  text-underline-position: under;
  text-underline-offset: calc(
    0.1em + 1px
  ); /* 1px is based on half of @apply decoration-2 to match the bottom of the underline */
}
</style>

<script setup lang="ts">
import type { PropType } from "vue"
import type { NavProps } from "~/types/nav.d"
import type { Folder } from "~/types/folder"

const props = defineProps({
  folder: {
    type: Object as PropType<Folder>,
    default: () => {},
  },
  data: {
    type: Array as PropType<NavProps["data"]>,
    required: true,
    default: () => [],
  },
  pageType: {
    type: String as PropType<string>,
    default: "subject",
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const getSubjectsInFolder = (id: number) => {
  return props.data.filter(i => i.parentLocationId === id)
}

const route = useRoute()
const router = useRouter()

const hash = computed(() =>
  encodeURIComponent(
    props.folder.title.toLowerCase().replaceAll(/\s/g, "-"),
  ),
)
const isOpen = computed(() => {
  return route.hash.slice(1) === hash.value
})

function closeIfOpen(event: Event) {
  if (isOpen.value) {
    router.replace({ hash: "" })
    event.preventDefault()
  }
}

const folderContent = computed(() =>
  getSubjectsInFolder(props.folder.id),
)

const isFolderEmpty = computed(() => {
  if (
    props.folder.mediatypes.articles.count === 0 &&
    props.folder.mediatypes.videos.count === 0 &&
    props.folder.mediatypes.quizes.count === 0
  ) {
    return true
  }
  return false
})
</script>

<template>
  <a
    class="folder-btn hover:text-u-contrast"
    text-class="!whitespace-normal text-left"
    left-align
    :class="[
      {
        opened: isOpen,
        'pointer-events-none opacity-60':
          isFolderEmpty && folderContent,
      },
    ]"
    :href="`#${hash}`"
    @click="closeIfOpen($event)"
  >
    <u-icon
      class="mr-4 self-center pl-2 text-white"
      :name="isOpen ? 'minus-circle' : 'plus-circle'"
    />
    {{ `${folder.priority}. ${folder.title}` }}
  </a>
  <ul v-if="isOpen">
    <li
      v-for="(i, key) in folderContent"
      :key="key"
      class="folder-subject"
    >
      <u-nav-list-item
        :item="i"
        :page-type="pageType"
        :licence="licence"
      />
    </li>
  </ul>
</template>

<style scoped lang="postcss">
.folder-btn {
  @apply flex w-full gap-3 py-3 font-national2 text-[22px] font-bold;
  @apply flex border-b-2 border-white border-opacity-20;

  &.opened {
    @apply border-b-2 border-opacity-100;
  }
}
.folder-subject:last-child {
  @apply pb-6;
}
</style>

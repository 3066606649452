import gql from "graphql-tag"
import { graphqlClient } from "~/graphql/client"

const PRODUCT_NAME = import.meta.env.VITE_CMS_WEB_NAME ?? "kunne"
/**
 * Handle total content count
 * @param path
 */
export async function getTotalContentCount(
  path: string,
): Promise<number | undefined> {
  const query = gql`{
    _repository {
      location(urlAlias: "/
      ${PRODUCT_NAME}/${path}") {
        children {
          totalCount
        }
      }
    }
  }`

  try {
    const { data } = await graphqlClient({ query })

    const totalCount = data._repository.location.children.totalCount

    return totalCount
  } catch (e) {
    return
  }
}

/**
 * Handle locationId
 * @param path
 */
export async function getLocationId(
  path: string,
): Promise<number | undefined> {
  const query = gql`{
    _repository {
      location(urlAlias: "/${PRODUCT_NAME}/${path}") {
        id
      }
    }
  }`

  try {
    const { data } = await graphqlClient({ query })

    const locationId = data._repository.location.id

    return locationId
  } catch (e) {
    return
  }
}

<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  plainText: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  parentLocationId: Number,
  subjectPage: {
    type: Boolean,
    default: false,
  },
})

const data = useDataStore()

const subject = computed(() =>
  data.subjects.find(({ id }) => props.parentLocationId === id),
)
const shortTitle = ref("")
const subjectHref = ref("")
const subjectPriority = ref("")

watch(subject, () => {
  shortTitle.value = subject.value?.shortTitle as string
  subjectHref.value = subject.value?.href as string
  subjectPriority.value = subject.value?.priority as unknown as string
})

const checkSubject = () =>
  (subjectHref.value && shortTitle.value) ||
  (subject.value?.href && subject.value?.shortTitle)

const route = useRoute<"article">()
const subjectParam = route.params.subject
const productParam = route.params.product
const variantParam = route.params.variant
</script>

<template>
  <p v-if="checkSubject() && !plainText" class="sub-heading">
    <a
      :href="`/produkter/${productParam}/${variantParam}/${subjectParam}`"
      >{{ subject?.priority || subjectPriority }}.
      {{ shortTitle || subject?.shortTitle }}</a
    >
  </p>
  <p
    v-if="checkSubject() && plainText"
    class="sub-heading-plaintext"
    :class="[{ preview }, { 'subject-page': subjectPage }]"
  >
    {{ subject?.priority || subjectPriority }}.
    {{ shortTitle || subject?.shortTitle }}
  </p>
</template>

<style scoped lang="postcss">
a {
  font-size: inherit;
  @apply decoration-solid;
}
.sub-heading,
.sub-heading-plaintext {
  @apply font-bold uppercase;
}
.sub-heading {
  color: inherit;
  @apply m-[0] mb-2 py-[0];
  > a {
    @apply !font-national2 font-bold not-italic leading-normal hover:border-b-2 hover:border-red-40;
  }
}

p {
  @apply mb-2 font-national2 font-bold not-italic leading-8 md:mb-4;
  > a {
    @apply border-b-2 border-red-40 font-national2 font-bold not-italic;
  }
  &.subject-page {
    @apply mb-0
      font-national2compressed text-[54px]
      font-bold
      leading-[1]
      md:text-[130px]
      md:leading-[117px] <md:text-black;
  }
  &.preview {
    @apply text-xl leading-[18px] md:text-6xl md:leading-[54px];
  }
}
</style>

<script setup lang="ts">
import { isAuthorized } from "~/utilities/auth"
import { setBodyBackground } from "~/utilities/theme"
import { identity } from "lodash-es"
import { useLoginPageUrl } from "~/composables/useLoginPageUrl"

const productContent = useProductContentStore()
const products = useProductVariantsStore()
const route = useRoute<"product">()
const loginPageUrl = useLoginPageUrl()

const maxWidth: string = "w-[90vw] md:w-[85vw] 2xl:w-[80vw] "

onBeforeMount(async () => {
  setBodyBackground("dark")
})

const isProfilePageActive = computed(() =>
  route.path.includes("min-side"),
)
</script>

<template>
  <div
    class="flex w-full items-center justify-center bg-[--u-color-dark]"
  >
    <div
      class="flex flex-col items-center justify-center md:flex-row md:items-start md:gap-16"
      :class="maxWidth"
    >
      <u-side-nav
        :data="productContent.subjectNav"
        :product="products.activeVariant"
        :folders="productContent.folders"
        :licence="products.activeVariant?.userHasLicence"
        page-type="product"
      />
      <main
        class="h-full w-full max-w-[45em] <md:flex <md:items-center <md:justify-center"
      >
        <div
          class="flex w-full items-center justify-end pt-12 xl:pt-14 <md:!hidden"
        >
          <u-button
            v-if="isAuthorized"
            id="min-side"
            data-pendo="min-side-desktop"
            :to="
              isProfilePageActive
                ? `/produkter/${[
                    route.params.product,
                    route.params.variant,
                  ]
                    .filter(identity)
                    .join('/')}`
                : `/produkter/${[
                    route.params.product,
                    route.params.variant,
                    'min-side',
                  ]
                    .filter(identity)
                    .join('/')}`
            "
            theme="white"
            size="sm"
            :active="isProfilePageActive"
          >
            <template #prepend>
              <u-icon name="user" size="sm" />
            </template>
            Min side
          </u-button>
          <u-button
            v-else
            id="logg-in"
            variant="outline"
            class="mt-3 <md:!hidden"
            theme="white"
            size="sm"
            :to="loginPageUrl"
          >
            <template #prepend>
              <u-icon name="user" size="sm" />
            </template>
            Logg inn
          </u-button>
        </div>
        <router-view />
      </main>
      <u-pay-banner
        v-if="
          !products.activeVariant?.userHasLicence &&
          !products.activeVariant?.isFree
        "
      />
    </div>
  </div>
</template>

<script setup>
const personalization = usePersonalizationStore()

function setFeatureFlagToDisabled() {
  isFeatureEnabled.value = false
}
</script>

<template>
  <div
    v-if="personalization?.settings"
    class="h-fit w-full overflow-hidden rounded-lg bg-white bg-opacity-10 p-6"
  >
    <div class="mb-4 flex items-center justify-start gap-3">
      <p class="text-xl lg:text-2xl">Innstillinger</p>
    </div>

    <u-checkbox
      v-model="personalization.settings.autoShowProgress"
      label="Vis progresjon automatisk"
      size="sm"
      dark
    />
    <div class="flex w-fit flex-col gap-1 xl:flex-row xl:gap-4">
      <u-button
        id="fjern-progresjon"
        variant="outline"
        class="text-green-fit mt-3 w-fit"
        size="sm"
        @click="personalization.clearReadHistory"
      >
        Fjern progresjon
      </u-button>
      <u-button
        id="fjern-progresjon"
        variant="outline"
        class="mt-3 w-fit text-green-40"
        size="sm"
        @click="setFeatureFlagToDisabled"
      >
        Deaktivere 'Feature Flags' midlertidig
      </u-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isAuthorized } from "~/utilities/auth"

const scrollOf = ref(false)
const menu = ref(false)
const handleScrollof = (val: boolean) => (scrollOf.value = val)

const route = useRoute<"product">()

const productParam = computed(() => route.params.product)

const router = useRouter()
</script>

<template>
  <u-header
    class="bg-[#142654] text-white"
    :limit-position="15"
    :class="{ 'text-white': menu || scrollOf }"
  >
    <div class="flex items-center gap-x-5">
      <!-- <u-button
        :class="[
          { inactive: !menu && !scrollOf },
          { 'active-menu-noscroll': menu && !scrollOf },
          { 'active-menu': menu },
          { 'active-scrollof': scrollOf }
        ]"
        class="hamburger-button"
        shape="rounded-outline"
        data-pendo="hamburger-landingpage-button"
        :icon="menu ? 'close' : 'hamburger'"
        :icon-size="18"
        :wave="true"
        :dynamic-icon="true"
        @click="menu = !menu"
      /> -->

      <u-button pill variant="quite" @click="router.go(-1)">
        <template #prepend>
          <u-icon name="arrow-left" class="p-[3px]" />
        </template>
      </u-button>

      <div class="text-logo heading-3">
        {{ "KUNNE" }}
      </div>
    </div>

    <u-marketing-menu v-model="menu" :scrolled="scrollOf" />

    <!-- <u-button
      class="login-button border-2 rounded-full px-8 leading-9 font-national2 !font-normal text-lg"
      :class="[
        { inactive: !menu && !scrollOf },
        { 'active-menu-noscroll': menu && !scrollOf },
        { 'active-menu': menu },
        { 'active-scrollof': scrollOf }
      ]"
      text="Logg inn"
      :wave="true"
      element-type="router-link"
      data-pendo="logg-inn-header-button"
      custom-attr="to"
      href="/logg-inn"
    /> -->
  </u-header>
</template>
<style scoped lang="postcss">
header {
  @apply fixed top-0 z-50 flex h-16 items-center justify-between gap-x-5 px-3 lg:h-20 lg:px-5;
}

.hamburger-button {
  @apply flex items-center justify-center bg-black
  py-2 ring-0 hover:border-portal-indigo-900 hover:bg-portal-indigo-900 hover:fill-white;
  &.inactive {
    @apply border-portal-indigo-900 bg-portal-indigo-900 fill-white hover:border-u-contrast hover:bg-u-contrast;
  }
  &.active-menu {
    @apply border-white fill-white stroke-white hover:border-white hover:!bg-u-contrast hover:fill-white;
  }
  &.active-menu-noscroll {
    @apply hover:border-u-contrast hover:bg-u-contrast;
  }
  &.active-scrollof {
    @apply border-white fill-white hover:border-portal-indigo-900;
  }
}

.text-logo {
  @apply my-3 font-national2compressed text-2xl font-extrabold md:text-[2.25rem] lg:text-[2.5rem];
}
.login-button {
  &.inactive {
    @apply border-portal-indigo-900 bg-white text-black
     hover:border-portal-indigo-900 hover:bg-portal-indigo-900 hover:text-white;
  }
  &.active-menu {
    @apply border-white text-white;
  }
  &.active-menu-noscroll {
    @apply hover:border-u-contrast hover:bg-u-contrast;
  }
  &.active-scrollof {
    @apply border-white text-white hover:border-portal-indigo-900 hover:bg-portal-indigo-900;
  }
}
</style>

<script setup lang="ts">
const licence = useLicenceStore()
const productContent = useProductContentStore()
const route = useRoute<"article">()

const licenceData = computed(() => licence.licenceData)
const { dataAssigned: licenceAssigned } = useDataWatch(licenceData)

const nextArticle = computed(() => productContent.nextArticle)

const nextSubject = computed(() => productContent.nextSubject)

const personalization = usePersonalizationStore()
function toggleArticleRead() {
  if (personalization.readHistory[0].done) {
    personalization.readHistory[0].done = false
  } else personalization.readHistory[0].done = true
}
const price = useProductPrice()
</script>

<template>
  <u-footer
    id="footer-main-content"
    class="article-footer u-grid z-30 px-4 py-4 text-white"
    :class="{
      'pay-footer':
        !nextArticle?.id && !nextSubject?.id && !licenceAssigned,
    }"
  >
    <div class="col-start-2 flex w-[80vw] gap-4">
      <u-button
        v-if="nextArticle?.id"
        :key="nextArticle.id"
        pill
        class="min-w-[1.8em] max-w-fit md:w-full"
        :to="nextArticle.frontendHref"
      >
        <template #prepend>
          <u-icon name="arrow-down" class="scale-[0.833]" />
        </template>
        Neste
      </u-button>

      <div class="flex items-center">
        <p
          class="col-start-3 hidden h-fit w-96 items-center truncate whitespace-nowrap text-xl font-bold lg:block"
        >
          {{ nextArticle?.title }}
        </p>
      </div>
    </div>
    <div
      v-if="isOnline && licence.hasValidLicence"
      id="article-mark-as-read"
      class="h-fll col-start-17 hidden w-fit place-self-end md:flex md:items-center md:justify-center"
    >
      <u-button
        v-if="
          personalization.activeArticle &&
          personalization.readHistory[0].done
        "
        variant="filled"
        pill
        theme="green"
        @click="toggleArticleRead()"
      >
        Lest
        <template #prepend>
          <u-icon name="check" size="xs" />
        </template>
      </u-button>
      <u-button
        v-if="
          personalization.activeArticle &&
          !personalization.readHistory[0].done
        "
        variant="filled"
        pill
        style="--color: white"
        theme="skin-medium"
        @click="toggleArticleRead()"
      >
        Marker som lest
        <template #prepend>
          <u-icon name="status-not-started" size="xs" class="pl-2" />
        </template>
      </u-button>
    </div>
    <u-pay-banner
      v-if="
        !nextArticle?.id &&
        !nextSubject?.id &&
        !licenceAssigned &&
        price &&
        price > 0
      "
      :short-text="true"
    />
  </u-footer>
</template>

<style scoped lang="postcss">
.article-footer {
  @apply w-full bg-[--u-color-dark] py-5;

  &.pay-footer {
    @apply h-1 pl-0;
  }
}
</style>

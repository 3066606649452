/**
 * Detect contrast of color
 * Accepts a hex code
 * @example isColorDark('#color') ? '#isDark' : '#isLight'
 */
export function isColorDark(color: string) {
  // Check the format of the color, HEX or RGB?
  let r: any, g: any, b: any
  let hsp = 0

  if (color.match(/^rgba/)) {
    const format = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    )

    if (format?.length) {
      r = format[1] // Red
      g = format[2] // Green
      b = format[3] // Blue
    }
    //const { red, green, blue } = rgbaToRgb(color)
    //const rgbColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

    /*r = red
    g = green
    b = blue */
  }

  if (color.match(/^rgb/)) {
    const rgbColor = color.match(
      /^rgb?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    )

    if (rgbColor) {
      r = Number(rgbColor[1])
      g = Number(rgbColor[2])
      b = Number(rgbColor[3])
    }
  } else {
    const value = hexToRgb(color)
    if (value?.r) {
      r = value.r
      g = value.g
      b = value.b
    }
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light"
  } else {
    return "dark"
  }
}

/**
 * Converts RGB to Hex
 * @example rgbToHex(10, 54, 120) //#0a3678
 * @param rgb
 * @returns a hex code
 */
export function rgbToHex(rgb: string) {
  const values = rgb
    .substring(4, rgb.length - 1)
    .replace(/ /g, "")
    .split(",")

  const r = Number(values[0])
  const g = Number(values[1])
  const b = Number(values[2])

  return (
    "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  )
}

/**
 * Converts Hex to RGB
 * @example hexToRgb("#0a3678") // "10,54,120"
 * @param hex
 * @returns
 */
export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return { r, g, b } // r + ',' + g + ',' + b//return 23,14,45 -> reformat if needed
  }
  return null
}

export function darkOrLight(element?: HTMLElement) {
  const el = document.querySelector("html") as HTMLElement
  const htmlElement = element ? element : el

  const color = getComputedStyle(htmlElement).backgroundColor
  return isColorDark(color) === "dark" ? true : false
}

<script setup lang="ts">
const toastStore = useToastStore()
</script>

<template>
  <section class="gui-toast-group">
    <output
      v-for="(i, key) in toastStore.toasts"
      :id="i.id"
      :key="key"
      role="status"
      class="gui-toast"
    >
      <u-icon name="attention" class="self-center" />
      <span class="self-center">{{ i.message }}</span>
      <u-button
        variant="quite"
        class="min-w-[42px] self-center"
        @click="toastStore.removeToast(i.id)"
      >
        <template #prepend>
          <u-icon name="close" size="sm" />
        </template>
      </u-button>
    </output>
  </section>
</template>

<style scoped lang="postcss">
.gui-toast-group {
  @apply fixed
  end-[5vh]
  start-[5vh]
  top-[5dvh]
  z-50
  grid
  justify-end
  justify-items-center
  gap-[1vh]
  md:start-[50vh]
  md:justify-items-end
  lg:start-[70vh];
}

.gui-toast {
  @apply flex gap-3 rounded-[10px] bg-blue-80 pl-3 font-national2 text-lg leading-[22px] text-white;
  max-inline-size: min(31ch, 90vw);
  padding-block: 16px;
}
</style>

<!-- eslint-disable prettier/prettier -->
<script setup lang="ts">
import { checkoutPageUrl } from "~/utilities/urls";
import { ProductsList } from "~/types/productVariant";

const licence = useLicenceStore()

const { logout, isAuthorized, user } = useAuth()
const products = useProductVariantsStore()
const applicationMeta = useApplicationMetaStore()
applicationMeta.setNewestVersion()

const licenceType = computed(() => {
  if (products.activeVariant?.userHasLicence) {
    return "Betalt"
  } else {
    return "Prøveversjon"
  }
})

const route = useRoute("subject")

const uniqueProductVariants = (variantList: ProductsList["variants"]) => {
  return variantList.filter((variant, index, self) => index ===
    self.findIndex((v) => v.ean === variant.ean),
  )
}

const newVersionExists = computed(() => applicationMeta.currentVersion !== applicationMeta.newestVersion)

async function handleReload(){
  location.reload()
}

watchEffect(() => {
  console.info("newVersionExists", newVersionExists.value, "currentVersion:", applicationMeta.currentVersion);
})
</script>

<template>
  <div class="mb-20 rounded-xl pt-12 text-[--u-color-light]">
    <div
      class="font-numeraWebRegula flex w-full flex-col items-start justify-start gap-4"
    >
      <div
        class="flex w-full items-center justify-between gap-4 pb-6"
      >
        <h1
          class="flex items-center justify-center whitespace-nowrap pl-6 font-national2compressed text-5xl font-bold"
        >
          Min Side
        </h1>
      </div>
      <div v-if="isAuthorized" class="flex w-full flex-col gap-4">
        <div
          class="w-full overflow-hidden rounded-lg bg-white bg-opacity-10 p-6 2xl:p-12"
        >
          <div
            class="flex items-start justify-between gap-2 pb-2 <sm:items-center"
          >
            <div
              class="flex items-center justify-start gap-3 text-4xl"
            >
              <h2 class="text-xl lg:text-3xl">
                {{ user.fullName }}
              </h2>
            </div>
          </div>
          <ul class="user-info whitespace-nowrap">
            <li
              class="flex gap-4 border-b-2 border-b-white border-opacity-20 pt-2 <md:flex-col <md:gap-0"
            >
              <p class="field-title flex items-center justify-center">
                Lisens:
              </p>
              <div
                class="field-text flex w-full items-center justify-between"
              >
                <p>
                  {{
                    products.activeVariant?.eCommerce?.title ||
                    `Kunne ${products.activeVariant?.name}`
                  }}
                </p>
                <div class="flex items-center justify-center gap-2">
                  <p class="font-national2">
                    {{ licenceType }}
                  </p>
                  <u-icon
                    v-if="licenceType == 'Betalt'"
                    name="check"
                    class="scale-[0.5] rounded-full bg-green-60 p-2"
                  />
                </div>
              </div>
            </li>
            <li
              v-if="licence.licenceData?.length && isAuthorized"
              class="flex gap-4 border-b-2 border-b-white border-opacity-20 <md:flex-col <md:gap-0"
            >
              <p class="field-title">Betaling:</p>
              <p class="field-text">Vipps</p>
            </li>
            <li
              class="flex items-center justify-between gap-4 pt-2 <md:flex-col <md:items-start <md:gap-2"
            >
              <div class="flex w-full gap-4 md:w-[50%]">
                <p class="field-title">E-post:</p>
                <p class="field-text w-fit max-w-96">
                  {{ user.email }}
                </p>
              </div>
              <u-button
                v-if="isAuthorized"
                size="sm"
                class="overflow-visible"
                theme="white"
                @click="logout(route.params.product)"
              >
                Logg ut
              </u-button>
            </li>
          </ul>
        </div>
        <div
          class="h-fit w-full overflow-hidden rounded-lg bg-white bg-opacity-10 p-6 2xl:p-12"
        >
          <div class="flex items-center justify-start gap-4 pb-6">
            <h2 class="whitespace-nowrap text-2xl lg:text-3xl">
              Mine produkter
            </h2>
          </div>
          <div
            v-if="licence.licenceData?.length"
            class="flex flex-col gap-4"
          >
            <div
              v-for="product in products.productsList"
              v-show="product.variants.some(v => v.userHasLicence)"
              :key="product.productTitle"
            >
              <template
                v-for="v in uniqueProductVariants(product.variants)"
                :key="v.ean"
              >
                <div
                  v-if="v.userHasLicence && v.eCommerce"
                  class="mb-10 flex flex-col items-start justify-start gap-4 md:flex-row"
                >
                  <img
                    :src="v.eCommerce?.imageUrl"
                    class="h-44 w-44 rounded bg-[--u-color-light] object-contain p-2"
                    :alt="v.eCommerce?.imageDescription"
                  />
                  <div class="flex flex-col gap-2">
                    <p class="font-national2 font-bold"></p>
                    <p class="font-national2 font-bold">
                      {{ v.eCommerce?.title }}
                    </p>
                    <p class="font-light">
                      {{ v.eCommerce?.description }}
                    </p>
                    <p class="font-national2 font-light">
                      Tilgang til
                      {{ formatDate(v.licence?.endDate) }} (utløper
                      automatisk)
                    </p>
                    <p />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <p v-else>
            Vi fant ingen betalte produkter tilkoblet brukeren din.
            <a
              element-type="router-link"
              custom-attr="to"
              :href="checkoutPageUrl(route.params.product)"
              class="text-u-contrast"
              >Kjøp tilgang</a
            >
          </p>
        </div>
        <u-download-offline />
        <u-feature-flag>
          <u-settings />
        </u-feature-flag>
        <div
          class="h-fit w-full overflow-hidden rounded-lg bg-white bg-opacity-10 p-6 2xl:p-12"
        >
          <h2 class="whitespace-nowrap pb-6 text-2xl lg:text-3xl">
            Kundeservice
          </h2>
          <p>
            Trenger du hjelp?
            <u-button
              class="ml-[-1.25em] underline underline-offset-8 sm:ml-[-1em]"
              size="sm"
              no-padding
              variant="quite"
              href="https://om.kunne.universitetsforlaget.no/hjelpesider"
            >
              <template #append>
                <u-icon name="link-external" size="sm" />
              </template>
              Gå til våre hjelpesider
            </u-button>
          </p>
          <div>
            <p v-if="applicationMeta.currentVersion" class="mt-5">
              Versjon: {{ applicationMeta.currentVersion }}
            </p>
            <u-button
              v-if="newVersionExists"
              size="sm"
              theme="white"
              class="mt-5 text-black"
              @click="handleReload"
            >
              <template #prepend>
                <u-icon name="download" size="sm" />
              </template>
              Hent nyeste versjon
            </u-button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex h-full w-full items-center justify-start pb-10 pl-2"
      >
        Logg inn for å se informasjon om brukeren din
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.user-info li {
  @apply py-2;
}

.field-title {
  @apply w-fit font-numeraWebBold text-base font-bold;
}

.field-text {
  @apply overflow-hidden overflow-ellipsis font-national2 <sm:w-[250px];
}
</style>

export default function setupNotFoundWathcer() {
  const productContent = useProductContentStore()
  const router = useRouter()

  watch(
    () => productContent.noSubjectsFound,
    newValue => {
      if (newValue === true) {
        router.replace("/404")
      }
    },
    { immediate: true },
  )
}

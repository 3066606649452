<script setup lang="ts">
const links = [
  {
    title: "Om Kunne",
    href: "/om",
  },
  {
    title: "Blogg",
    href: "/blogg/eksamenstips",
  },
  {
    title: "Kundeservice",
    href: "https://om.kunne.universitetsforlaget.no/hjelpesider",
  },
  {
    title: "Universitetsforlaget",
    href: "https://www.uf.no",
  },
  {
    title: "Personvern",
    href: "/om/personvern",
  },
  {
    title: "Informasjonskapsler",
    href: "/om/informasjonskapsler",
  },
  {
    title: "Administrer Informasjonskapsler",
    href: "#",
    customClass: "cky-banner-element",
  },
  // {
  //   title: 'Produkter',
  //   href: '#'

  // },
  // {
  //   title: 'Forfattere',
  //   href: '#'

  // },
]
</script>

<template>
  <footer
    class="flex h-full w-screen flex-col items-center justify-center overflow-hidden bg-[--u-color-medium] py-20 md:h-80 md:py-0"
  >
    <div
      class="flex w-[90vw] max-w-[100em] flex-col items-center justify-center sm:justify-between md:w-[80vw] md:flex-row"
    >
      <div class="flex w-full flex-col justify-center gap-4">
        <h2
          class="font-national2compressed text-6xl font-extrabold uppercase leading-[1em] text-white"
        >
          <a class="kunne-logo relative" href="/"> Kunne </a>
        </h2>
        <div class="pb-10 text-white opacity-75">
          H Aschehoug & Co W Nygaard AS<br />Org. nr 910 292 005<br />Sehesteds
          gate 3, 0164 Oslo
        </div>
      </div>
      <div
        id="links"
        class="flex w-full items-end justify-end text-white"
      >
        <ul
          class="flex h-full w-full flex-col flex-wrap items-start justify-center gap-2 sm:justify-start lg:h-36 lg:w-fit"
        >
          <li
            v-for="link in links"
            :key="link.title"
            class="md:ml-5"
            :class="link?.customClass"
          >
            <a :href="link.href">
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style lang="css">
#links a {
  @apply font-national2 underline decoration-2 underline-offset-8 hover:text-u-contrast hover:decoration-u-contrast;
}

.kunne-logo::after {
  content: "";
  @apply absolute bottom-3 left-[130px] h-1 w-20 bg-white opacity-60;
}
</style>

<script setup>
const route = useRoute()
const data = useDataStore()
const name = String(
  "om" + (route.params.path ? `/${route.params.path}` : ""),
)

onBeforeMount(async () => {
  const ready = await data.getPage(name)
})

const page = computed(() =>
  data.pages.find(page =>
    page?.urlAliases.find(({ path }) => path === `/${name}`),
  ),
)
</script>

<template>
  <div
    class="font- grid grid-cols-[minmax(0,80ch)] place-content-center px-10 py-20 pb-52"
  >
    <article v-if="page" class="richtext font-numeraWebRegular">
      <section
        v-for="section in page.sections"
        :key="section.id"
        v-html="section.richtext"
      />
    </article>
  </div>
</template>

<route lang="yaml">
path: "/om/:path?"
meta:
  title: Om
  layout: marketing
</route>

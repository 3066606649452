<script setup>
import { formatCurrency } from "~/utilities/format"
import { useCheckout } from "~/composables/checkout"
import { loginVipps, createVippsLoginUrl } from "~/utilities/auth"

const route = useRoute()
const router = useRouter()
const auth = useAuth()
const checkout = useCheckout()
const productInformationStore = useProductInformationStore()
const licenceStore = useLicenceStore()

await licenceStore.setLicenceData()

const products = productInformationStore.productData
const store = useDataStore()
const EAN = computed(() => store.product.ean)
const hasLicenceForProduct = computed(() =>
  licenceStore.hasLicenceByEan(EAN.value),
)

const checkoutError = ref(false)
const checkoutShow = ref(false)

const vippsUrl = createVippsLoginUrl()

if (products.length === 0) {
  await productInformationStore.setProductData()
}

await store.getSubjects(route.params.product).catch(() => {
  console.log(
    "[404] - Redirected because an error occured whilst attempting to fetch the subject you were trying to access (checkout)",
  )
  router.replace("/404")
})

const price = computed(
  () =>
    productInformationStore.productData.find(
      el => el.ean === EAN.value,
    )?.price,
)

const redirectPath = computed(() =>
  route.params.product
    ? `produkter/${route.params.product}/${route.params.variant}`
    : "",
)

function submit() {
  checkoutShow.value = true
  checkoutError.value = false

  checkout
    .submit(
      { iFrameContainerId: "vipps-checkout-frame-container" },
      redirectPath.value,
      EAN.value,
    )
    .catch(() => {
      checkoutError.value = true
    })
}

const dataStore = useDataStore()
const licenceTitle = ref(null)
const licenceImg = ref(null)
const licenceImgDescription = ref(null)
const licenceDescription = ref(null)
const productExist = ref(false)

watch(
  productInformationStore.productData,
  () => {
    const products = productInformationStore.productData.filter(
      p => p.ean === dataStore.product.ean,
    )

    if (products.length > 0) {
      const product = products[0]
      licenceTitle.value = product.title
      licenceImg.value = product.imageUrl
      licenceImgDescription.value = product.imageDescription
      licenceDescription.value = product.description
      productExist.value = true
    }
  },
  { immediate: true },
)
</script>

<template>
  <div v-if="productExist" class="checkout rounded-2xl">
    <u-modal
      v-model="checkoutShow"
      close-icon="close-circle"
      close-icon-size="30"
    >
      <section id="vipps-checkout-frame-container" />
    </u-modal>

    <div
      class="grid gap-6 rounded-2xl bg-white shadow-md sm:px-16 sm:py-16 md:grid-cols-[15rem,1fr] lg:pl-48 <sm:p-8"
    >
      <div
        class="flex h-60 w-60 flex-col items-center justify-center gap-2 rounded-md bg-[--u-color-light]"
      >
        <p
          class="z-10 flex max-h-10 w-full justify-start gap-2 px-4 font-national2compressed text-4xl font-extrabold uppercase text-[--u-color-dark]"
        >
          Kunne
          {{
            licenceTitle
              ? licenceTitle.slice(5, licenceTitle.length)
              : ""
          }}
        </p>
        <img
          :alt="licenceImgDescription || 'Fant ikke alt tekst'"
          style="aspect-ratio: 1/1"
          :src="licenceImg"
          class="h-fit max-h-[60%] w-fit object-contain"
        />
      </div>
      <div class="flex flex-col">
        <p class="mb-auto font-national2 text-xl">
          <strong style="font: inherit; font-weight: bold">
            {{ licenceTitle || "Fant ikke produkttittel" }}
          </strong>
          {{ licenceDescription || "Fant ikke produktbeskrivelse" }}
        </p>

        <p class="mt-8 font-national2 text-xl">
          <strong style="font: inherit; font-weight: bold">
            {{ formatCurrency(price) }} for ett semester
          </strong>
        </p>

        <p class="font-national2 text-xl">(Stopper automatisk)</p>
      </div>
    </div>

    <div
      class="grid items-baseline gap-y-6 border-b-2 border-b-portal-indigo-100 py-16 sm:pr-16 lg:grid-cols-[12rem,1fr] <sm:pr-8 <lg:grid-cols-[4rem,1fr]"
    >
      <div
        class="grid place-items-center justify-self-center rounded-full font-national2compressed font-bold lg:h-16 lg:w-16 lg:text-3xl <lg:h-10 <lg:w-10 <lg:text-2xl"
        :class="
          !auth.isAuthorized.value ? 'bg-green-20' : 'bg-gray-200'
        "
      >
        1
      </div>

      <template v-if="auth.isAuthorized.value">
        <div class="font-numeraWebRegular text-3xl">
          Logget inn som {{ auth.user.value.fullName }}
        </div>
      </template>

      <template v-else>
        <div class="font-numeraWebRegular text-3xl">
          Logg inn eller registrer deg med vipps
        </div>
        <div
          class="flex items-center lg:gap-16 <lg:flex-col <lg:items-start <lg:gap-6"
          style="grid-column: 2"
        >
          <a
            v-wave
            class="block rounded-full"
            :href="vippsUrl"
            @click.prevent="loginVipps(route.path)"
          >
            <img
              src="/images/vipps-logginn.svg"
              alt="Logg inn med Vipps"
              class="h-18"
            />
          </a>
          <div class="font-national2 text-lg">
            Har du ikke vipps?
            <a
              href="https://vipps.no/hjelp/vipps/kom-i-gang-med-vipps/hvordan-skaffer-jeg-meg-vipps/"
              target="_blank"
              style="font: inherit; text-decoration: underline"
              >Slik går du frem.</a
            >
          </div>
        </div>
      </template>
    </div>

    <form
      class="grid items-baseline gap-y-6 py-16 pr-16 text-xl lg:grid-cols-[12rem,1fr] <lg:grid-cols-[4rem,1fr]"
      @submit.prevent="submit()"
    >
      <div
        class="grid place-items-center justify-self-center rounded-full font-national2compressed font-bold lg:h-16 lg:w-16 lg:text-3xl <lg:h-10 <lg:w-10 <lg:text-2xl"
        :class="
          auth.isAuthorized.value ? 'bg-green-20' : 'bg-gray-200'
        "
      >
        2
      </div>
      <template v-if="!auth.isAuthorized.value">
        <div class="font-numeraWebRegular text-3xl">Betaling</div>
      </template>

      <template v-else-if="hasLicenceForProduct">
        <div>
          <div class="font-numeraWebRegular text-3xl">
            Du har allerede tilgang til Kunne
          </div>
          <router-link
            class="mt-5 block font-national2 text-lg underline"
            :to="`/produkter/${
              route.params.product + '/' + route.params.variant
            }`"
          >
            Gå til Kunne
          </router-link>
        </div>
      </template>

      <template v-else>
        <div class="font-numeraWebRegular text-3xl">
          Betaling: {{ formatCurrency(price) }} for et semester
        </div>
        <p style="grid-column: 2; font: inherit">
          Viktig informasjon: Fordi du får umiddelbar tilgang til
          Kunne, frasier du deg angreretten. Kunne vil kun være
          tilgjengelig for lesing i Kunne.no. Det kan ikke lastes ned.
        </p>
        <label
          style="grid-column: 2"
          class="flex items-baseline gap-3"
        >
          <input
            v-model="checkout.termsAccepted.value"
            type="checkbox"
            required
          />
          <div>
            Jeg samtykker til
            <a
              href="https://kunne.universitetsforlaget.no/kjopsvilkar"
              rel="noreferrer"
              target="_blank"
              style="font: inherit; text-decoration: underline"
              >kjøpsvilkårene</a
            >
          </div>
        </label>
        <button
          v-wave
          style="grid-column: 2"
          class="mt-6 grid items-center place-self-start rounded-full bg-blue-60 px-8 py-2 font-national2 font-bold italic text-white lg:min-h-16 lg:text-2xl <lg:min-h-10 <lg:text-xl"
        >
          Kjøp tilgang
        </button>
      </template>
    </form>
  </div>
  <div
    v-else
    class="flex flex-col gap-6 rounded-2xl bg-white shadow-md sm:px-16 sm:py-16"
  >
    <p>
      Ops, noe gikk galt, og vi greide ikke hente informasjon om
      produktet.
    </p>
    <p>
      Du har ikke blitt belastet. Prøv igjen litt senere eller ta
      kontakt med
      <a
        href="https://om.kunne.universitetsforlaget.no/hjelpesider/"
        rel="noreferrer"
        target="_blank"
        class="underline"
        >Kunne kundeservice</a
      >.
    </p>
  </div>
</template>

<route lang="yaml">
name: checkout
meta:
  title: Betaling
  layout: basic
</route>

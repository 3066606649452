import GraphqlProductPageBlockDocument from "../../graphql/documents/productPageBlocks"

import { graphqlClient } from "../../graphql/client"
import type { ProductPageBlocks } from "~/types/productPageBlocks"

export default async (
  urlAlias: string,
): Promise<ProductPageBlocks | null | undefined> => {
  const { data } = await graphqlClient({
    query: GraphqlProductPageBlockDocument,
    variables: {
      urlAlias,
    },
  })
  try {
    const query = data._repository.location

    if (!query) return null

    const { children } = query

    return {
      children,
    }
  } catch (error) {
    console.error(error)
  }
}

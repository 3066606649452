<script setup lang="ts">
import { useCheckout } from "~/composables/checkout"
import { useLicenceStore } from "~/store/licence"
import { PropType } from "vue"
import { ProductInformation } from "~/types/productVariant"
import * as Sentry from "@sentry/vue"

const props = defineProps({
  selectedProduct: {
    type: Object as PropType<ProductInformation>,
    required: false,
  },
  productKey: {
    type: String,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  activeStep: {
    type: String,
    required: true,
  },
})

const checkoutShow = defineModel<boolean>("checkoutShow")
const checkoutError = defineModel<boolean>("checkoutError")

const licenceStore = useLicenceStore()
await licenceStore.setLicenceData()
const hasLicence = computed(() =>
  licenceStore.hasLicenceByEan(props.selectedProduct?.ean ?? ""),
)

const checkout = useCheckout()
const auth = useAuth()
const loggedIn = computed(() => auth.isAuthorized.value)

function submit() {
  checkoutShow.value = true
  checkoutError.value = false

  if (props.selectedProduct?.ean) {
    checkout
      .submit(
        { iFrameContainerId: "vipps-checkout-frame-container" },
        `produkter/${props.productKey}/${props.selectedProduct.variantKey}`,
        props.selectedProduct.ean,
      )
      .catch(() => {
        checkoutError.value = true
      })
  } else {
    Sentry.captureException(
      `EAN is missing in payment step of product [${props.productKey}] with variant [${props.selectedProduct?.shortVariantLabel}]`,
    )
    checkoutError.value = true
  }
}

const price = computed(() => {
  const ean = props.selectedProduct?.ean
  if (ean) {
    return props.selectedProduct.eCommerce?.price
  } else {
    Sentry.captureException(
      `Unable to set product price due to missing EAN for [${props.selectedProduct?.name}] with variant [${props.selectedProduct?.shortVariantLabel}]`,
    )
  }
})
</script>

<template>
  <!--  TODO: Handle product variant not found  -->
  <UStep v-if="selectedProduct" :is-active-step="activeStep === step">
    <template #step>{{ step }}</template>
    <template #title>
      <template v-if="hasLicence && selectedProduct">
        Du har allerede tilgang til Kunne {{ selectedProduct.name }}
      </template>
      <template v-else-if="!hasLicence">
        {{ price && price > 0 ? "Betaling" : "Prøv produktet" }}
      </template>
    </template>
    <template #content>
      <template v-if="!loggedIn" />
      <template v-else-if="hasLicence">
        <u-button
          :href="`/produkter/${
            productKey + '/' + selectedProduct?.variantKey
          }`"
          size="md"
          cta
          pill
          theme="white"
          variant="filled"
          style="--color: white"
        >
          Gå til Kunne {{ selectedProduct?.name ?? "" }}
        </u-button>
      </template>
      <template v-else>
        <form class="flex text-xl" @submit.prevent="submit()">
          <section class="flex flex-col gap-12">
            <div v-if="price && price > 0" class="font-national2">
              <label class="flex items-baseline gap-3">
                <input
                  v-model="checkout.termsAccepted.value"
                  type="checkbox"
                  required
                />
                <span>
                  Jeg samtykker til
                  <a
                    href="https://kunne.universitetsforlaget.no/kjopsvilkar"
                    rel="noreferrer"
                    target="_blank"
                    style="font: inherit; text-decoration: underline"
                    >kjøpsvilkårene</a
                  >
                </span>
              </label>
            </div>

            <UCheckoutCard
              v-if="selectedProduct"
              :price="price"
              :selected-product="selectedProduct"
              :show-payment="true"
            />
          </section>
        </form>
      </template>
    </template>
  </UStep>
  <UStep v-else :is-active-step="activeStep === step">
    <template #step>{{ step }}</template>
    <template #title>Betaling</template>
  </UStep>
</template>

<style scoped lang="postcss"></style>

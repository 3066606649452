import { useDataStore } from "~/store/data"
import { ref, watchEffect } from "vue"
import { useProductVariantsStore } from "~/store/productVariants"

export type OfflineAccess = "not-available" | "downloading" | "ready"

export const statusMessages: Record<
  OfflineAccess,
  { title: string; description: string }
> = {
  "not-available": {
    title: "Last ned (200MB)",
    description:
      "Last innhold og få tilgang på tekst og bilder offline.",
  },
  downloading: {
    title: "Laster ned...",
    description: "Nedlastingen er i gang.",
  },
  ready: {
    title: "Lastet ned",
    description:
      "Kunne (tekst og bilder) er nå tilgjengelig offline.",
  },
}

export const downloadProgress = ref({
  subjectsDownloaded: 0,
  totalSubjects: 0,
  imageDownloadedStatus: "not-started" as
    | "not-started"
    | "started"
    | "finished",
  videoDownloadedStatus: "not-started" as
    | "not-started"
    | "started"
    | "finished",
  articlesDownloadedStatus: "not-started" as
    | "not-started"
    | "started"
    | "finished",
  imagesDownloaded: 0,
  videosDownloaded: 0,
  totalImages: 0,
  totalVideos: 0,
  status:
    (localStorage.getItem("offline-access") as OfflineAccess) ??
    "not-available",
})

watch(
  downloadProgress,
  newValue => {
    localStorage.setItem("offline-access", newValue.status)
  },
  { deep: true },
)

export function cacheProductForOfflineUse(
  productName: string,
  variant: string,
): Promise<void> {
  if (downloadProgress.value.status === "downloading") {
    return Promise.reject(new Error("Caching is already in progress"))
  }
  downloadProgress.value.status = "downloading"
  downloadProgress.value.articlesDownloadedStatus = "started"

  console.info("cacheProductForOfflineUse")
  return new Promise(async (resolve, reject) => {
    try {
      const dataStore = useDataStore()
      console.info("starting to download product")
      await dataStore.getSubjects(productName + "-" + variant)

      const subjects = dataStore.subjects
      downloadProgress.value.totalSubjects = subjects.length
      const MAX_CONCURRENT_REQUESTS = 6
      const articlePromises: Promise<void>[] = []

      for (const subject of subjects) {
        const MAX_RETRY_ATTEMPTS = 3
        let retryAttempts = 0

        const articlePromise = new Promise<void>(
          async (resolve, reject) => {
            const retry = async () => {
              try {
                await dataStore.getArticles({
                  product: productName,
                  variant,
                  subject: subject.title,
                  id: subject.id,
                })
                downloadProgress.value.subjectsDownloaded++
                resolve()
              } catch (error) {
                if (retryAttempts < MAX_RETRY_ATTEMPTS) {
                  retryAttempts++
                  console.info(`Retrying... Attempt ${retryAttempts}`)
                  setTimeout(retry, 200)
                } else {
                  reject(error)
                }
              }
            }

            await retry()
          },
        )

        articlePromises.push(articlePromise)

        if (articlePromises.length >= MAX_CONCURRENT_REQUESTS) {
          await Promise.all(articlePromises)
          articlePromises.length = 0
        }
      }

      if (articlePromises.length > 0) {
        await Promise.all(articlePromises)
      }
      downloadProgress.value.articlesDownloadedStatus = "finished"
      console.info("Product has been cached")
      downloadProgress.value.status = "ready"
      resolve()
    } catch (error) {
      console.error(error)
      downloadProgress.value.status = "not-available"
      reject(error)
    }
  })
}

const offlineAccess =
  (localStorage.getItem("offline-access") as OfflineAccess) ??
  "not-available"
if (offlineAccess === "downloading") {
  watchEffect(() => {
    setTimeout(async () => {
      const store = useProductVariantsStore()
      downloadProgress.value.status = "not-available"
      for (const productKey in store.groupedProductVariants) {
        const product = store.groupedProductVariants[productKey]
        for (const variant of product.variants) {
          if (!variant.userHasLicence) continue
          await cacheProductForOfflineUse(
            productKey,
            variant.variantKey,
          )
        }
      }
    }, 5000)
  })
}

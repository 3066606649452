<script setup lang="ts">
import { identity } from "lodash-es"
import { isAuthorized } from "~/utilities/auth"

const productContent = useProductContentStore()
const products = useProductVariantsStore()
const route = useRoute<"subject">()
const licence = useLicenceStore()
import { setBodyBackground } from "~/utilities/theme"
import { useLoginPageUrl } from "~/composables/useLoginPageUrl"

const licenceData = computed(() => licence.licenceData)
const { dataAssigned: licenceAssigned } = useDataWatch(licenceData)
const loginPageUrl = useLoginPageUrl()

const subjectShortTitle = ref("")
const subjectPriority = ref("")
const isProfilePageActive = computed(() =>
  route.path.includes("min-side"),
)

const maxWidth: string = "w-[90vw] md:w-[85vw] 2xl:w-[80vw] "

onBeforeMount(async () => {
  setBodyBackground("dark")
})

watch(
  () => productContent.activeSubject,
  () => {
    subjectShortTitle.value = productContent.activeSubject
      ?.shortTitle as string
    subjectPriority.value = productContent.activeSubject
      ?.priority as unknown as string
  },
)

const checkSubject = () =>
  (subjectPriority.value && subjectShortTitle.value) ||
  (productContent.activeSubject?.shortTitle &&
    productContent.activeSubject?.priority)

const price = useProductPrice()
</script>

<template>
  <div
    class="flex h-full w-full items-center justify-center bg-[--u-color-dark]"
  >
    <div
      class="flex flex-col items-center justify-center md:flex-row md:items-start md:gap-16"
      :class="maxWidth"
    >
      <u-side-nav
        :data="productContent.articleNav"
        :licence="licenceAssigned"
        :product="products.activeVariant"
        class="md:w-full"
      >
        <template #list-top>
          <li
            :key="0"
            class="mt-0 md:m-0 md:mt-1 md:border-b-2 md:border-white"
          >
            <router-link
              :to="`/produkter/${
                route.params.product + '/' + route.params.variant
              }`"
              class="flex items-center pb-2"
            >
              <u-icon name="arrow-left" size="sm" class="mr-3.5" />
              <span class="font-black">
                Kunne
                <span class="lowercase">{{
                  products.activeProduct?.productTitle
                }}</span>
              </span>
            </router-link>
          </li>
          <li
            v-if="checkSubject()"
            class="mt-8 hidden items-start pb-3 text-white md:flex md:border-b-2 md:border-white md:pb-2"
          >
            <span
              class="subject-number menu-heading-large mr-3.5 flex w-[18px] items-center justify-center"
            >
              {{
                productContent.activeSubject?.priority ||
                subjectPriority
              }}.
            </span>
            <span class="menu-heading-large">
              {{
                productContent.activeSubject?.shortTitle ||
                subjectShortTitle
              }}
            </span>
          </li>
        </template>
      </u-side-nav>
      <main
        class="h-full w-full max-w-[45em] <md:flex <md:flex-col <md:items-center <md:justify-center"
      >
        <div
          class="flex w-full items-center justify-end pt-14 <md:!hidden"
        >
          <u-button
            v-if="isAuthorized"
            id="min-side"
            data-pendo="min-side-desktop"
            :to="
              isProfilePageActive
                ? `/produkter/${[
                    route.params.product,
                    route.params.variant,
                    route.params.subject,
                  ]
                    .filter(identity)
                    .join('/')}`
                : `/produkter/${[
                    route.params.product,
                    route.params.variant,
                    route.params.subject,
                    'min-side',
                  ]
                    .filter(identity)
                    .join('/')}`
            "
            theme="white"
            size="sm"
            :active="isProfilePageActive"
          >
            <template #prepend>
              <u-icon name="user" size="sm" />
            </template>
            Min side
          </u-button>
          <u-button
            v-else
            id="logg-in"
            variant="outline"
            class="mt-3 <md:!hidden"
            theme="white"
            size="sm"
            :to="loginPageUrl"
          >
            <template #prepend>
              <u-icon name="user" size="sm" />
            </template>
            Logg inn
          </u-button>
        </div>
        <div
          v-if="productContent.noSubjectsFound"
          class="richtext pb-32 text-white md:pt-[175px] 2xl:pt-[210px]"
        >
          <h1>Fant ikke kapittelet</h1>
          <router-link
            class="mt-6 block"
            :to="`/produkter/${route.params.product}/${route.params.variant}`"
          >
            Ta meg til forsiden av Kunne
            {{
              capitalizeChar(
                products.activeProduct?.productTitle ?? "",
              )
            }}
          </router-link>
        </div>
        <template v-else>
          <h1
            v-if="
              checkSubject() && !route.fullPath.endsWith('min-side')
            "
            class="pb-7 pt-10 font-national2condensed text-[40px] font-medium text-white md:hidden"
          >
            {{
              `${
                productContent.activeSubject?.priority ||
                subjectPriority
              }. ${
                productContent.activeSubject?.shortTitle ||
                subjectShortTitle
              }`
            }}
          </h1>
          <router-view />
        </template>
      </main>
    </div>
    <u-pay-banner
      v-if="!licenceAssigned && price && price > 0"
      :short-text="productContent.activeSubject?.totalChildren === 0"
    />
  </div>
</template>

<style scoped>
.menu-heading-large {
  @apply font-national2 text-[20px] font-bold not-italic !leading-6;
}
</style>

<script setup lang="ts">
import type { PropType } from "vue"
import { onBeforeRouteUpdate } from "vue-router"

const props = defineProps({
  html: {
    type: String as PropType<string>,
    default: "",
  },
})

const route = useRoute()
const router = useRouter()

const content = computed(() => {
  const html = stringToHTML(props.html) as Element
  html.querySelector("h1")?.remove()
  return html.innerHTML
})

function handleLinks(event: Event) {
  const href = (event.target as HTMLDivElement)
    ?.closest("a")
    ?.getAttribute("href")

  if (href?.startsWith("#")) {
    event.preventDefault()

    if (route.hash === href) {
      router.back()
    } else {
      route.hash
        ? router.replace({ ...route, hash: href })
        : router.push({ ...route, hash: href })
    }
  }
}

function removeActiveLink() {
  document
    .querySelectorAll(".text-link--active-target")
    .forEach(el => el.classList.remove("text-link--active-target"))
}

function setActiveLink(href: string) {
  const link = document.querySelector(`a[href="${href}"]`)
  if (link) link.classList.add("text-link--active-target")
}

watch(content, () => {
  if (route.hash) setActiveLink(route.hash)
})

onBeforeRouteUpdate(({ hash }) => {
  removeActiveLink()
  if (hash) setActiveLink(hash)
})

onMounted(() => {
  if (route.hash) setActiveLink(route.hash)
})
</script>

<template>
  <div
    v-if="content"
    class="richtext"
    @click="handleLinks"
    v-html="content"
  />
</template>

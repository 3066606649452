<script setup>
import { ref, inject } from "vue"
import {
  cacheProductForOfflineUse,
  downloadProgress,
} from "~/composables/offlineDownload"

const route = useRoute()
const productParam = computed(() => route.params.product)
const variantParam = computed(() => route.params.variant)

const isModalVisible = inject("isModalVisible", ref(false))
</script>

<template>
  <u-modal
    :model-value="isModalVisible"
    title="Er du sikker på at du vil laste ned innhold (tekst og bilder)?"
    description="Det krever 200MB data."
    @close="isModalVisible = false"
  >
    <div
      v-if="downloadProgress.subjectsDownloaded > 0"
      class="m-3 border p-3 font-national2"
    >
      <p>
        Kaptiler:
        <span>{{ downloadProgress.subjectsDownloaded }}</span> ({{
          downloadProgress.totalSubjects
        }})
      </p>
      <p>
        Bilder: {{ downloadProgress.imagesDownloaded }} ({{
          downloadProgress.totalImages
        }})
      </p>
    </div>

    <div class="flex items-center">
      <u-button
        id="cacel-downloads"
        class="mr-2"
        theme="portal"
        size="sm"
        @click="isModalVisible = false"
      >
        <div class="text-lg">Senere</div>
      </u-button>
      <u-button
        id="downloads"
        theme="portal"
        size="sm"
        @click="
          () => {
            cacheProductForOfflineUse(productParam, variantParam)
            isModalVisible = false
          }
        "
      >
        <div class="flex items-center justify-center">
          <u-icon
            class="mr-2 scale-[0.833] fill-portal-indigo-900"
            name="download"
          />
          <span class="text-lg">Last ned (200MB)</span>
        </div>
      </u-button>
    </div>
  </u-modal>
</template>

<script setup lang="ts">
import type { PropType } from "vue"
import type { Subject } from "~/types/subject"
import { getColorClassNameFromScheme } from "~/utilities/colors"

const props = defineProps({
  subject: {
    type: Object as PropType<Subject>,
    required: true,
    default: () => ({}),
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const withImage = ref(!!props.subject.img?.src)

const cardImage = () => {
  if (!withImage.value) return
  return `background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(18, 11, 43, 0.90)), url(${props.subject.img?.src}); background-repeat: no-repeat; background-size: cover; background-position-y: center;`
}

const price = useProductPrice()

const backgroundVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.subject.colorTag as string, [
    "bg",
  ])
const textVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.subject.colorTag as string, [
    "text",
  ])
</script>

<template>
  <div class="relative flex items-center">
    <router-link
      :id="`subject-card-${subject.id}`"
      :to="`/produkter/${
        subject.href.split('/')[1].replace(/-(?!.*-)/, '/') +
        '/' +
        subject.href.split('/')[2]
      }`"
      class="subject-card-button"
      :class="backgroundVal"
      :style="cardImage()"
    >
      <div class="mb-10 flex w-72 flex-col items-start p-4 lg:p-0">
        <div
          class="flex items-end text-white"
          :class="
            'border-' +
            textVal.split('-')[1] +
            '-' +
            textVal.split('-')[2]
          "
        >
          <div class="subject-slide-title mb-2" :class="textVal">
            <span
              v-if="
                subject.totalChildren !== 0 &&
                !licence &&
                price &&
                price > 0
              "
              class="freemium"
            >
              Prøvekapittel!
            </span>
            <span>{{
              +subject.priority + ". " + subject.title
            }}</span>
          </div>
        </div>

        <u-media-types
          :text-color="textVal"
          :data="subject"
          :licence="licence"
        />
      </div>
    </router-link>
    <div class="subject-card-stacks-effect" />
  </div>
</template>

<style scoped lang="postcss">
.subject-card-button {
  @apply sticky z-20 mb-[48px] flex h-[350px] w-full  items-end justify-start overflow-hidden rounded-lg p-3 shadow-[0_20px_60px_-25px_rgba(0,0,0,0.3)] shadow-black transition-[transform,shadow]
  duration-150 ease-linear md:hover:translate-x-[5px] md:hover:translate-y-[-3px] lg:h-[537px] lg:justify-end  lg:rounded-xl lg:px-[50px] lg:py-[50px] <md:font-medium;
}

.subject-card-stacks-effect {
  &::after {
    @apply absolute left-[-10px] top-[10px] z-10 mb-[48px]  h-[350px] w-full rounded-lg bg-white bg-gradient-to-t from-[--color-slate-200] to-transparent  lg:left-[-10px] lg:h-[537px] lg:rounded-xl;
    content: "";
  }
}
.freemium {
  @apply mb-1 block font-national2 text-[18px] text-u-contrast md:hidden;
}

.subject-slide-title {
  @apply font-national2compressed text-[32px] font-medium leading-10;
}
</style>

import {
  ProductInformation,
  ProductsMap,
  ProductVariants,
} from "~/types/productVariant"
import * as Sentry from "@sentry/vue"
import { EcommerceProduct } from "~/types/productData"

export const getGroupedProductVariants = (
  productVariantsData: ProductVariants[],
  ecommerceData: EcommerceProduct[],
): ProductsMap | undefined => {
  const productVariants: ProductsMap = {}
  for (const product of productVariantsData) {
    const variantTitle = product.ibexaTitle

    const words = variantTitle.split(" ")

    if (words.length < 2) {
      Sentry.captureException(
        "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
      )

      console.error(
        "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
      )
      console.error("Product Title Found: ", product.ibexaTitle)
      return undefined
    }

    // The variant (ex. "UiO" or "NTNU") is the last word in the title
    const variant = words[words.length - 1]
    const productTitle = variantTitle.replace(` ${variant}`, "")

    const productKey = buildProductKey(product._url)

    const productIsFree = computed(
      () =>
        ecommerceData.filter(p => p.ean === product.ean)[0]?.price ===
        0,
    )

    if (!productVariants[productKey]) {
      productVariants[productKey] = {
        productTitle,
        hasAtLeastOneReleasedProduct:
          (product.ean && product.ean.length > 0) || false,
        variants: [
          {
            name: variantTitle,
            shortTitle: product.shortTitle,
            ean: product.ean,
            isReleased:
              (product.ean && product.ean.length > 0) || false,
            isFree: productIsFree.value,
            variantKey: variant.toLowerCase(),
            shortVariantLabel: variant,
            locationId: product._location.id,
            url: product._url,
            pathString: product._location.pathString,
          },
        ],
      }
    } else {
      productVariants[productKey].variants.push({
        name: variantTitle,
        shortTitle: product.shortTitle,
        ean: product.ean,
        isReleased: (product.ean && product.ean.length > 0) || false,
        isFree: productIsFree.value,
        variantKey: variant.toLowerCase(),
        shortVariantLabel: variant,
        locationId: product._location.id,
        url: product._url,
        pathString: product._location.pathString,
      })
    }
    if (
      productVariants[productKey] &&
      !productVariants[productKey].hasAtLeastOneReleasedProduct &&
      product.ean &&
      product.ean.length > 0
    ) {
      productVariants[productKey].hasAtLeastOneReleasedProduct = true
    }
  }

  addProductVariantAlias(productVariants)

  return Object.keys(productVariants).length > 0
    ? productVariants
    : undefined
}

function addProductVariantAlias(productsMap: ProductsMap) {
  if (productsMap["exphil"]) {
    const uioVariant = productsMap["exphil"].variants.find(
      v => v.variantKey === "uio",
    )
    if (uioVariant) {
      const uiaVariant: ProductInformation = {
        ...uioVariant,
        name: "Exphil UiA",
        variantKey: "uio", // Keep uio as short variant, to keep uio in the URL and make navigation work
        shortVariantLabel: "UiA",
      }

      productsMap["exphil"].variants.push(uiaVariant)
    }
  }
}

function buildProductKey(url: string) {
  // We only want the part after '/' and before the last '-' in the URL, ex. '/kunne/exphil-uio' -> 'exphil'
  const parts = url.split("/")
  const lastPart = parts[parts.length - 1]
  const segments = lastPart.split("-")
  return segments.slice(0, segments.length - 1).join("-")
}

import gql from "graphql-tag"

export default gql`
  query ($urlAlias: String) {
    _repository {
      location(urlAlias: $urlAlias) {
        children(sortBy: _priority) {
          edges {
            node {
              content {
                ... on ProductPageBlockContent {
                  id
                  body
                  header
                  image {
                    variation(identifier: fullwidth) {
                      uri
                      width
                    }
                    alternativeText
                  }
                  blockOrientation
                  imageText
                }
              }
              priority
            }
          }
        }
      }
    }
  }
`

<script setup lang="ts">
const products = useProductVariantsStore()
const route = useRoute<"product">()
const appName = import.meta.env.VITE_APP_NAME
</script>

<template>
  <h1 class="w-full title-wrapper pt-14">
    <router-link
      class="title"
      :to="`/produkter/${
        route.params.product + '/' + route.params.variant ?? ''
      }`"
      style="color: inherit"
    >
      {{ appName }} {{ products.activeProduct?.productTitle }}
      <span
        v-if="products.activeVariant?.shortTitle"
        class="subtitle whitespace-nowrap"
        >{{
          products.activeVariant?.shortTitle
            ? products.activeVariant?.shortTitle
            : ""
        }}</span
      >
    </router-link>
  </h1>
</template>

<style scoped lang="postcss">
* {
  @apply font-national2compressed
  font-medium
  uppercase
  leading-[1]
  text-white;
}

.title {
  @apply p-0 text-[36px] xl:text-[48px];
}
.subtitle {
  @apply /*pt-[4px]*/
  block
  pb-0
  font-national2
  text-[18px]
  font-normal
  leading-normal;
}
</style>

<script setup lang="ts">
import pendo from "~/analytics/pendo"
import { useAuth } from "./composables/auth"
import { setupFeatureFlag } from "~/composables/featureFlag"
import setupNotFoundWathcer from "~/composables/notFound"
import { useApplicationMetaStore } from "~/store/applicationMeta"

setupFeatureFlag()
setupNotFoundWathcer()

const { init, user } = useAuth()
const ui = useUiStore()
const route = useRoute()
const router = useRouter()
const ready = ref(false)
const productRoute = useRoute<"product">()
const subjectRoute = useRoute<"subject">()
const articleRoute = useRoute<"article">()
const productName = computed(() => productRoute.params.product)
const capitalizedProductName = computed(
  () =>
    productName.value[0].toUpperCase() + productName.value.slice(1),
)
const productContent = useProductContentStore()
const productVariants = useProductVariantsStore()
const applicationMeta = useApplicationMetaStore()

applicationMeta.setNewestVersion()
productVariants.hydrateStore()

watchEffect(() => {
  productVariants.product = productRoute.params.product
  productVariants.variant = productRoute.params.variant
  productContent.articleSubjectHref = subjectRoute.params.subject
  productContent.articleHref = articleRoute.params.article
})

init(router).then(async () => {
  const licence = useLicenceStore()
  await licence.setLicenceData()
  ready.value = true
  pendo(route, user.value.uid)
})

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg

useHead({
  title: computed(
    () =>
      import.meta.env.VITE_APP_NAME +
      " " +
      `${
        productName.value
          ? capitalizedProductName.value.replaceAll("-", " ")
          : ""
      }` +
      `${route.meta.title ? " - " + route.meta.title : ""}`,
  ),
  meta: [
    { name: "description", content: String(route.meta.description) },
  ],
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
    },
  ],
})
</script>

<template>
  <!-- Orchestrating async dependencies in a component tree -->
  <!-- Wrap non SSR friendly components for now -->
  <suspense>
    <div>
      <template v-if="ready">
        <u-toast />
        <router-view />
        <u-pay-result />
        <u-audio-player />
        <u-download-media-background />
      </template>
      <u-load-screen v-else :data="ui.loader" />
    </div>
  </suspense>
</template>

<style scoped lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

import { unreachable } from "./errors"

type ColorTypes = (
  | "text"
  | "bg"
  | "bgCode"
  | "shadowLeft"
  | "shadowBottom"
)[]

interface GetColorOptions {
  /** Sets the text color to black or white depending on the bg color instead of colored */
  bwText?: boolean
}

export const colorSchemes = new Map([
  [
    "britt",
    {
      text: "red-40",
      bwText: "white",
      bg: "blue-60",
      bgCode: "#204CB6",
      shadowLeft: "#3864CF",
      shadowBottom: "#1F4399",
    },
  ],
  [
    "arne",
    {
      text: "green-60",
      bwText: "black",
      bg: "yellow-20",
      bgCode: "#FFF6BC",
      shadowLeft: "#FFFADA",
      shadowBottom: "#E1DBB1",
    },
  ],
  [
    "jakob",
    {
      text: "teal-20",
      bwText: "white",
      bg: "blue-100",
      bgCode: "#142654",
      shadowLeft: "#273C72",
      shadowBottom: "#141F3D",
    },
  ],
  [
    "tom",
    {
      text: "blue-80",
      bwText: "black",
      bg: "grey-40",
      bgCode: "#9ca3af",
      shadowLeft: "#D0D0CF",
      shadowBottom: "#A19F9B",
    },
  ],
  [
    "kjersti",
    {
      text: "red-100",
      bwText: "black",
      bg: "yellow-100",
      bgCode: "#D8980B",
      shadowLeft: "#518E8E",
      shadowBottom: "#BC8815",
    },
  ],
  [
    "asle",
    {
      text: "yellow-40",
      bwText: "white",
      bg: "teal-100",
      bgCode: "#367575",
      shadowLeft: "#FFF6B7",
      shadowBottom: "#336363",
    },
  ],
  [
    "amund",
    {
      text: "red-80",
      bwText: "black",
      bg: "yellow-60",
      bgCode: "#FFDC0D",
      shadowLeft: "#FFEE8B",
      shadowBottom: "#E7CC2F",
    },
  ],
  [
    "terje",
    {
      text: "grey-100",
      bwText: "black",
      bg: "green-40",
      bgCode: "#5FC16F",
      shadowLeft: "#82DA90",
      shadowBottom: "#569B61",
    },
  ],
  [
    "silje",
    {
      text: "yellow-60",
      bwText: "white",
      bg: "blue-100",
      bgCode: "#142654",
      shadowLeft: "#273C72",
      shadowBottom: "#141F3D",
    },
  ],
  [
    "karina",
    {
      text: "purple-80",
      bwText: "black",
      bg: "yellow-80",
      bgCode: "#ECBA0C",
      shadowLeft: "#FDCD25",
      shadowBottom: "#CCA41A",
    },
  ],
  [
    "ola",
    {
      text: "purple-40",
      bwText: "white",
      bg: "blue-100",
      bgCode: "#142654",
      shadowLeft: "#273C72",
      shadowBottom: "#141F3D",
    },
  ],
  [
    "inger",
    {
      text: "green-40",
      bwText: "white",
      bg: "blue-80",
      bgCode: "#1A3985",
      shadowLeft: "#3454A1",
      shadowBottom: "#172E65",
    },
  ],
  [
    "svein",
    {
      text: "red-80",
      bwText: "black",
      bg: "grey-20",
      bgCode: "#e5e7eb",
      shadowLeft: "#F8F8F8",
      shadowBottom: "#D3CDC6",
    },
  ],
  [
    "thomas",
    {
      text: "green-60",
      bwText: "black",
      bg: "yellow-40",
      bgCode: "#FAE86F",
      shadowLeft: "#FFF6B7",
      shadowBottom: "#DBCB60",
    },
  ],
  [
    "per",
    {
      text: "grey-100",
      bwText: "black",
      bg: "purple-40",
      bgCode: "#CE78C5",
      shadowLeft: "#E290DA",
      shadowBottom: "#B06DA9",
    },
  ],
  [
    "thor",
    {
      text: "purple-20",
      bwText: "white",
      bg: "blue-80",
      bgCode: "#1A3985",
      shadowLeft: "#3454A1",
      shadowBottom: "#172E65",
    },
  ],
  [
    "marit",
    {
      text: "purple-100",
      bwText: "black",
      bg: "red-60",
      bgCode: "#EC6343",
      shadowLeft: "#FF886C",
      shadowBottom: "#D4654A",
    },
  ],
  [
    "marianne",
    {
      text: "teal-60",
      bwText: "white",
      bg: "grey-80",
      bgCode: "#1f2937",
      shadowLeft: "#767166",
      shadowBottom: "#48453E",
    },
  ],
  [
    "tormod",
    {
      text: "red-100",
      bwText: "black",
      bg: "yellow-20",
      bgCode: "#FFF6BC",
      shadowLeft: "#FFFADA",
      shadowBottom: "#E1DBB1",
    },
  ],
  [
    "turid",
    {
      text: "red-60",
      bwText: "white",
      bg: "blue-80",
      bgCode: "#1A3985",
      shadowLeft: "#3454A1",
      shadowBottom: "#172E65",
    },
  ],
  [
    "hans",
    {
      text: "grey-100",
      bwText: "black",
      bg: "red-40",
      bgCode: "#F49F8B",
      shadowLeft: "#FFB5A4",
      shadowBottom: "#D8998A",
    },
  ],
  [
    "ellen",
    {
      text: "red-20",
      bwText: "white",
      bg: "green-100",
      bgCode: "#28362B",
      shadowLeft: "#425045",
      shadowBottom: "#1F2621",
    },
  ],
  [
    "knut",
    {
      text: "teal-40",
      bwText: "white",
      bg: "red-100",
      bgCode: "#764236",
      shadowLeft: "#835B52",
      shadowBottom: "#653E35",
    },
  ],
  [
    "viggo",
    {
      text: "green-100",
      bwText: "black",
      bg: "yellow-60",
      bgCode: "#FFDC0D",
      shadowLeft: "#FFEE8B",
      shadowBottom: "#E7CC2F",
    },
  ],
  [
    "frode",
    {
      text: "purple-20",
      bwText: "white",
      bg: "red-80",
      bgCode: "#D13419",
      shadowLeft: "#E15E47",
      shadowBottom: "#B63E2A",
    },
  ],
  [
    "katrine",
    {
      text: "purple-100",
      bwText: "black",
      bg: "purple-20",
      bgCode: "#EACAE4",
      shadowLeft: "#E4EFFF",
      shadowBottom: "#C0CFE4",
    },
  ],
  [
    "mona",
    {
      text: "teal-100",
      bwText: "black",
      bg: "green-20",
      bgCode: "#C8ECCE",
      shadowLeft: "#E7FDEA",
      shadowBottom: "#C1D6C5",
    },
  ],
  [
    "tore",
    {
      text: "teal-40",
      bwText: "white",
      bg: "blue-80",
      bgCode: "#1A3985",
      shadowLeft: "#3454A1",
      shadowBottom: "#172E65",
    },
  ],
  [
    "ruth",
    {
      text: "blue-100",
      bwText: "black",
      bg: "grey-60",
      bgCode: "#4b5563",
      shadowLeft: "#767166",
      shadowBottom: "#48453E",
    },
  ],
  [
    "gro",
    {
      text: "green-20",
      bwText: "white",
      bg: "teal-100",
      bgCode: "#367575",
      shadowLeft: "#E7FDEA",
      shadowBottom: "#C1D6C5",
    },
  ],
  [
    "peter",
    {
      text: "red-100",
      bwText: "black",
      bg: "teal-60",
      bgCode: "#2EC6BE",
      shadowLeft: "#43DBD3",
      shadowBottom: "#36AFA9",
    },
  ],
  [
    "randi",
    {
      text: "teal-20",
      bwText: "black",
      bg: "blue-40",
      bgCode: "#5474F6",
      shadowLeft: "#718CFB",
      shadowBottom: "#4F68CE",
    },
  ],
  [
    "synne",
    {
      text: "teal-20",
      bwText: "white",
      bg: "green-80",
      bgCode: "#275C32",
      shadowLeft: "#3E784A",
      shadowBottom: "#254B2D",
    },
  ],
  [
    "kirsti",
    {
      text: "yellow-20",
      bwText: "white",
      bg: "green-100",
      bgCode: "#28362B",
      shadowLeft: "#425045",
      shadowBottom: "#1F2621",
    },
  ],
  [
    "berit",
    {
      text: "green-20",
      bwText: "white",
      bg: "purple-60",
      bgCode: "#AF3EAA",
      shadowLeft: "#CA5DC5",
      shadowBottom: "#974293",
    },
  ],
  [
    "ida",
    {
      text: "grey-100",
      bwText: "black",
      bg: "teal-80",
      bgCode: "#2BA19E",
      shadowLeft: "#3EB2B0",
      shadowBottom: "#2F9390",
    },
  ],
  [
    "bjarne",
    {
      text: "black",
      bwText: "black",
      bg: "white",
      bgCode: "#ffffff",
      shadowLeft: "white",
      shadowBottom: "white",
    },
  ],
])

export function getColorClassNameFromScheme(
  name: string,
  types: ColorTypes,
  options: GetColorOptions = {},
) {
  if (!name) return ""

  function build({
    text,
    bwText,
    bg,
    bgCode,
    shadowLeft,
    shadowBottom,
  }: {
    text: string
    bwText: string
    bg: string
    bgCode: string
    shadowLeft: string
    shadowBottom: string
  }) {
    return types
      .map(type => {
        switch (type) {
          case "text":
            return options.bwText ? `text-${bwText}` : `text-${text}`
          case "bg":
            return `bg-${bg}`
          case "bgCode":
            return bgCode
          case "shadowLeft":
            return shadowLeft
          case "shadowBottom":
            return shadowBottom
          default:
            return unreachable(type)
        }
      })
      .join(" ")
  }

  return build(
    colorSchemes.get(name.toLowerCase()) ?? {
      text: "black",
      bwText: "black",
      bg: "white",
      bgCode: "#ffffff",
      shadowLeft: "white",
      shadowBottom: "white",
    },
  )
}

<script setup lang="ts">
import type { PropType } from "vue"
import type { Article } from "~/types/article"
import type { NavElement } from "~/types/ui"
import { setBodyBackground } from "~/utilities/theme"

const props = defineProps({
  index: {
    type: Object as PropType<NavElement>,
    default: () => {},
  },
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const convertToDigits = (x: number) => (x < 10 ? `0${x}` : x)

const getMinutes = () => {
  if (props.data.video == null) return
  const sec = parseInt(props.data.video.metadata.duration, 10)
  const minutes = Math.floor(sec / 60)
  const seconds = sec - minutes * 60

  return `${convertToDigits(minutes)}:${convertToDigits(seconds)}`
}
onMounted(() => setBodyBackground("medium"))
</script>

<template>
  <div
    :id="`template-${data.id}`"
    class="u-grid u-article bg-[--u-color-medium] text-white"
    :class="{ 'u-article--preview': preview }"
  >
    <u-article-template-header
      :data="data"
      class="col-start-2 col-end-18 pt-[var(--grid-col-width)] @md:col-start-5 @md:col-end-15"
      :class="{ '@md:!col-end-17 md:!col-start-3': preview }"
      :video-meta="getMinutes()"
    />

    <u-rich-text
      :html="data.intro?.html"
      class="col-start-2 col-end-18 @md:col-start-5 @md:col-end-15"
      :class="{ '@md:!col-start-3 @md:!col-end-17': preview }"
    />

    <u-video-screen
      data-pendo="video-player"
      class="col-start-1 col-end-19"
      :data="data"
      :preview="preview"
    />

    <div class="text-container accordion-container">
      <u-accordion
        :html="data.video?.transcription.html"
        :preview="preview"
      />
    </div>
  </div>
</template>

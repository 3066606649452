<script setup lang="ts">
import { setBodyBackground } from "~/utilities/theme"
setBodyBackground("light")
const products = useProductVariantsStore()
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="w-[90vw] md:w-[80vw]">
      <UPageHeader :product="products.activeProduct" />
      <main
        class="flex w-full items-center justify-center overflow-hidden bg-[--u-color-light]"
      >
        <router-view />
      </main>
    </div>
  </div>
  <UPublicFooter />
</template>

import { getCheckoutStatus } from "~/hooks/payment/"

export function useLicencePolling(
  duaration: number,
  interval = 2000,
) {
  const rejected = ref(false)
  const data = ref(0)
  const licence = useLicenceStore()
  const isLoading = ref(false)

  let timerId: any

  const stopPolling = () => {
    clearInterval(timerId)
    isLoading.value = false
  }

  const fetchData = async () => {
    isLoading.value = true
    await licence.setLicenceData()
    if (
      licence.licenceData?.length &&
      licence.licenceData.length > 0
    ) {
      data.value = licence.licenceData.length
      stopPolling()
    }
    const checkoutStatus = await getCheckoutStatus()
    if (checkoutStatus?.sessionStatus === "REJECTED") {
      rejected.value = true
      stopPolling()
    }
  }

  const startPolling = () => {
    fetchData()
    timerId = setInterval(fetchData, interval)
  }

  onMounted(() => {
    startPolling()
    setTimeout(() => {
      stopPolling()
    }, duaration)
  })

  return {
    data,
    isLoading,
    rejected,
  }
}

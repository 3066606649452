import GraphqlProductVariantsDocument from "../../graphql/documents/productVariants"

import { graphqlClient } from "../../graphql/client"
import {
  ProductVariants,
  ProductVariantsEdgeNodes,
} from "~/types/productVariant"

export default async (): Promise<ProductVariants[] | null> => {
  const WEB_NAME = import.meta.env.VITE_CMS_WEB_NAME

  const { data } = (await graphqlClient({
    query: GraphqlProductVariantsDocument,
    variables: {
      locationId: import.meta.env.VITE_CMS_PRODUCTS_LOCATION_ID,
    },
  })) as ProductVariantsEdgeNodes

  try {
    const query = data.content

    if (!query) return null

    return query.products.edges
      .map(variant => variant.node)
      .filter(
        node =>
          node.ibexaTitle !== "" ||
          node._url?.startsWith(`/${WEB_NAME || "kunne"}`),
      )
  } catch (error) {
    console.error(error)
    return null
  }
}

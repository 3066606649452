<script setup lang="ts">
import type { PropType } from "vue"
import type { Subject } from "~/types/subject"
import type { Folder } from "~/types/folder"

interface MediaTypes {
  i: string
  count: number
  text: string
}

defineProps({
  data: {
    type: Object as PropType<Subject | Folder>,
    default: () => {},
  },
  darkBackground: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  textColor: {
    type: String as PropType<string>,
    default: () => "",
  },
})

/**
 * @description Media types
 * @param ({Subject} | {Folder}) item
 * */
function mediaTypes(item: Subject | Folder): MediaTypes[] {
  return [
    {
      i: "article",
      count: item.mediatypes.articles?.count,
      text:
        item.mediatypes.articles?.count === 1
          ? "artikkel"
          : "artikler",
    },
    // {
    //   i: 'user-headphones',
    //   count: 2,
    //   text: 'podkaster'
    // },
    {
      i: "video",
      count: item.mediatypes.videos?.count,
      text: item.mediatypes.videos?.count === 1 ? "video" : "videoer",
    },
    {
      i: "check",
      count: item.mediatypes.quizes?.count,
      text:
        item.mediatypes.quizes?.count === 1
          ? "øvingsoppgave"
          : "øvingsoppgaver",
    },
  ]
}

const windowWidth = ref(window.innerWidth)

const getIconSize = () => {
  if (windowWidth.value >= 744) {
    return "md"
  }
  return "sm"
}
</script>

<template>
  <ul class="<md:flex <md:gap-1.5">
    <li
      v-for="(el, k) in mediaTypes(data)"
      :key="k"
      :class="[
        { '!hidden': !el.count },
        {
          'fill-white text-white':
            darkBackground && !textColor.length,
        },
        {
          'fill-black text-black':
            !darkBackground && !textColor.length,
        },
        {
          '<md:bg-white <md:bg-opacity-10':
            darkBackground && !textColor.length,
        },
        {
          '<md:bg-white <md:bg-opacity-60':
            !darkBackground && !textColor.length,
        },
        textColor,
      ]"
      class="compact-view flex w-fit items-center gap-4 text-xl duration-200"
    >
      <u-icon
        :name="el.i"
        :size="getIconSize()"
        class="text-inherit"
      />

      <div class="text-nav-element flex text-[18px] text-inherit">
        <span>{{ el.count }}&nbsp;</span>
        <span class="<md:hidden">{{ el.text }}</span>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="postcss">
.compact-view {
  @apply <md:gap-[5px] <md:rounded-[30px] <md:px-2.5;
}
.text-nav-element span {
  @apply font-national2 text-[18px];
}

.text-nav-element {
  @apply font-national2 text-[22px] font-normal not-italic;
}
</style>

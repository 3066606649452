<script setup lang="ts">
import type { PropType } from "vue"
import type { Video } from "~/types/media"

const props = defineProps({
  src: {
    type: String as PropType<string>,
    default: "",
  },
  title: {
    type: String as PropType<string>,
    default: "",
  },
  publishedBy: {
    type: String as PropType<string>,
    default: "",
  },
  published: {
    type: String as PropType<string>,
    default: "",
  },
  poster: {
    type: String as PropType<string>,
    default: undefined,
  },
  timestamps: {
    type: Array as PropType<
      { id: string; description: string; time: string }[]
    >,
    default: () => [],
  },
  captions: {
    type: Array as PropType<Video["captions"]>,
  },
  autoplay: Boolean,
  muted: Boolean,
})

const displayFormater = new Intl.DisplayNames(
  navigator.language ?? "nb",
  { type: "language" },
)
const video = ref() as Ref<HTMLVideoElement | undefined>
const play = ref(false)
const played = ref(false)
const videoLoaded = ref(true)
const isMuted = ref(props.muted)

function convertToSeconds(time: string) {
  return time
    .split(":")
    .reverse()
    .reduce(
      (prev, curr, i) =>
        prev + (curr as unknown as number) * Math.pow(60, i),
      0,
    )
}

function onPlay(time: string) {
  const seconds = convertToSeconds(time)

  if (!video.value) return

  video.value.currentTime = seconds
  video.value.play()
  played.value = true
  video.value.setAttribute("controls", "controls")
}

// Does not fire on iOS Safari.
// Is set to true by default.
function onLoad() {
  videoLoaded.value = true
}

function toDisplayLanguage(code: string) {
  const name = displayFormater.of(code) ?? code
  return name.charAt(0).toUpperCase() + name.slice(1)
}

const baseUri = import.meta.env.VITE_IBEXA_BASEURL
const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.target instanceof HTMLVideoElement) {
      entry.isIntersecting
        ? entry.target.play()
        : entry.target.pause()
    }
  })
})

onMounted(() => {
  if (props.autoplay && video.value) {
    played.value = true
    video.value?.setAttribute("controls", "controls")
    observer.observe(video.value)
  }
})
</script>

<template>
  <div class="flex-wrapper">
    <div class="grid w-full grid-rows-[1fr,max-content,1fr]">
      <video
        ref="video"
        :poster="poster"
        :class="[{ 'opacity-0': !videoLoaded }]"
        controlsList="nodownload"
        :src="src"
        preload="auto"
        class="col-start-1 row-start-1 row-end-4 place-self-stretch bg-black transition-opacity duration-300 ease-in-out"
        playsinline
        crossorigin="anonymous"
        :muted="isMuted"
        @loadeddata="onLoad"
      >
        <track
          v-for="caption in captions"
          :key="caption.captionFile.uri"
          :src="baseUri + caption.captionFile.uri"
          :srclang="caption.language"
          :label="toDisplayLanguage(caption.language)"
        />
      </video>

      <u-button
        v-if="muted"
        class="absolute left-6 top-6"
        pill
        variant="filled"
        style="--color: white"
        @click="isMuted = !isMuted"
      >
        <template #prepend>
          <u-icon v-if="isMuted" name="speaker-mute" />
          <u-icon v-else name="speaker2" />
        </template>
      </u-button>

      <button
        id="video-test"
        :class="{ invisible: !videoLoaded || played }"
        width="18"
        height="18"
        class="video-play-button col-start-1 row-start-2 place-self-center"
        @click="onPlay('0')"
      >
        <u-icon
          v-if="!play"
          name="play"
          size="sm"
          class="media-play-icon text-black"
          aria-hidden="true"
        />
        <u-icon
          v-else
          name="pause"
          class="media-play-icon text-black"
          aria-hidden="true"
        />
      </button>
      <div
        :class="{ invisible: !videoLoaded || played }"
        class="col-start-1 row-start-3 grid place-content-center px-6 font-national2compressed text-4xl font-bold text-white"
      >
        {{ title }}
      </div>
    </div>
    <div class="time-stamps text-portal-indigo-400 text-opacity-50">
      <u-button
        v-for="(t, k) in timestamps"
        :key="k"
        variant="filled"
        pill
        style="--color: white"
        @click="onPlay(t.time)"
      >
        {{ t.time }}
        {{ t.description }}
      </u-button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.video-overlay {
  @apply absolute left-0 top-0 z-10 h-full w-full
  after:absolute after:left-0 after:top-0 after:h-full after:w-full
  after:bg-gradient-to-t after:opacity-100 after:content-[''];
}

.flex-wrapper {
  @apply relative flex h-full w-full flex-col items-center justify-center;
}

.video-play-button {
  @apply grid h-[50px] w-[50px] transform place-content-center rounded-full bg-paper
  duration-200 hover:scale-105 md:h-[96px] md:w-[96px];
}

.time-stamps {
  @apply mb-9 mt-5 flex flex-wrap gap-x-[10px] gap-y-[24px] md:my-6;

  /* top, right, bottom, left, gap */
  @apply pl-[20px] pr-[20px];

  /* Spacing md */
  @apply md:pl-[69px] md:pr-[69px];

  /* lg */
  @apply lg:px-[0];

  /* 2xl */
  @apply 2xl:my-12;
}
</style>

<script setup lang="ts">
const products = useProductContentStore()

const personalization = usePersonalizationStore()

const articles = computed(() => {
  return products.articles
})

const fullScreenId = ref(-1)
function fullScreen(key: number) {
  fullScreenId.value = key
}

onMounted(() => {
  if (personalization.lastActiveArticle) {
    const id = personalization.lastActiveArticle.id
    const element = document.getElementById(id.toString())
    if (element) {
      setTimeout(() => {
        element.scrollIntoView()
        window.scrollBy(0, -200)
      }, 100)
      fullScreenId.value = id
    }
  }
})
</script>

<template>
  <div
    class="w-[21rem] max-w-[70rem] pb-32 pt-[1.5em] lg:w-full xl:pt-[2.5em]"
  >
    <ul
      v-if="articles.length"
      id="article-cards"
      :key="0"
      class="flex flex-col gap-10"
    >
      <li v-for="(i, key) in articles" :key="key">
        <router-link
          :to="`/produkter/${
            i.href.split('/')[1].replace(/-(?!.*-)/, '/') +
            '/' +
            i.href.split('/')[2] +
            '/' +
            i.href.split('/')[3]
          }`"
          class="flex flex-col gap-3"
          :class="{ ['pointer-events-none']: i.restricted }"
          @click="fullScreen(i.id)"
        >
          <div
            class="transition-transform duration-150 ease-linear md:hover:scale-[101%] <md:flex <md:flex-col <md:items-center <md:justify-center"
          >
            <u-dynamic-article
              :id="i.id"
              :style="
                fullScreenId === i.id
                  ? 'view-transition-name: figure-caption;'
                  : ''
              "
              :disabled="true"
              aria-disabled="true"
              class="max-h-max-content pointer-events-none max-h-full w-full select-none overflow-hidden rounded-3xl md:!h-[537px] md:w-full <md:h-96"
              :data="i"
              :preview="true"
              :key-val="key"
            />
            <div
              class="border-b-2 border-white border-opacity-40 pt-2 font-national2 text-white md:hidden md:pt-0"
            >
              {{ `${i.title}` }}
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <u-skeleton-card-list v-else :key="1" />
  </div>
</template>

<route lang="yaml">
name: subject
meta:
  layout: subject
</route>

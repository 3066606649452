<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components"
import type { PropType, Ref } from "vue"
import type { Article } from "~/types/article"

defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const emit = defineEmits(["click"])
const showCaption = ref(false) as Ref<boolean>

function toggle() {
  emit("click")
  showCaption.value = !showCaption.value
}
</script>

<template>
  <div v-if="data?.imageCaption?.html" class="image-caption">
    <transition name="fade" mode="out-in">
      <div
        v-if="showCaption"
        v-on-click-outside.bubble="() => (showCaption = false)"
        class="box"
      >
        <component
          :is="{
            name: 'richtext',
            template: data?.imageCaption?.html,
          }"
        />
      </div>
    </transition>

    <u-button
      v-model:active="showCaption"
      variant="filled"
      pill
      class="absolute bottom-0 right-0 z-20 m-3 text-portal-indigo-900 text-opacity-50"
      style="--color: white"
      @click.stop
    >
      <template #prepend>
        <u-icon name="camera" />
      </template>
    </u-button>
  </div>
</template>

<style scoped lang="postcss">
.image-caption {
  @apply absolute bottom-0 right-0 w-full;

  /* & > button {
    @apply flex items-center justify-center;
    @apply w-[30px] h-[30px] lg:w-[36px] lg:h-[36px];

    @apply fill-white p-3 m-3 absolute bottom-0 right-0 z-20 rounded-full hover:bg-u-contrast;
    &.active {
      @apply bg-u-contrast bg-opacity-100;
    }
    &.inactive {
      @apply bg-portal-indigo-900 bg-opacity-50;
    }
  }*/
  & > .box {
    @apply absolute bottom-0 right-0
    z-10 w-full bg-black bg-opacity-50 py-5 !font-national2 text-white backdrop-blur-[4px];
    @apply max-h-[396px] overflow-auto;
    @apply px-6 lg:px-16;

    /*& > * {
      @apply !font-national2 text-[18px] leading-8 sm:text-[20px] lg:text-[22px] lg:leading-8;
    }*/
  }
}
</style>

<script setup>
const router = useRouter()
</script>

<template>
  <div
    class="container flex flex-col items-center justify-center py-40 text-white"
  >
    <p class="mb-10 text-center">
      Oi, denne siden ser ikke ut til å eksistere.
    </p>
    <div class="flex flex-col justify-center gap-5 md:flex-row">
      <u-button @click="router.push('/')"> Gå til forsiden </u-button>
      <u-button @click="router.back()"> Gå tilbake </u-button>
    </div>
  </div>
</template>

<route lang="yaml">
name: "404"
meta:
  title: 404
  layout: 404
</route>

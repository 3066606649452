<script setup lang="ts">
import ULoginStep from "~/components/UCheckoutPage/ULoginStep.vue"
import UPaymentStep from "~/components/UCheckoutPage/UPaymentStep.vue"
import { useProductVariantsStore } from "~/store/productVariants"
import { variantFullNameMap } from "~/utilities/constants"

const route = useRoute<"productCheckout">()
const router = useRouter()
const auth = useAuth()

const productInformationStore = useProductInformationStore()
const productData = productInformationStore.productData
if (productData.length === 0) {
  await productInformationStore.setProductData()
}

const products = useProductVariantsStore()
const currentProduct = computed(() => products.activeProduct)

const selectedProductVariant = ref(
  route.query.variant?.toString() ?? "",
)

watch(selectedProductVariant, () => {
  router.push({
    query: {
      ...route.query,
      variant: selectedProductVariant.value,
    },
  })
})

const selectedVariantInfo = computed(() => {
  return currentProduct.value?.variants.find(
    v => v.shortVariantLabel === selectedProductVariant.value,
  )
})

const userHasLicenceToSingleVariant = computed(() => {
  return (
    auth.isAuthorized.value &&
    products.activeVariant &&
    products.activeVariant.userHasLicence
  )
})

const options = computed<
  Array<{
    value: string
    label: string
  }>
>(() => {
  return (
    currentProduct.value?.variants
      .filter(v => v.isReleased)
      .map(v => {
        return {
          value: v.shortVariantLabel,
          label:
            variantFullNameMap[v.shortVariantLabel.toLowerCase()] ??
            v.shortVariantLabel,
        }
      }) ?? []
  )
})

const redirectUrl = computed(() => {
  if (route.query.variant) {
    return `${route.path}?variant=${route.query.variant}`
  } else {
    return route.path
  }
})

const checkoutError = ref(false)
const checkoutShow = ref(false)

watchEffect(() => {
  if (products.activeVariant) {
    selectedProductVariant.value =
      products.activeVariant.shortVariantLabel
  }
})

watchEffect(() => {
  if (userHasLicenceToSingleVariant.value && currentProduct.value) {
    router.push(
      `/produkter/${route.params.product}/${products.activeVariant?.variantKey}`,
    )
  }
})

const paymentStep = computed(() => {
  if (products.activeVariant) {
    // Skip variant selection step, and set payment to step 2 instead
    return "2"
  } else {
    return "3"
  }
})

const activeStep = computed(() => {
  if (!auth.isAuthorized.value) {
    return "1"
  } else if (!selectedProductVariant.value) {
    return "2"
  } else {
    return paymentStep.value
  }
})
</script>

<template>
  <div
    class="checkout-container flex w-full max-w-[100em] flex-col gap-6 py-8"
  >
    <u-modal
      v-model="checkoutShow"
      close-icon="close-circle"
      close-icon-size="30"
    >
      <section id="vipps-checkout-frame-container" />
    </u-modal>

    <ULoginStep
      step="1"
      :active-step="activeStep"
      :show-login-button="!auth.isAuthorized.value"
      :redirect-url="redirectUrl"
    />
    <USelectVariantStep
      v-if="paymentStep === '3'"
      v-model="selectedProductVariant"
      step="2"
      :active-step="activeStep"
      :show-content="auth.isAuthorized.value"
      :options="options"
    />
    <UPaymentStep
      v-model:checkout-show="checkoutShow"
      v-model:checkout-error="checkoutError"
      :step="paymentStep"
      :active-step="activeStep"
      :selected-product="selectedVariantInfo"
      :product-key="
        route.params.product ?? currentProduct?.productTitle
      "
    />
  </div>
</template>

<style scoped>
.checkout-container section:last-child {
  border-bottom: 0;
}
</style>

<route lang="yaml">
name: productCheckout
meta:
  title: Betaling
  layout: default
</route>

<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  dynamicClass: {
    type: String as PropType<string>,
    default: "",
  },
})

const emit = defineEmits(["update:modelValue"])
const route = useRoute()

const show = computed({
  get: () => props.modelValue,
  set: val => emit("update:modelValue", val),
})

watch(show, value => {
  toggleMenu(value)
})

watch(route, value => {
  if (value) toggleMenu(false)
})

function toggleMenu(value: boolean) {
  value
    ? document.body.classList.add("overflow-hidden")
    : document.body.classList.remove("overflow-hidden")
}
</script>

<template>
  <teleport to="body">
    <transition name="fade-in-down" mode="out-in">
      <div
        v-if="show"
        id="defaultModal"
        tabindex="-1"
        class="nav-menu"
        :class="dynamicClass"
        aria-modal="true"
        role="dialog"
      >
        <div>
          <slot />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped lang="postcss">
.nav-menu {
  @apply fixed bottom-0 left-0
  right-0 z-30 m-auto mx-auto flex h-full overflow-y-auto
  overflow-x-hidden backdrop-blur backdrop-saturate-100 md:inset-0;

  & > div {
    @apply w-full px-3 pt-20 lg:px-5;
  }
}
</style>

export const routes = [
  {
    path: '/',
    name: 'hjem',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/index.vue'),
    /* no children */
    meta: {
      "title": "Kunne",
      "description": "Kunne gjør det enklere å forstå pensum og stå på eksamen."
    }
  },
  {
    path: '/:product/search-offline',
    name: 'search-offline',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/[product].search-offline.vue'),
    /* no children */
    meta: {
      "title": "Søk",
      "layout": "navigation"
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/404.vue'),
    /* no children */
    meta: {
      "title": 404,
      "layout": 404
    }
  },
  {
    path: '/blogg/:path',
    name: '/blogg',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/blogg.vue'),
    /* no children */
    meta: {
      "title": "Blogg",
      "layout": "marketing"
    }
  },
  {
    path: '/feature-flag',
    name: 'featureFlag',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/feature-flag.vue'),
    /* no children */
    meta: {
      "title": "featureFlag",
      "layout": "basic"
    }
  },
  {
    path: '/om/:path?',
    name: '/om',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/om.vue'),
    /* no children */
    meta: {
      "title": "Om",
      "layout": "marketing"
    }
  },
  {
    path: '/produkter/:product',
    name: 'productPage',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].vue'),
    /* no children */
    meta: {
      "title": "Forside",
      "layout": "productPage"
    }
  },
  {
    path: '/produkter/:product/:variant',
    name: 'product',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].vue'),
    /* no children */
    meta: {
      "title": "Hjem",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/:subject',
    name: 'subject',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].[subject].vue'),
    /* no children */
    meta: {
      "layout": "subject"
    }
  },
  {
    path: '/produkter/:product/:variant/:subject/:article',
    name: 'article',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].[subject].[article].vue'),
    /* no children */
    meta: {
      "layout": "article",
      "is": "article",
      "authorize": true
    }
  },
  {
    path: '/produkter/:product/:variant/:subject/min-side',
    name: '/produkter.[product].[variant].[subject].min-side',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].[subject].min-side.vue'),
    /* no children */
    meta: {
      "title": "Min side",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/begreper',
    name: 'begreper',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].begreper.vue'),
    /* no children */
    meta: {
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/checkout',
    name: 'checkout',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].checkout.vue'),
    /* no children */
    meta: {
      "title": "Betaling",
      "layout": "basic"
    }
  },
  {
    path: '/produkter/:product/:variant/kategorier',
    name: '/produkter.[product].[variant].kategorier',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].kategorier.vue'),
    /* no children */
    meta: {
      "title": "Kategorier",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/logg-inn',
    name: 'login',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].logg-inn.vue'),
    /* no children */
    meta: {
      "title": "Logg inn",
      "layout": "basic",
      "loggedInRedirect": true
    }
  },
  {
    path: '/produkter/:product/:variant/min-side',
    name: '/produkter.[product].[variant].min-side',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].min-side.vue'),
    /* no children */
    meta: {
      "title": "Min side",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/search',
    name: 'search',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].search.vue'),
    /* no children */
    meta: {
      "title": "Søk",
      "layout": "navigation"
    }
  },
  {
    path: '/produkter/:product/checkout',
    name: 'productCheckout',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].checkout.vue'),
    /* no children */
    meta: {
      "title": "Betaling",
      "layout": "default"
    }
  },
  {
    path: '/produkter/:product/logg-inn',
    name: 'login2',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].logg-inn.vue'),
    /* no children */
  },
  {
    path: '/registrer',
    name: 'signup',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/registrer.vue'),
    /* no children */
    meta: {
      "title": "Registrer ny bruker",
      "layout": "public"
    }
  },
  {
    path: '/tilbakestill-passord',
    name: 'reset-password',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/tilbakestill-passord.vue'),
    /* no children */
    meta: {
      "title": "Tilbakestill passord",
      "layout": "public"
    }
  }
]

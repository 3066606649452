import type { EcommerceProduct } from "~/types/productData.d"
import type { Ref } from "vue"

import { acceptHMRUpdate, defineStore } from "pinia"

import { getProductData } from "~/hooks/payment"

export const useProductInformationStore = defineStore(
  "productInformation",
  () => {
    /** State */
    const productData: Ref<EcommerceProduct[]> = ref([])

    /** Actions */
    const setProductData = async () => {
      productData.value = await getProductData()
    }

    return {
      /** State */
      productData,
      /** Actions */
      setProductData,
    }
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useProductInformationStore, import.meta.hot),
  )

<script setup lang="ts">
import type { PropType, Ref } from "vue"
import type { Article } from "~/types/article.js"

defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  bgOverlay: {
    type: String as PropType<string>,
    default: "paper",
  },
})

const autoplay = ref(true)
const videoElement = ref({}) as Ref<HTMLVideoElement>

onMounted(() => addEventListener("fullscreenchange", pauseVideo))
onBeforeUnmount(() =>
  removeEventListener("fullscreenchange", pauseVideo),
)

function pauseVideo() {
  if (!document.fullscreenElement) {
    videoElement.value.pause()
    autoplay.value = false
  } else {
    autoplay.value = true
    videoElement.value.play()
  }
}

function getVideo(data: any) {
  if (!data) return
  const url = data?.blob
    ? URL.createObjectURL(data.blob)
    : data.elementURI

  return url
}
</script>

<template>
  <u-video
    v-if="data.video?.metadata"
    :src="getVideo(data.video?.metadata)"
    :poster="data.video.img.src || data.video.metadata.image"
    :timestamps="data.video.timestamps"
    :captions="data.video.captions"
  />
</template>

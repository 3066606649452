type shadeOptions = "dark" | "light" | "medium" | null

export const setThemeColor = (themeColor: string) => {
  const getThemeColors = (theme: string) => {
    if (theme === "purple") {
      return {
        light: "#F9EFF7",
        medium: "var(--color-purple-100)",
        dark: "#311236",
        lightAccent: "var(--color-purple-80)",
        darkAccent: "var(--color-purple-20)",
      }
    } else if (theme === "blue") {
      return {
        light: "var(--color-blue-10)",
        medium: "var(--color-blue-100)",
        dark: "var(--color-portal-indigo-900)",
        lightAccent: "var(--color-blue-60)",
        darkAccent: "var(--color-blue-20)",
      }
    } else if (theme === "light-blue") {
      return {
        light: "#E5F0FF",
        medium: "#003990",
        dark: "#002B65",
        lightAccent: "#0469FF",
        darkAccent: "#B3D2FF",
      }
    } else if (theme === "teal") {
      return {
        light: " #DFF5F5",
        medium: "var(--color-teal-100)",
        dark: "#0B3030",
        lightAccent: "var(--color-teal-80)",
        darkAccent: "var(--color-blue-20)",
      }
    } else
      return {
        light: "var(--color-paper)",
        medium: "var(--color-grey-80)",
        dark: "#121212",
        lightAccent: "var(--color-grey-60)",
        darkAccent: "var(--color-grey-20)",
      }
  }

  // Set theme colors
  const theme = computed(() => getThemeColors(themeColor))

  document.documentElement.style.setProperty(
    "--u-color-light",
    theme.value.light,
  )
  document.documentElement.style.setProperty(
    "--u-color-dark",
    theme.value.dark,
  )
  document.documentElement.style.setProperty(
    "--u-color-accent-light",
    theme.value.lightAccent,
  )
  document.documentElement.style.setProperty(
    "--u-color-accent-dark",
    theme.value.darkAccent,
  )
  document.documentElement.style.setProperty(
    "--u-color-medium",
    theme.value.medium,
  )
}

export const setBodyBackground = (shade?: shadeOptions) => {
  if (shade === "dark") {
    document.body.style.backgroundColor = "var(--u-color-dark)"
  } else if (shade === "light") {
    document.body.style.backgroundColor = "var(--u-color-light)"
  } else if (shade === "medium") {
    document.body.style.backgroundColor = "var(--u-color-medium)"
  } else {
    document.body.style.backgroundColor = "var(--color-paper)"
  }
}

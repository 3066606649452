import type {
  ArticleEdgeNodes,
  ArticleNode,
  ArticleNodeContent,
} from "~/types/ibexa.article"
import type { SubjectPageNode } from "~/types/ibexa.subject-page"
import type { Article } from "~/types/article"

import GraphqlArticlesDocument from "~/graphql/documents/articles"
import GraphqlArticleDocument from "~/graphql/documents/article"
import { graphqlClient } from "~/graphql/client"
import { formatData } from "./format-data"
import { FOLDER_REGEX } from "~/utilities/constants"

/**
 * Get the articles from Ibexa
 * @param product
 * @param subject
 * @returns the articles
 */
export default async (locationId: number): Promise<Article[]> => {
  const { data } = (await graphqlClient({
    query: GraphqlArticlesDocument,
    variables: {
      locationId,
    },
  })) as ArticleEdgeNodes

  if (!data) return []
  const query: ArticleNode[] =
    data?._repository.location.children?.edges
  const edges: ArticleNodeContent[] | SubjectPageNode[] = query?.map(
    ({ node }: ArticleNode) => node,
  )
  const articleEdges = edges?.filter(
    (edge: ArticleNodeContent) =>
      edge.content._type.identifier === "article",
  )
  const nodes = articleEdges?.map((node: ArticleNodeContent) =>
    formatData(node),
  )

  const processedNodes = nodes?.map((node: Article) => ({
    ...node,
    href: node.href.replace(FOLDER_REGEX, ""),
    hrefRaw: node.href,
  }))
  return processedNodes
}

export async function getArticleByLocationId(
  id: number,
): Promise<Article | null> {
  const { data } = await graphqlClient({
    query: GraphqlArticleDocument,
    variables: { id },
  })

  if (!data) return null

  const articleData = data._repository.location as ArticleNodeContent
  const article = formatData(articleData)

  return article
}

<script setup lang="ts">
import type { PropType } from "vue"
import type { PageUrlAlias } from "~/types/pages"

const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  scrolled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const emit = defineEmits(["update:modelValue"])
const data = useDataStore()
const model = computed({
  get: () => props.modelValue,
  set: val => emit("update:modelValue", val),
})

const navItems = reactive<Array<PageUrlAlias & { external?: true }>>([
  { title: "Kunne Exphil", priority: 0, url: "/exphil" },
  {
    title: "Hjelpesider",
    priority: 20,
    url: "https://om.kunne.universitetsforlaget.no/hjelpesider",
    external: true,
  },
])

const sortedNavItems = computed(() =>
  [...navItems].sort((a, b) => a.priority - b.priority),
)

data.getPageNav().then(pageNavs => {
  navItems.push(...pageNavs)
})

// const navigation = ref([
//   { text: 'Kunne Exphil', href: 'https://kunne.no/exphil' },
//   { text: 'Om Kunne', href: 'https://kunne.universitetsforlaget.no' },
//   { text: 'Hjelpesider', href: 'https://om.kunne.universitetsforlaget.no/hjelpesider' },
//   { text: 'Personvern', href: 'https://kunne.universitetsforlaget.no/personvern' },
//   { text: 'Informasjonskapsler', href: 'https://kunne.universitetsforlaget.no/informasjonskapsler' },
//   { text: 'Kjøpsvilkår for Kunne', href: 'https://kunne.universitetsforlaget.no/kjopsvilkar' }
// ])
</script>

<template>
  <u-menu
    v-model="model"
    :dynamic-class="
      scrolled ? 'bg-u-contrast' : 'bg-portal-indigo-900'
    "
  >
    <div class="flex h-full w-full flex-col items-center">
      <div class="lg:max-w-1/2 m-auto">
        <ul class="mb-20 flex flex-col gap-y-4">
          <li v-for="(i, k) in sortedNavItems" :key="k">
            <a
              :href="i.url"
              :class="[{ 'hover:text-u-contrast': !scrolled }]"
              class="text-2xl text-white underline lg:text-3xl"
            >
              {{ i.title }}
            </a>
          </li>
          <li
            :class="[{ 'hover:text-red-60': !scrolled }]"
            class="text-2xl text-white underline lg:text-3xl"
          >
            <a href="#" class="cky-banner-element"
              >Administrer informasjonskapsler</a
            >
          </li>
        </ul>
      </div>
    </div>
  </u-menu>
</template>

<script setup>
import { setBodyBackground } from "~/utilities/theme"
setBodyBackground("light")

const products = useProductVariantsStore()
</script>

<template>
  <div
    class="flex min-h-screen flex-col items-center justify-between"
  >
    <div class="w-[90vw] md:w-[80vw]">
      <UPageHeader />
      <main class="flex h-full items-center justify-center">
        <router-view />
      </main>
    </div>
    <UPublicFooter />
  </div>
</template>

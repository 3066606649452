<script setup lang="ts">
import { setBodyBackground } from "~/utilities/theme"

const { product } = useDataStore()

onBeforeMount(async () => {
  setBodyBackground("dark")
})
</script>

<template>
  <div class="browse-layout">
    <header class="browse-layout__header <md:hidden">
      <u-product-title class="!p-0" />
    </header>

    <div class="browse-layout__view">
      <router-view />
    </div>
  </div>
</template>

<style scoped>
.browse-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--app-header-height) minmax(0, 1fr);
  gap: var(--gap-lg);
  max-width: calc(var(--app-width) + var(--app-padding) * 2);
  padding: 0 var(--app-padding);
  margin: 0 auto;
  color: white;
  /* font: 1rem/400 National2; */
  --app-header-height: 210px;
  --app-width: 1512px;
  --app-padding: 74px;
}

.browse-layout__header {
  display: grid;
  align-content: end;
  align-items: center;
  position: sticky;
  top: 0;
  padding-bottom: 3rem;
  gap: 3rem;
  height: var(--app-header-height);
  z-index: 1;
}

@media (max-width: 743px) {
  .browse-layout {
    --app-header-height: 1.25rem;
  }
}

@media (max-width: 1023px) {
  .browse-layout {
    --app-padding: 1.25rem;
  }
}

@media (max-width: 1679px) {
  .browse-layout {
    --app-width: calc(1512px - var(--app-padding) * 2);
  }
}
</style>

<script setup lang="ts" name="SearchPage">
import type { LocationQuery } from "vue-router"

const route = useRoute<"search">()
const returnTo = ref(firstString(route.query.from) || "/exphil")
function firstString(value: LocationQuery[1]) {
  return (Array.isArray(value) ? value[0] : value) ?? ""
}
</script>

<template>
  <div class="grid grid-cols-3">
    <div class="col-span-1">
      <div>
        <router-link
          v-wave
          :to="returnTo"
          class="grid rounded-full border-2 border-current p-4 hover:border-u-contrast md:fixed md:left-5 md:top-5"
          aria-label="Lukk søk"
        >
          <u-icon name="close" class="stroke-current" size="xxs" />
        </router-link>
      </div>

      <div>
        <u-search-bar />
        <p class="mt-5 font-national2 font-bold italic">0 treff</p>
        <hr class="my-2 border" />
      </div>
      <u-offline-message />
    </div>
  </div>
</template>

<route lang="yaml">
name: search-offline
meta:
  title: Søk
  layout: navigation
</route>

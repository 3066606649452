import type { ProductNode } from "~/types/ibexa.product"
import GraphqlProductDocument from "~/graphql/documents/product"
import { graphqlClient } from "~/graphql/client"

export default async (
  locationId: number | undefined,
): Promise<any> => {
  if (!locationId) return {}

  const { data: product } = (await graphqlClient({
    query: GraphqlProductDocument,
    variables: {
      locationId,
    },
  })) as ProductNode

  return {
    product: {
      id: product?._repository.location?.id,
      pathString: product?._repository.location?.pathString,
      title: product?._repository.location?.content?.title.replace(
        /-/,
        "/",
      ),
      shortTitle: product?._repository.location?.content?.shortTitle,
      ean: product?._repository.location?.content?.ean,
    },
  }
}

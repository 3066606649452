<script setup lang="ts">
import { computed } from "vue"
import { useDataStore } from "../../store/data"

const route = useRoute<"subject">()
const store = useDataStore()
const subjects = computed(() => store.subjects)
const currentSubject = computed(() =>
  subjects.value.find(i => i.href.includes(route.params.subject)),
)
const articles = computed(() =>
  store.articles.filter(
    i => i.parentLocationId === currentSubject.value?.id,
  ),
)
</script>

<template>
  <div
    class="fixed right-0 top-0 z-10 overflow-scroll bg-white p-4"
    style="width: 300px; height: 100vh"
  >
    <h1 class="mb-4 text-3xl font-bold">Overview</h1>
    <ul>
      <li v-for="(subject, index) in subjects" :key="index">
        <h2
          class="mb-5 text-2xl font-semibold"
          :class="{
            'text-u-contrast': subject.href.includes(
              route.params.subject,
            ),
          }"
        >
          {{ index + 1 }}. {{ subject.title }}
        </h2>
        <p>Subject index: {{ index }}</p>
        <p>Subject priority: {{ subject.priority }}</p>
        <p>Subject ID: {{ subject.id }}</p>
        <h2 class="my-5 text-xl text-blue-500">Articles:</h2>
        <ul class="list-inside list-decimal">
          <li
            v-for="(article, articleIndex) in articles.filter(
              i => i.parentLocationId === subject.id,
            )"
            :key="articleIndex"
            class="my-3"
          >
            <div
              :class="{
                'text-u-contrast': article.href.match(
                  String($route.path),
                ),
              }"
              class="mb-3"
            >
              {{ article.title }}
            </div>
            <p>Article index: {{ articleIndex }}</p>
            <p>Article priority: {{ article.priority }}</p>
            <p>Article ID: {{ article.id }}</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

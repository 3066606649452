<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
  name: {
    type: String as PropType<string>,
    default: "",
  },
  type: {
    type: String as PropType<string>,
    default: "",
  },
  label: {
    type: String as PropType<string>,
    default: "",
  },
  labelFor: {
    type: String as PropType<string>,
    default: "",
  },
  placeholder: {
    type: String as PropType<string>,
    default: "",
  },
  validation: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  pattern: {
    type: String as PropType<string>,
    default: "",
  },
})

const emit = defineEmits(["update:modelValue"])

const model = computed({
  get: () => props.modelValue,
  set: val => {
    emit("update:modelValue", val)
  },
})

const ctx = getCurrentInstance()
</script>

<template>
  <div :id="`input-group-${ctx?.uid}`" class="input-group pt-[1rem]">
    <u-form-label :label="label" :label-for="labelFor" />
    <u-input
      v-model="model"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      class="text-portal-indigo-900"
      :pattern="pattern"
      :validation="validation"
    />
  </div>
</template>

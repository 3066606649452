<script setup lang="ts">
onMounted(() => setBg("paper"))
</script>

<template>
  <div class="text scroll-smooth bg-[#FFF5F5] pt-20 text-[#1A3985]">
    <!-- Header -->
    <u-marketing-header />

    <!-- Main content -->
    <main class="pt-20 lg:pt-0">
      <router-view />
    </main>

    <u-product-page-footer max-width="w-[90vw] md:w-[80vw]" />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue"
import type { Article } from "~/types/article"

defineProps({
  article: {
    type: Object as PropType<Article>,
    required: true,
  },
})
// @ts-expect-error: unused variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let scrollOf = ref(false)
const route = useRoute<"subject">()
const router = useRouter()
const productContent = useProductContentStore()

const scrollof = (val: Ref<false> | Ref<true>) => {
  scrollOf = val
}

const previousArticle = computed(() => productContent.previousArticle)

const previousSubject = computed(() => productContent.previousSubject)

const canGoBack = computed(() => {
  if (previousArticle.value?.id || previousSubject.value?.id) {
    return true
  }
  return false
})

const goback = () => {
  if (productContent.previousArticle?.frontendHref) {
    router.push(productContent.previousArticle?.frontendHref)
  } else {
    console.info(
      "Navigating to 404 page:'productContent.previousArticle?.frontendHref' is not defined",
      productContent.previousArticle?.frontendHref,
    )
    router.push("/404")
  }
}
</script>

<template>
  <u-header
    id="header-main-content"
    class="u-grid pointer-events-none z-20 h-fit px-4 py-4"
    :scroll-of-mobile="true"
    :scroll-of="true"
    :scroll-of-id="`article-page-${article?.id}`"
    class-on-scroll="bg-portal-indigo-900"
    :limit-position="15"
    @scroll-of="scrollof"
  >
    <u-button
      class="pointer-events-auto col-start-1 place-self-end bg-paper"
      pill
      @click="
        router.push(
          `/produkter/${route.params.product}/${route.params.variant}/${route.params.subject}`,
        )
      "
    >
      <template #prepend>
        <u-icon name="minimize" class="scale-[0.8333333] transform" />
      </template>
    </u-button>

    <transition name="fade" mode="out-in">
      <u-button
        v-if="canGoBack"
        class="pointer-events-auto col-start-2 place-self-start bg-paper"
        pill
        @click="goback"
      >
        <template #prepend>
          <u-icon name="arrow-up" class="scale-[0.833]" />
          <div class="pr-2">Forrige</div>
        </template>
      </u-button>
    </transition>
  </u-header>
</template>

<script setup lang="ts">
import { createVippsLoginUrl, loginVipps } from "~/utilities/auth"

defineProps({
  showLoginButton: {
    type: Boolean,
    required: true,
  },
  hideStepIndicator: {
    type: Boolean,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  redirectUrl: {
    type: String,
    required: true,
  },
  activeStep: {
    type: String,
    required: true,
  },
})

const auth = useAuth()
const vippsUrl = createVippsLoginUrl()
</script>

<template>
  <UStep
    :hide-step-indicator="hideStepIndicator"
    :is-active-step="activeStep === step"
  >
    <template #step>{{ step }}</template>
    <template #title>
      <p
        v-if="auth.isAuthorized.value"
        class="font-numeraWebRegular text-3xl"
      >
        Logget inn som {{ auth.user.value.fullName }}
      </p>
      <p v-else>Logg inn eller registrer deg med Vipps</p>
    </template>
    <template #content>
      <div v-if="!auth.isAuthorized.value" class="font-national2">
        <p>
          Ved å logge inn og registerere deg som bruker på Kunne,
          samtykker du til vår
          <a class="underline" href="/om/personvern"
            >personvernerklæring</a
          >.
        </p>
        <br />
      </div>
      <div
        v-if="showLoginButton && !auth.isAuthorized.value"
        class="flex flex-col gap-10 font-numeraWebRegular text-3xl <md:gap-2"
      >
        <a
          v-wave
          class="fit-c block w-fit rounded-full"
          :href="vippsUrl"
          @click.prevent="loginVipps(redirectUrl)"
        >
          <img
            src="/images/vipps-logginn.svg"
            alt="Logg inn med Vipps"
            class="h-18"
          />
        </a>
        <div class="font-national2 text-lg">
          Har du ikke Vipps?
          <a
            href="https://vipps.no/hjelp/vipps/kom-i-gang-med-vipps/hvordan-skaffer-jeg-meg-vipps/"
            target="_blank"
            style="font: inherit; text-decoration: underline"
            >Slik går du frem for å skaffe Vipps.</a
          >
        </div>
      </div>
    </template>
  </UStep>
</template>

<script setup lang="ts">
import { setBodyBackground } from "~/utilities/theme"
import { useLoginPageUrl } from "~/composables/useLoginPageUrl"

const productContent = useProductContentStore()
const route = useRoute<"article">()
const router = useRouter()
const { isAuthorized } = useAuth()
const loginPageUrl = useLoginPageUrl()

const productParam = computed(() => route.params.product)

onBeforeMount(async () => {
  setBodyBackground()
})

onMounted(() => {
  layoutFocus()
})

watch(route, () => {
  layoutFocus()
})

function layoutFocus() {
  const layout = document.getElementById("article-layout")
  layout?.focus()
}

function scroll() {
  const el = Array.from(
    document.querySelectorAll("[id^=article-page-]"),
  ) as HTMLElement[]

  if (el.length) {
    const [page] = el
    page.focus()
  }
}

const goback = () => {
  if (productContent.previousArticle?.frontendHref) {
    router.push(productContent.previousArticle?.frontendHref)
  } else {
    console.info(
      "Navigating to 404 page:'productContent.previousArticle?.frontendHref' is not defined",
      productContent.previousArticle?.frontendHref,
    )
    router.push("/404")
  }
}
const goForward = () => {
  if (productContent.nextArticle?.frontendHref) {
    router.push(productContent.nextArticle?.frontendHref)
  } else {
    console.info(
      "Navigating to 404 page:'productContent.nextArticle?.frontendHref' is not defined",
      productContent.nextArticle?.frontendHref,
    )
    router.push("/404")
  }
}
</script>

<template>
  <div
    id="article-layout"
    class="article-layout !bg-paper outline-none"
    tabindex="0"
    style="view-transition-name: article"
    @keyup.esc="
      router.push(
        `/produkter/${productParam}/${route.params.subject}`,
      )
    "
    @keyup.exact.left="goback"
    @keyup.exact.right="goForward"
    @keyup.up="scroll"
    @keyup.down="scroll"
  >
    <u-article-loading-screen
      v-if="
        productContent.hydrateArticlesInProgress ||
        productContent.hydrationInProgress
      "
    />

    <div
      v-else
      class="grid h-[100dvh] grid-rows-[0,minmax(0,1fr),max-content]"
    >
      <div
        v-if="!productContent.activeArticle"
        class="richtext row-start-2 place-self-center"
      >
        <h1>Fant ikke artikkelen</h1>
        <p v-if="!isAuthorized">
          Kanskje du ser den hvis du
          <router-link :to="loginPageUrl">logger inn</router-link>?
        </p>

        <router-link
          class="mt-6 block"
          :to="`/produkter/${route.params.product}/${route.params.variant}/${route.params.subject}`"
        >
          Ta meg til forsiden av kapittelet
        </router-link>
      </div>

      <template v-else>
        <u-article-header
          v-if="productContent.activeArticle?.id"
          :article="productContent.activeArticle"
        />
        <router-view />
        <u-article-footer v-if="productContent.activeArticle?.id" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
const productRoute = useRoute<"product">()
const productParam = computed(() => productRoute.params.product)
const data = useDataStore()

data.getSubjects(productParam.value)
const productBlocks = ref()

onBeforeMount(async () => {
  try {
    await data
      .getProductBlocks(
        `/${import.meta.env.VITE_CMS_WEB_NAME}/forsider/` +
          productParam.value,
      )
      .then(blocks => (productBlocks.value = blocks?.children.edges))
  } catch (error) {
    console.error(error)
  }
})

const style = getComputedStyle(document.body)
style.getPropertyValue("--theme-loaded")
</script>

<template>
  <div
    class="flex h-full w-full flex-col items-center justify-center gap-4 bg-[--u-color-light] pb-40 text-xl text-[--u-color-dark] lg:gap-20"
  >
    <UProductPageBanner :product="data.product" />
    <UProductPageBlock
      v-for="productBlock in productBlocks"
      :key="productBlock.value"
      img-text-color="white"
      :product-block-data="productBlock.node.content"
    />
  </div>
</template>

<route lang="yaml">
name: productPage
meta:
  title: Forside
  layout: productPage
</route>

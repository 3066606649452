import gql from "graphql-tag"

export default gql`
  query ($urlAlias: String) {
    _repository {
      location(urlAlias: $urlAlias) {
        content {
          ... on ProductPageContent {
            id
            title
            header {
              html5
            }
            theme
          }
        }
      }
    }
  }
`

<script setup lang="ts">
import type { PropType } from "vue"
import type { Folder } from "~/types/folder"
import type { Subject } from "~/types/subject"

import { getColorClassNameFromScheme } from "~/utilities/colors"
import brightnessFormat from "~/utilities/brightness-format"

const props = defineProps({
  subjects: {
    type: Object as PropType<Subject[]>,
    required: true,
    default: [] as Subject[],
  },
  folder: {
    type: Object as PropType<Folder>,
    required: true,
    default: () => ({}),
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const route = useRoute()
const router = useRouter()

const hash = computed(() =>
  encodeURIComponent(
    props.folder.title.toLowerCase().replaceAll(/\s/g, "-"),
  ),
)
const isOpen = computed(() => {
  return route.hash.slice(1) === hash.value
})

function closeIfOpen(event: Event) {
  if (isOpen.value) {
    router.replace({ hash: "" })
    event.preventDefault()
  }
}

const textVal = getColorClassNameFromScheme(props.folder.colorTag, [
  "text",
])
const text = !textVal.length ? "white" : textVal

const setText = computed(() => {
  if (isOpen.value) return "text-white"
  return text
})

const backgroundVal = getColorClassNameFromScheme(
  props.folder.colorTag,
  ["bg"],
)
const background =
  backgroundVal === "white" || !backgroundVal.length
    ? "bg-purple-100"
    : backgroundVal

const backgroundColorValue = background
  .split("-")
  .filter(e => e !== "bg")
  .join("-")
const isDark = computed(() => brightnessFormat(backgroundColorValue))

const setBackground = computed(() => {
  if (isOpen.value) return "bg-none"
  return background
})

const shadowLeftVal = getColorClassNameFromScheme(
  props.folder.colorTag,
  ["shadowLeft"],
)
const shadowLeft =
  shadowLeftVal === "white" || !shadowLeftVal.length
    ? "rgba(117, 90, 113, 1)"
    : shadowLeftVal

const shadowBottomVal = getColorClassNameFromScheme(
  props.folder.colorTag,
  ["shadowBottom"],
)
const shadowBottom =
  shadowBottomVal === "white" || !shadowLeftVal.length
    ? "rgba(31, 26, 30, 1)"
    : shadowBottomVal

const isFolderEmpty = computed(() => {
  if (
    props.folder.mediatypes.articles.count === 0 &&
    props.folder.mediatypes.videos.count === 0 &&
    props.folder.mediatypes.quizes.count === 0
  ) {
    return true
  }
  return false
})
</script>

<template>
  <div class="relative">
    <div
      :class="[
        {
          'btn-wrapper': !isOpen,
          'pointer-events-none': isFolderEmpty,
        },
      ]"
    >
      <a
        :id="`folder-card-${folder.id}`"
        class="folder-card-button"
        :class="[
          setBackground,
          {
            '!h-[429px] pl-[10px] pt-[24px] md:!h-[600px] md:py-[40px]':
              !isOpen,
          },
          { 'pl-[10px] pt-[24px] md:py-0': isOpen },
          ,
          { 'opacity-60': isFolderEmpty },
        ]"
        :href="`#${hash}`"
        @click="closeIfOpen($event)"
      >
        <div
          class="flex w-full flex-col items-start text-left leading-none"
        >
          <h2 :id="hash" class="folder-slide-title">
            <div class="folder-title" :class="setText">
              {{ folder.priority }}.
              <div
                v-if="folder.titleHtml"
                class="inline"
                v-html="folder.titleHtml"
              />
              <template v-else>
                {{ folder.title }}
              </template>
            </div>
          </h2>
          <div
            v-if="!isOpen"
            :class="{ 'content-wrapper': folder.img.src.length }"
          >
            <img :src="folder.img.src" :alt="folder.img.alt" />
            <u-media-types
              class="self-end"
              :data="folder"
              :dark-background="isDark"
            />
          </div>
        </div>
      </a>
      <ul v-if="isOpen">
        <li
          v-for="(subject, key) in subjects"
          :id="`subject-card-list-item-${subject.id}`"
          :key="key"
        >
          <u-subject-card-item
            :subject="subject"
            :licence="licence"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.btn-wrapper {
  @apply mb-20 ml-4;
  &:before {
    content: "";
    background-color: v-bind("shadowLeft");
    transform: skewY(-40deg);
    @apply absolute left-[-13px] top-[0.78rem] h-full w-[29px];
  }

  &:after {
    content: "";
    transform: skew(-50deg);
    background-color: v-bind("shadowBottom");
    width: calc(100% - 16px);
    @apply absolute bottom-[-25px] left-[0.1rem] z-10 h-[25px];
  }
}

.folder-card-button {
  @apply sticky z-20 mb-[48px] flex h-fit w-full
  items-start justify-start
  overflow-hidden shadow-xl md:px-[66px] <md:p-4;
  .content-wrapper {
    @apply mx-3 mt-14 grid gap-11
    text-white
    md:gap-4 xl:mx-9 xl:mb-9 xl:mt-6 xl:grid-cols-[2fr,1fr];

    img {
      @apply max-h-[9.5rem] md:max-h-48 xl:max-h-60;
    }
  }
}

.folder-slide-title {
  @apply font-national2compressed font-bold uppercase leading-none text-white;
  @apply text-[40px] md:text-[50px] lg:text-[70px] xl:text-[130px];
}

.freemium {
  @apply mb-[-1rem] mt-4 block font-national2 text-[18px] font-bold text-[--u-color-accent-light] md:hidden;
}
</style>

<script setup lang="ts">
import { formatCurrency } from "~/utilities/format"
import * as Sentry from "@sentry/vue"
import { variantFullNameMap } from "~/utilities/constants"
import { ProductInformation } from "~/types/productVariant"
import { PropType } from "vue"

const props = defineProps({
  selectedProduct: {
    type: Object as PropType<ProductInformation>,
    required: true,
  },
  showPayment: {
    type: Boolean,
    required: true,
    default: true,
  },
  price: {
    type: Number as PropType<number>,
    required: false,
    default: undefined,
  },
})

const route = useRoute<"productCheckout" | "login2">() // TODO: Update login route name

const ecommerceData = computed(() => props.selectedProduct.eCommerce)
const productIsFree = computed(() => props.price === 0)

watchEffect(() => {
  if (!props.selectedProduct.ean || !ecommerceData.value) {
    Sentry.captureException(
      `There is a mismatch in EAN value from e-commerce and CMS for [${props.selectedProduct?.name}, ${props.selectedProduct?.shortVariantLabel}], e-commerce=[${ecommerceData.value?.ean}], cms=[${props.selectedProduct?.ean}]`,
    )
  }
})
</script>

<template>
  <div
    v-if="
      (showPayment && ecommerceData && selectedProduct) ||
      (!showPayment && selectedProduct.ean)
    "
    class="flex max-w-[720px] justify-between gap-14 rounded-2xl bg-white p-8 font-national2 text-xl shadow-xl <lg:flex-col"
  >
    <div
      class="flex aspect-square h-[232px] items-center justify-center rounded-md bg-[--u-color-light]"
    >
      <img
        :alt="
          ecommerceData?.imageDescription ||
          'Mangler bildebeskrivelse'
        "
        :src="ecommerceData?.imageUrl"
        class="max-h-[80%]"
      />
    </div>
    <div class="flex flex-col gap-2">
      <p class="mb-4">
        <b>{{
          variantFullNameMap[
            selectedProduct?.shortVariantLabel.toLowerCase()
          ] || ""
        }}</b>
      </p>
      <h2
        class="text- font-national2compressed text-4xl text-[--u-color-dark]"
      >
        <b>Kunne {{ selectedProduct?.name }}</b>
      </h2>
      <p>
        {{
          ecommerceData?.description || "Fant ikke produktbeskrivelse"
        }}
      </p>
      <div class="flex flex-col justify-between">
        <br />
        <div
          v-if="showPayment && !productIsFree"
          class="flex flex-row justify-between <md:flex-col"
        >
          <p>
            <span v-if="typeof price === 'number'"
              ><b>{{ formatCurrency(price) }}</b> per semester</span
            >
            <span v-else
              >Ops! Fant ikke pris for dette produktet, prøv igjen
              senere.</span
            >
            <br />
            (Stopper automatisk)
          </p>
          <br />
          <u-button
            v-show="!productIsFree && showPayment"
            class="cursor-pointer"
            type="submit"
            pill
            cta
            size="md"
            variant="filled"
            style="--color: white"
            >Betal</u-button
          >
        </div>
        <div>
          <u-button
            v-show="productIsFree || !showPayment"
            :to="`/produkter/${route.params.product}/${selectedProduct?.variantKey}`"
            size="md"
            pill
            cta
            variant="filled"
            style="--color: white"
            >Prøv gratis</u-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex flex-col gap-6 rounded-2xl bg-white font-national2 shadow-md sm:px-16 sm:py-16"
  >
    <p>
      Ops, noe gikk galt, og vi greide ikke hente informasjon om
      produktet.
    </p>
    <p>
      Prøv igjen litt senere eller ta kontakt med
      <a
        href="https://om.kunne.universitetsforlaget.no/hjelpesider/"
        rel="noreferrer"
        target="_blank"
        class="underline"
        >Kunne kundeservice</a
      >.
    </p>
  </div>
</template>

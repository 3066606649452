<script setup lang="ts">
import { setBodyBackground } from "~/utilities/theme"
setBodyBackground("light")
</script>

<template>
  <div>
    <!-- Header -->
    <u-auth-modal />

    <u-public-header />

    <!-- Main content -->
    <main>
      <router-view />
    </main>

    <!-- Footer -->
    <u-public-footer />
  </div>
</template>

import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { createPinia } from "pinia"
import { useCacheStore } from "./store/cache"
import { createHead } from "@vueuse/head"
import VWave from "v-wave"
import { listenForServiceWorkerMessages } from "~/utilities/pwa"
import { useDataStore } from "./store/data"

import * as Sentry from "@sentry/vue"
import { routes } from "vue-router/auto/routes"
import { setupLayouts } from "virtual:generated-layouts"

import App from "./App.vue"

import "./styles/main.css"

export { routes }

import "./scripts"
import { login } from "./utilities/auth"
import { BrowserTracing } from "@sentry/vue"
import { setThemeColor } from "./utilities/theme"

export const layoutRoutes = setupLayouts(routes)

export const app = createApp(App)

async function start() {
  if (location.pathname === "/am") {
    await login()
  }

  const lastusedProduct =
    localStorage.getItem("lastActiveProduct") ?? "exphil"
  const router = createRouter({
    routes: [
      // Redirects
      {
        path: "/",
        redirect: () => {
          return { path: `/produkter/${lastusedProduct}` }
        },
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: to => {
          console.log(
            "[404] - Redirected because no valid path was found",
          )
          return { path: "/404" }
        },
      },
      // File based routing
      ...setupLayouts(routes),
    ],
    history: createWebHistory(),

    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else if (to.hash) {
        return { el: to.hash, top: 300 }
      } else if (to.path !== from.path) {
        return { top: 0 }
      }
    },
  })

  router.beforeEach((to, _, next) => {
    if (to.params.product) {
      const data = useDataStore()
      const productPageData = computed(
        () => data.getProductPage().value,
      )
      try {
        data
          .setProductPage(
            `/${import.meta.env.VITE_CMS_WEB_NAME}/forsider/${
              to.params.product
            }`,
          )
          .then(() => setThemeColor(productPageData.value?.theme))
      } catch (error) {
        if (import.meta.env.VITE_CMS_WEB_NAME !== "preview") {
          console.error("Main 404 redirect")
          router.replace("/404")
        }
        return
      }
    } else {
      setThemeColor("blue")
    }

    if (typeof document.startViewTransition === "function") {
      document.startViewTransition(() => next())
    } else {
      next()
    }
  })

  const pinia = createPinia()
  app.use(pinia)
  app.use(router)
  const head = createHead()
  app.use(head)
  app.use(VWave, {})
  app.mount("#app")

  useCacheStore()
  listenForServiceWorkerMessages()

  const data = useDataStore()
  if (data.getProductPage().value === null) {
    try {
      data
        .setProductPage(
          `/${import.meta.env.VITE_CMS_WEB_NAME}/forsider/exphil`,
        )
        .then(() => setThemeColor(data.getProductPage().value?.theme))
    } catch (error) {
      return
    }
  }

  if (/production/.test(import.meta.env.MODE)) {
    Sentry.init({
      app: app,
      dsn: "https://f185c4a89a1c45d7a7f005f2029747c7@o239822.ingest.sentry.io/6147474",

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production

      // Disabled Sentry Replays
      // replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.

      // Disabled Sentry Replays
      // replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay(),
        new BrowserTracing({
          routingInstrumentation:
            Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["kunne.net", "kunne.no", "test.kunne.nu"],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
  }
}

start()

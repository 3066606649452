<script setup lang="ts">
import { useProductVariantsStore } from "~/store/productVariants"
import { variantFullNameMap } from "~/utilities/constants"

const auth = useAuth()
const route = useRoute<"login2">()
const router = useRouter()
const fromPath = ref(route.query.fromPath?.toString() ?? "")

const products = useProductVariantsStore()

const currentProduct = computed(() => products.activeProduct)

const validLicences = computed(
  () => currentProduct.value?.variants.filter(v => v.userHasLicence),
)

const selectedProductVariant = ref("")

const redirectUrl = ref(location.pathname)

const options = computed<
  Array<{
    value: string
    label: string
  }>
>(() => {
  return (
    currentProduct.value?.variants
      .filter(v => v.isReleased)
      .map(variant => ({
        value: variant.shortVariantLabel,
        label:
          variantFullNameMap[
            variant.shortVariantLabel.toLowerCase()
          ] ?? variant.shortVariantLabel,
      })) ?? []
  )
})

if (auth.isAuthorized.value && validLicences.value?.length === 1) {
  router.push(
    `/produkter/${route.params.product}/${validLicences.value[0].variantKey}`,
  )
}

watchEffect(() => {
  if (products.activeVariant) {
    selectedProductVariant.value = products.activeVariant.variantKey
  }
})

const selectedVariantInfo = computed(() => {
  return currentProduct.value?.variants.find(
    v => v.shortVariantLabel === selectedProductVariant.value,
  )
})

const navigateProductStep = computed(() => {
  if (products.activeVariant) {
    // Skip variant selection step, and set product navigation to step 2 instead
    return "2"
  } else {
    return "3"
  }
})

const activeStep = computed(() => {
  if (!auth.isAuthorized.value) {
    return "1"
  } else if (!selectedProductVariant.value) {
    return "2"
  } else {
    return navigateProductStep.value
  }
})
</script>

<template>
  <div
    class="login-container flex w-full max-w-[100em] flex-col gap-6 py-8"
  >
    <ULoginStep
      step="1"
      :hide-step-indicator="!auth.isAuthorized.value"
      :active-step="activeStep"
      :show-login-button="true"
      :redirect-url="fromPath ? fromPath : redirectUrl"
    />

    <USelectVariantStep
      v-if="auth.isAuthorized.value && navigateProductStep === '3'"
      v-model="selectedProductVariant"
      step="2"
      :active-step="activeStep"
      :show-content="auth.isAuthorized.value"
      :options="options"
    />
    <UNavigateProductStep
      v-if="auth.isAuthorized.value && selectedVariantInfo"
      :active-step="activeStep"
      :selected-product="selectedVariantInfo"
      :step="navigateProductStep"
    />
  </div>
</template>

<style scoped>
.login-container section:last-child {
  border-bottom: 0;
}
</style>

<route lang="yaml">
name: login2
meta:
title: Logg inn
layout: default
</route>

import gql from "graphql-tag"

export default gql`
  query ($locationId: Int) {
    _repository {
      location(locationId: $locationId) {
        id
        priority
        hidden
        parentLocationId
        pathString
        content {
          ... on ProductContent {
            ean
            title
            shortTitle
          }
        }
      }
    }
  }
`

<script setup lang="ts">
import { isAuthorized, logout } from "~/utilities/auth"
import { PropType } from "vue"
import { ProductsList } from "~/types/productVariant"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: false,
    default: undefined,
  },
  dark: {
    type: Boolean,
    default: true,
  },
})

const variantStore = useProductVariantsStore()

const route = useRoute<"product">()
const title = computed(() => props.product?.productTitle ?? "")

const productParam = computed(() => route.params.product)

const hasAtLeastOneReleasedProduct = computed(() => {
  if (variantStore.groupedProductVariants) {
    return variantStore?.groupedProductVariants[
      route.params.product as string
    ].hasAtLeastOneReleasedProduct
  }
})

const logoClass = `w-[48vw] max-w-[2em] font-national2compressed text-4xl font-extrabold uppercase leading-[1em] text-${
  props.dark ? "[--u-color-dark]" : "white"
} sm:max-w-[5.5em] md:text-5xl lg:w-[20vw] lg:text-6xl`

const loginPageUrl = useLoginPageUrl()
</script>

<template>
  <header
    class="flex h-full w-full items-center justify-center overflow-hidden"
  >
    <div
      class="flex h-fit w-full max-w-[100em] items-start justify-between py-8 lg:pt-20 xl:py-10"
    >
      <h1 v-if="title" :class="logoClass">
        <a :href="productParam ? '/produkter/' + productParam : '/'"
          >Kunne{{ ` ${title}` }}</a
        >
      </h1>
      <h1 v-else :class="logoClass"><a href="/">Kunne</a></h1>
      <u-button
        v-if="title && hasAtLeastOneReleasedProduct"
        :to="loginPageUrl"
        pill
        class="mt-[-10px]"
        size="lg"
        variant="quite"
        theme="skin"
        @click="() => (isAuthorized ? logout() : null)"
      >
        {{ !isAuthorized ? "Logg inn" : "Logg ut" }}
      </u-button>
    </div>
  </header>
</template>

<style lang=""></style>

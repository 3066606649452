<script setup lang="ts">
import type { PropType } from "vue"

defineProps({
  html: {
    type: String as PropType<string>,
    default: "",
  },
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
defineEmits(["update:click"])

const opened = ref(false)
const windowWidth = ref(window.innerWidth)

onMounted(() => {
  window.onresize = () => {
    windowWidth.value = window.innerWidth
  }
})

function getIconSize() {
  if (windowWidth.value >= 1680) {
    return "lg"
  }
  return "md"
}

function getIconStyle() {
  if (!opened.value)
    return "rounded-full border-paper border-solid border-[2px] hover:border-u-contrast"
  return "rounded-full border-portal-indigo-900 border-solid border-[2px] hover:border-u-contrast"
}
</script>

<template>
  <div
    v-show="html.length > 2"
    class="accordion"
    :class="opened ? 'opened' : ''"
  >
    <u-button
      text="TRANSKRIPSJON"
      :icon="opened ? 'minus-circle' : 'plus-circle'"
      class="flex items-center gap-[10px] font-national2 text-[20px] md:text-[28px]"
      :icon-class="getIconStyle()"
      :icon-size="getIconSize()"
      :dynamic-icon="true"
      @click="opened = !opened"
    />

    <transition name="fade">
      <u-rich-text
        v-show="opened"
        :html="html"
        class="secondary"
        :class="[preview ? 'preview' : 'fullscreen']"
        :short-title="false"
      />
    </transition>
  </div>
</template>

<!-- NB: delete -->
<style lang="postcss" scoped>
.accordion {
  @apply mx-5 rounded-[10px] bg-white bg-opacity-10 py-5 pl-6 text-paper;
  @apply md:ml-11 md:mr-16 2xl:px-24 2xl:py-4;
  &.opened {
    @apply bg-white text-black;
  }
  .text-container {
    overflow: hidden;
  }
  /* NB: does not seem to work */
  .fade-enter-active,
  .fade-leave-active {
    transition: height 0.3s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    height: 0px;
  }
}
</style>

<script setup>
import {
  downloadProgress,
  statusMessages,
} from "~/composables/offlineDownload"
const showDownloadsPopup = ref(false)

provide("isModalVisible", showDownloadsPopup)
</script>

<template>
  <div
    class="h-fit w-full overflow-hidden rounded-lg bg-white bg-opacity-10 p-6"
  >
    <div class="mb-4 flex items-center justify-start gap-3">
      <p class="text-2xl lg:text-4xl">
        <span class="text-2xl lg:text-3xl">Mine nedlastinger</span>
      </p>
    </div>
    <p class="text-md">
      {{ statusMessages[downloadProgress.status].description }}
    </p>

    <u-button
      id="activate-downloads-popup"
      variant="filled"
      :disabled="
        downloadProgress.status !== 'not-available' &&
        !isFeatureEnabled
      "
      class="mt-5"
      size="sm"
      @click="showDownloadsPopup = true"
    >
      <div class="flex items-center justify-center">
        <u-icon
          v-if="downloadProgress.status === 'not-available'"
          class="mr-2 scale-[0.833] fill-portal-indigo-900"
          name="download"
        />
        <u-icon
          v-if="downloadProgress.status === 'ready'"
          name="victory"
          size="md"
          class="mr-2 scale-[0.833]"
        />
        <span class="text-lg">{{
          statusMessages[downloadProgress.status].title
        }}</span>
      </div>
    </u-button>
    <u-download-modal />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue"

const props = defineProps({
  totalArticlesDone: {
    type: Number,
    default: 0,
  },
  totalChildren: {
    type: Number,
    default: 0,
  },
  chapterStatus: {
    type: String,
    default: "",
  },
})
</script>

<template>
  <div
    class="rounded-full px-2 text-base text-white opacity-75 group-hover:opacity-100"
  >
    <div class="flex items-center gap-2">
      <div>
        {{ props.totalArticlesDone }}/{{ props.totalChildren }}
      </div>
      <u-icon
        :name="`status-${props.chapterStatus}`"
        size="xs"
        :aria-label="`Artikler ${props.chapterStatus}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Article } from "~/types/article"

const props = defineProps({
  backgroundColorCode: {
    type: String as PropType<string>,
    default: "",
  },
  withImage: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  firstAudioArticleUrl: {
    type: String as PropType<string>,
    default: () => null,
  },
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  subjectPage: {
    type: Boolean,
    default: false,
  },
  plainText: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object as PropType<Article>,
    required: true,
  },
})

const timestamp = computed(() => {
  if (props.data.video == null) return

  const duration = Number(props.data.video.metadata.duration)
  const stamp = (n: number) =>
    Math.floor(n).toString().padStart(2, "0")

  return `${stamp(duration / 60)}:${stamp(duration % 60)}`
})
</script>

<template>
  <header :class="{ 'pt-[6rem]': !preview }">
    <u-subject-short-title
      :preview="preview"
      :plain-text="plainText"
      :subject-page="subjectPage"
      :parent-location-id="data.parentLocationId"
    />
    <div class="richtext">
      <h1
        v-if="data.titleHtml"
        class="inline"
        :class="[{ 'subject-page-title': subjectPage }, { preview }]"
        v-html="data.titleHtml"
      />

      <h1
        v-else
        class="inline"
        :class="[{ 'subject-page-title': subjectPage }, { preview }]"
      >
        {{ data.title }}
      </h1>

      <template v-if="timestamp && timestamp !== '00:00'">
        &nbsp;
        <!-- This space is here to force a normal space between title and timestmap -->
        <span class="h1 mb-4 !font-light">{{ timestamp }}</span>
      </template>
    </div>

    <u-audio-player-button
      v-if="!preview"
      :background-color-code="backgroundColorCode"
      :with-image="withImage"
      :first-audio-article-url="firstAudioArticleUrl"
      class="my-6"
      :class="{
        'text-[--u-color-accent-dark]':
          data.isDark || data.bg === 'portal-indigo-900',
      }"
      data-pendo="audio-player"
    />
  </header>
</template>

<style scoped lang="postcss">
h1 {
  &.subject-page-title {
    @apply font-national2compressed text-[54px] font-normal leading-[1] md:text-[130px] md:leading-[117px] <md:text-black;
  }
  &.preview {
    @apply text-lg leading-[18px] md:text-6xl md:leading-[54px];
  }
}
</style>

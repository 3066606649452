<script setup lang="ts">
const emit = defineEmits(["update:modelValue"])

const { login } = useAuth()

const route = useRoute()
const show = ref(true)

onBeforeMount(() => {
  toggleAuthModal()
})

watch(route, () => toggleAuthModal())

function toggleAuthModal() {
  show.value = route.meta?.showAuthModal ? true : false
}

const model = computed({
  get: () => show.value,
  set: val => emit("update:modelValue", val),
})
</script>

<template>
  <u-modal v-model="model" header-class="hidden">
    <div class="my-10 flex items-center justify-center">
      <div class="flex flex-col">
        <u-icon
          name="uf-logo"
          :style="{ width: 250 }"
          class="mx-auto mb-10 self-start justify-self-center fill-portal-indigo-900"
          size="lg"
        />

        <p>
          {{ "Logg inn eller registrer deg hvis du er ny" }}
        </p>

        <u-button
          text="Logg inn"
          shape="normal"
          class="my-5 bg-portal-indigo-900 hover:bg-u-contrast"
          @click="login()"
        />

        <u-button
          text="Registrer deg"
          shape="normal"
          class="bg-portal-indigo-900 hover:bg-u-contrast"
          @click="$router.push('/registrer')"
        />
      </div>
    </div>
  </u-modal>
</template>

import { useToastStore } from "~/store/toast"

const id = crypto.randomUUID()

export const useProductPrice = () => {
  const productStore = useProductVariantsStore()
  const addToast = useToastStore().addToast

  const price = computed(() => {
    const activeVariant = productStore.activeVariant
    if (activeVariant?.eCommerce) {
      return activeVariant?.eCommerce?.price
    } else {
      const ecommercePrice = activeVariant
        ? productStore.eCommerce.find(e =>
            e.title.includes(activeVariant.name),
          )?.price
        : undefined

      if (activeVariant && ecommercePrice === undefined) {
        addToast({
          message: `Vi greide ikke å hente prisen på Kunne ${activeVariant.name} akkurat nå. Dersom du ønsker å kjøpe dette, prøv igjen senere.`,
          id,
        })
      }

      return ecommercePrice
    }
  })

  return price
}

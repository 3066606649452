<!-- TODO: Remove this, no longer used -->

<script setup lang="ts">
const { resetPassword } = useAuth()
</script>

<template>
  <div
    class="container flex min-h-[calc(100vh-10rem)] items-center justify-items-center gap-x-20 py-10 md:py-0"
  >
    <div
      class="flex h-full w-full items-center justify-center rounded-sm text-white"
    >
      <div class="!max-w-[448px]">
        <div class="heading-3 my-3 !text-5xl">
          {{ $route.meta.title }}
        </div>
        <p>
          {{
            "Lag et nytt passord i feltet nedenfor. Etter det kan du logge deg inn."
          }}
        </p>

        <u-reset-password
          class="md:w-100 my-5"
          @submit="resetPassword"
        />
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
name: reset-password
meta:
  title: Tilbakestill passord
  layout: public
</route>

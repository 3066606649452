<script setup lang="ts">
import type { PropType } from "vue"
import { vOnClickOutside } from "@vueuse/components"

const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  alignY: {
    type: String as PropType<string>,
    default: "center",
  },
  alignX: {
    type: String as PropType<string>,
    default: "center",
  },
  customClass: {
    type: String as PropType<string>,
    default: "center",
  },
  customInnerClass: {
    type: String as PropType<string>,
    default: "",
  },
  customTopClass: {
    type: String as PropType<string>,
    default: "",
  },
  title: {
    type: String as PropType<string>,
    default: "",
  },
  description: {
    type: String as PropType<string>,
    default: "",
  },
  body: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  headerClass: {
    type: String as PropType<string>,
    default: "",
  },
})

const emit = defineEmits(["update:modelValue", "close"])

const show = computed({
  get: () => props.modelValue,
  set: val => emit("update:modelValue", val),
})
</script>

<template>
  <teleport to="body">
    <transition name="fade" mode="out-in">
      <div
        v-if="show"
        id="defaultModal"
        tabindex="-1"
        :class="
          customTopClass || {
            'justify-start': alignX === 'start',
            'justify-end': alignX === 'end',
            'justify-center': alignX === 'center',
            'items-start': alignY === 'start',
            'items-bottom': alignY === 'bottom',
            'items-center': alignY === 'center',
          }
        "
        class="fixed left-0 right-0 top-0 z-30 m-auto mx-auto flex h-dvh overflow-y-auto overflow-x-hidden bg-portal-indigo-900 bg-opacity-90 backdrop-blur backdrop-saturate-100 md:inset-0"
        aria-modal="true"
        role="dialog"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="body"
            class="max-h-lg relative w-full max-w-lg sm:p-4"
            :class="customClass"
          >
            <div
              v-on-click-outside="() => (show = false)"
              class="relative h-dvh overflow-hidden rounded-sm bg-white drop-shadow-xl sm:h-auto"
              :class="customInnerClass"
            >
              <div
                class="flex justify-between p-5"
                :class="headerClass"
              >
                <div>
                  <slot name="header" />
                  <h1 v-if="title" class="font-semibol text-2xl">
                    {{ title }}
                  </h1>
                  <div v-if="description" class="text-gray-600">
                    {{ description }}
                  </div>
                </div>

                <u-icon
                  name="close"
                  class="fixed-width-icon scale-75 transform pl-4 hover:text-red-500"
                  @click="
                    () => {
                      show = false
                      $emit('update:modelValue')
                      $emit('close')
                    }
                  "
                />
              </div>
              <div class="p-5">
                <slot />
              </div>
            </div>
          </div>
          <div v-else v-on-click-outside="() => (show = false)">
            <slot />
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

import type { Ref } from "vue"
import { acceptHMRUpdate, defineStore } from "pinia"

interface Toast {
  id: string
  message: string
}

export const useToastStore = defineStore("toast", () => {
  /** State */
  const toasts: Ref<Toast[]> = ref([])

  /** Actions */
  const addToast = (toast: Toast) => {
    if (toasts.value.find(t => t.id === toast.id)) return
    toasts.value.push(toast)
  }

  const removeToast = (id: string) => {
    toasts.value = toasts.value.filter(toast => toast.id !== id)
  }

  return {
    /** State */
    toasts,

    /** Actions */
    addToast,
    removeToast,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useToastStore, import.meta.hot),
  )

<script setup>
const personalization = usePersonalizationStore()
const data = useDataStore()
const route = useRoute("article")
</script>

<template>
  <div
    v-if="personalization.lastReadArticle"
    class="pt-[40px] lg:pt-[65px]"
  >
    <router-link
      v-if="personalization.lastReadArticle"
      :to="`/produkter/${route.params.product}/${
        route.params.variant
      }${personalization.lastReadArticle.href.replace(
        /^\/[^\/]+\//,
        '/',
      )}`"
    >
      <div
        class="mt-[-.4em] w-full rounded-xl bg-[--u-color-light] p-6 text-white hover:brightness-95"
      >
        <div class="flex items-center justify-start">
          <p
            class="cfont-numeraWebRegular text-l-1-numera text-black"
          >
            Fortsett der du slapp:
          </p>
        </div>
        <p class="text-h-2-national-2-c text-black <lg:text-lg">
          {{ personalization.lastReadArticle.priority }}.
          {{ personalization.lastReadArticle.subjectTitle }}
        </p>
        <p
          class="font-national2compressed text-h-1-national-2-c text-[--u-color-dark] <lg:text-3xl"
        >
          {{ personalization.lastReadArticle.articleTitle }}
        </p>
      </div>
    </router-link>
  </div>
</template>

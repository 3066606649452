<script setup lang="ts">
import type { PropType } from "vue"

defineProps({
  navState: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

defineEmits(["update:navState"])

const route = useRoute<"product">()
const { user, login, logout } = useAuth()
</script>

<template>
  <u-header
    :scroll-of-mobile="true"
    class-on-scroll="bg-portal-indigo-900"
    class="h-5-rem container bottom-0 flex w-full items-center bg-portal-indigo-900 px-3 py-3 md:px-5"
  >
    <u-button
      :wave="true"
      shape="rounded"
      class="ml-0 flex stroke-white pl-0 hover:stroke-u-contrast"
      @click="user.uid ? logout(route.params.product) : login()"
    >
      <u-product-title />
    </u-button>
  </u-header>
</template>

import type { Ref } from "vue"
import type { Article } from "~/types/article"
import type { Subject } from "~/types/subject"
import type { PageData, PageUrlAlias } from "~/types/pages"
import type { Folder } from "~/types/folder"
import type { Product } from "~/types/product"

import { acceptHMRUpdate, defineStore } from "pinia"

import { getMediaCount } from "~/hooks/media-count"
import getPageDataFormat from "~/hooks/page"
import getPageNavDataFormat from "~/hooks/page/pages"
import getDataFormat from "~/hooks/subject"
import getArticlesDataFormat from "~/hooks/article"
import { getRestrictedArticles } from "~/hooks/restricted-article"
import { FOLDER_REGEX } from "~/utilities/constants"
import { getLocationId } from "~/hooks/utils"
import getProduct from "~/hooks/product"
import getProductPageDataFormat from "~/hooks/productPage"
import getProductPageBlocksDataFormat from "~/hooks/productPageBlocks"

export const useDataStore = defineStore("data", () => {
  /** State */

  const product: Ref<Product> = ref({
    id: 0,
    pathString: "",
    title: "",
    shortTitle: "",
    ean: "0",
    lookupName: "",
  })
  const pages: Ref<PageData[]> = ref([]) // Todo -> Add types for pages
  const pageNav: Ref<PageUrlAlias[]> = ref([])
  const subjects: Ref<Subject[]> = ref([])
  const articles: Ref<Article[]> = ref([])
  const folders: Ref<Folder[]> = ref([])
  const productPageData = ref()

  /** Getters */
  const subjectNav = computed(() =>
    subjects.value
      .map(i => {
        return {
          id: i.id,
          parentLocationId: i.parentLocationId,
          text: i.title,
          hrefRaw: i.href,
          href: i.href.replace(FOLDER_REGEX, ""),
          priority: i.priority,
          hidden: i.hidden,
          totalChildren: i.totalChildren,
          type: i.type,
        }
      })
      .sort((a, b) => a.priority - b.priority),
  )

  const articleNav = computed(() =>
    articles.value.map(i => {
      return {
        id: i.id,
        parentLocationId: i.parentLocationId,
        text: i.title,
        hrefRaw: i.href,
        href: i.href.replace(FOLDER_REGEX, ""),
        priority: i.priority,
        hidden: i.hidden,
        restricted: i.restricted || false,
      }
    }),
  )

  /** Actions */

  async function fetchMediaCountSubjects(id: number) {
    await Promise.all(
      subjects.value.map(async (s: Subject) => {
        if (
          s.parentLocationId === id &&
          s.mediatypes.articles.count === 0
        ) {
          const mediaCount = await getMediaCount(s.id as number)
          s.mediatypes.articles.count = mediaCount.articles
          s.mediatypes.videos.count = mediaCount.videos
          s.mediatypes.podcasts.count = mediaCount.podcasts
          s.mediatypes.quizes.count = mediaCount.quizes
        }
      }),
    )
  }

  async function fetchMediaCountFolders(id: number) {
    await Promise.all(
      folders.value.map(async (f: Folder) => {
        if (
          f.parentLocationId === id &&
          f.mediatypes.articles.count === 0
        ) {
          const mediaCount = await getMediaCount(f.id as number)
          f.mediatypes.articles.count = mediaCount.articles
          f.mediatypes.videos.count = mediaCount.videos
          f.mediatypes.podcasts.count = mediaCount.podcasts
          f.mediatypes.quizes.count = mediaCount.quizes
        }
      }),
    )
  }

  /**
   * Get data for the landing page
   * @param urlAlias
   */
  async function getPage(urlAlias: string) {
    const page = await getPageDataFormat(urlAlias)

    if (!page) return

    const index = pages.value.findIndex(item => item.id === page.id)
    if (index !== -1) pages.value.splice(index, 1, page)
    else pages.value.push(page)

    return page
  }

  async function setProductPage(urlAlias: string) {
    if (!productPageData.value) {
      const router = useRouter()
      const productPage = await getProductPageDataFormat(urlAlias)

      if (!productPage) {
        console.error("Product page was not found")
        router.replace("/404")
        return
      }

      productPageData.value = {
        title: productPage.title,
        header: productPage.header.html5,
        theme: productPage.theme,
      }
    }
  }

  const getProductPage = () => {
    return productPageData
  }

  async function getProductBlocks(urlAlias: string) {
    const productPageBlocks =
      await getProductPageBlocksDataFormat(urlAlias)

    if (!productPageBlocks) return

    return productPageBlocks
  }

  async function getPageNav() {
    const pageNavs = await getPageNavDataFormat()

    pageNav.value = pageNavs

    return pageNavs
  }

  /**
   * Get product, subjects and folders by product name
   * @param name
   */
  async function getSubjects(name: string) {
    if (subjects.value.length && product.value.lookupName === name)
      return
    NProgress.start()
    try {
      const locationId = await getLocationId(name)
      const productFromIbexa = await getProduct(locationId)

      // Set product in data store
      Object.assign(product.value, productFromIbexa.product)
      product.value.lookupName = name
      const data = await getDataFormat(locationId)

      folders.value = data.folders
      await fetchMediaCountFolders(product.value.id)

      subjects.value = data.subjects
      await fetchMediaCountSubjects(product.value.id)

      NProgress.done()
    } catch (error) {
      NProgress.done()
      throw error
    }
  }

  /**
   * Get all articles by location id.
   * Used for restricted articles.
   * @param locationId
   */

  async function getArticlesByLocationId(locationId: number) {
    NProgress.start()

    try {
      const restrictedArticlesRaw =
        await getRestrictedArticles(locationId)
      const restrictedArticles = restrictedArticlesRaw.map(
        (e: any) => ({
          audio: undefined,
          bg: "paper",
          body: {
            html: `<h1>${e.name}</h1><div class="article-text-placeholder"></div>`,
          },
          color: "black",
          href: e.url,
          id: e.locationId,
          imageCaption: { html: "" },
          img: e.image,
          intro: { html: "\n" },
          parentLocationId: locationId,
          priority: e.priority,
          shortTitle: "",
          template: "UImageWithTextArticle",
          title: e.name,
          type: "article",
          restricted: true,
        }),
      )

      articles.value = restrictedArticles
      NProgress.done()
    } catch (error) {
      NProgress.done()
      throw error
    }
  }

  const formatSubjectPage = (
    parentId: string | number | undefined,
  ): Article | undefined => {
    const subject = subjects.value.find(s => s.id === parentId)

    if (!subject) return
    const {
      img,
      id,
      parentLocationId,
      title,
      shortTitle,
      subjectPageTitlePosition,
      colorTag,
      href,
      priority,
      // introPages
    } = subject
    if (subject.introPages && subject.introPages.length > 0) {
      return {
        id: (id as number) + parentLocationId,
        parentLocationId: id as number,
        template: "USlidesArticle",
        bg: "paper",
        img,
        title,
        shortTitle,
        priority,
        subjectPageTitlePosition,
        // introPages,
        colorTag,
        href: `${href}/forside`,
        isDark: false,
      }
    } else return
  }

  /**
   * Get all articles by product name + subject name ("path/to/subject")
   * @param path
   */
  async function getArticles(args: {
    product: string
    variant: string
    subject: string
    id?: number
  }) {
    let currentSubject = subjects.value.find(
      i =>
        i.href === `/${args.product}-${args.variant}/${args.subject}`,
    )

    let currentLocationId = currentSubject?.id

    if (!currentSubject && args.id) {
      currentSubject = subjects.value.find(i => i.id === args.id)
      currentLocationId = args.id
    }

    if (!currentSubject) return

    NProgress.start()

    const inStore = articles.value.find(
      a => a.parentLocationId === currentSubject?.id,
    )

    if (inStore) {
      NProgress.done()
      return
    }

    if (currentSubject?.totalChildren === 0) {
      await getArticlesByLocationId(currentLocationId as number)
      return
    }

    try {
      const items = await getArticlesDataFormat(
        currentLocationId as number,
      )
      const subjectPage = formatSubjectPage(currentLocationId)

      if (subjectPage) items.unshift(subjectPage)

      items?.map((i, key, arr) => {
        const index = articles.value.findIndex(
          item => item.id === i.id,
        )
        if (index !== -1) articles.value.splice(index, 1, i)
        else articles.value.push(i)

        if (key === arr.length - 1) {
          NProgress.done()
        }
      })
    } catch (error) {
      NProgress.done()
      throw error
    }
  }

  return {
    /** State */
    product,
    pages,
    pageNav,
    subjects,
    articles,
    folders,
    /** Getters */
    subjectNav,
    articleNav,
    /** Actions */
    getPage,
    setProductPage,
    getProductPage,
    getProductBlocks,
    getPageNav,
    getSubjects,
    getArticles,
    getArticlesByLocationId,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useDataStore, import.meta.hot),
  )

<script setup lang="ts">
import type { PropType, Ref } from "vue"
import { getCurrentInstance } from "vue"

const props = defineProps({
  customId: {
    type: String as PropType<string>,
  },
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
  placeholder: {
    type: String as PropType<string>,
    default: "",
  },
  type: {
    type: String as PropType<string>,
    default: "",
  },
  autocomplete: {
    type: String as PropType<string>,
    default: "off",
  },
  name: {
    type: String as PropType<string>,
    default: "",
  },
  ring: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  pattern: {
    type: String as PropType<string>,
    default: "",
  },
  validation: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const emit = defineEmits(["update:modelValue"])

onMounted(() => {
  camalize("helloYou")
})

const focus = ref(false) as Ref<boolean>

const model = computed({
  get: () => props.modelValue,
  set: value => {
    emit("update:modelValue", value)
  },
})

const instance = getCurrentInstance()

function inputFocus() {
  document.getElementById(`input-${instance?.uid}`)?.focus()
}
</script>

<template>
  <div
    :id="customId || `input-group-${instance?.uid}`"
    class="input-wrapper"
    :class="[{ focus }, { '!border-0 !bg-red-40': validation }]"
    @click="inputFocus"
  >
    <input
      :id="customId || `input-${instance?.uid}`"
      v-model="model"
      :placeholder="placeholder"
      :name="name"
      :type="type"
      :autocomplete="autocomplete"
      :pattern="pattern"
      @focus="focus = true"
      @blur="focus = false"
    />
  </div>
</template>

<style scoped lang="postcss">
.input-wrapper {
  @apply flex
  h-12
  items-center
  justify-between
  overflow-hidden
  rounded-[5px]
  border-2
  bg-white
  stroke-white
  transition
  duration-300
  ease-in-out
  focus:outline-none;

  &.focus,
  &:hover {
    @apply !border-u-contrast !stroke-red-60;
  }

  &.focus {
    @apply !border-2;
  }

  &:hover {
    @apply border-2;
  }

  & > input {
    @apply w-full
    px-5
    py-2
    font-numeraWebRegular
    text-[18px]
    placeholder-black
    placeholder-opacity-60
    outline-none
    md:text-[20px]
    xl:text-[25px];
  }

  & > i {
    @apply mr-4;
  }
}
</style>

<script setup lang="ts">
import type { PropType, Ref } from "vue"
import type { SignupForm } from "~/types/auth"

const props = defineProps({
  signupError: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

/**
 *
 * @see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
 */
const emit = defineEmits(["submit"])
const registedEmailError = `
  Denne e-posten er allerede i bruk hos Kunne eller et annet av Aschehougs digitale produkter. 
  Du kan forsøke å 
  <a class="font-national2 text-[18px] !leading-normal md:text-[20px] md:!leading-normal xl:text-[25px]  xl:!leading-normal underline" href="https://id.aschehoug.cloud/?goto=https%3A%2F%2Fam.core.prod.aschehoug.cloud%2Fam%2Foauth2%2Fauthorize%3Fcode_challenge%3DeM3Hr1bRq3zBS23_gdjVYwtRyPigVVphmfoE_2Mco_I%26code_challenge_method%3DS256%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520email%26client_id%3Dkunne_client%26redirect_uri%3Dhttps%3A%2F%2Fkunne.no">logge inn</a> 
  i stedet, eller <a class="font-national2 text-[18px] !leading-normal md:text-[20px] md:!leading-normal xl:text-[25px] xl:!leading-normal underline" href="https://om.kunne.no/hjelpesider/kb-tickets/new">kontakte Kundeservice</a> for å få hjelp.
`

const form = ref({
  firstName: "",
  lastName: "",
  email: "",
}) as Ref<SignupForm>

const error = ref({
  firstName: "",
  lastName: "",
  email: "",
  terms: "",
}) as Ref<{ [key: string]: string }>

const acceptTerms = ref(false)
const showAcceptTermsError = ref(false)

function submit() {
  if (!acceptTerms.value) {
    showAcceptTermsError.value = true
    return
  }
  const { firstName, lastName, email } = form.value

  if (firstName.length < 2) {
    error.value.firstName = "Fornavn må være minst 2 tegn"
  }

  if (lastName.length < 2) {
    error.value.lastName = "Etternavn må være minst 2 tegn"
  }

  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    error.value.email = "Ikke en gyldig e-postadresse"
  }

  if (Object.values(error.value).some(e => e)) return

  emit("submit", form.value)
}

const generateEmailMsg = () => {
  if (props.signupError && error.value.email.length) {
    return error.value.email
  } else if (props.signupError && !error.value.email.length) {
    return registedEmailError
  } else {
    return error.value.email
  }
}

watch(
  form,
  () => {
    Object.keys(error.value).forEach(key => {
      error.value[key] = ""
    })
  },
  { deep: true },
)
</script>

<template>
  <u-form>
    <u-input-group
      v-model="form.firstName"
      label-for="fname"
      name="fname"
      label="Fornavn:"
      placeholder="Fornavn"
      type="text"
      pattern="[a-zA-Z]+"
      :validation="error.firstName.length > 0"
    />

    <u-input-validation-field
      :show="error.firstName.length > 0"
      :message="error.firstName"
    />

    <u-input-group
      v-model="form.lastName"
      label-for="lname"
      name="lname"
      label="Etternavn:"
      placeholder="Etternavn"
      type="text"
      :validation="error.lastName.length > 0"
    />

    <u-input-validation-field
      :show="error.lastName.length > 0"
      :message="error.lastName"
    />

    <u-input-group
      v-model="form.email"
      label-for="email"
      name="email"
      label="E-postadresse:"
      placeholder="din@epost.no"
      type="email"
      pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
      :validation="error.email.length > 0 || signupError"
    />

    <u-input-validation-field
      :show="error.email.length > 0 || signupError"
      :message="generateEmailMsg()"
    />

    <div class="mt-3 flex items-center gap-x-5">
      <input
        id="checkbox_id"
        v-model="acceptTerms"
        type="checkbox"
        name="checkbox"
        class="terms-checkbox"
      />
      <label for="checkbox_id">
        <span class="label-txt">{{
          "Jeg har lest og samtykker til "
        }}</span>
        <a
          href="https://kunne.universitetsforlaget.no/personvern"
          target="_blank"
          class="label-txt underline"
          >{{ "personvernerklæringen" }}</a
        >
        <span class="label-txt">{{ " for Kunne." }}</span></label
      >
    </div>

    <div class="">
      <p
        v-if="showAcceptTermsError && !acceptTerms"
        class="label-txt ml-9 mt-5 text-red-500"
      >
        {{
          "Du må godkjenne vilkårene for Kunne før du kan registrere en bruker."
        }}
      </p>
      <u-button
        :class="!acceptTerms ? '!bg-grey-60' : ''"
        shape="normal"
        class="submit-btn rounded-lg px-2 py-1"
        :wave="true"
        @click="submit"
      >
        {{ "Registrer deg" }}
      </u-button>
    </div>
  </u-form>
</template>

<style scoped lang="postcss">
.terms-checkbox {
  transform: scale(1.6, 1.6);
  @apply cursor-pointer accent-red-60;
}
.input-group {
  @apply mb-3;
}

.label-txt {
  @apply font-national2 text-[18px] !leading-normal
  xl:text-[22px] xl:!leading-normal;
}

.submit-btn {
  @apply mb-5 mt-10 max-w-[219px] bg-red-60 font-numeraWebRegular text-lg
  leading-normal hover:bg-red-80 md:text-xl md:leading-normal xl:text-2xl xl:leading-normal;
}
</style>

import { Article } from "~/types/article"
import { Subject } from "~/types/subject"

export const articleTemplates = new Map([
  ["text", { ibexaId: 0, name: "Tekst" }],
  ["quiz", { ibexaId: 3, name: "Oppgaver" }],
  ["video", { ibexaId: 1, name: "Video" }],
])

/**
 * Format articles from raw data (cms).
 * Used for restricted articles.
 * @param restrictedArticlesRaw
 * @param subjectLocationId
 */

export function formatRestrictedArticles(
  restrictedArticlesRaw: any,
  subjectLocationId: number,
): any {
  const restrictedArticles = restrictedArticlesRaw.map((e: any) => ({
    audio: undefined,
    bg: "paper",
    body: {
      html: `<h1>${e.name}</h1><div class="article-text-placeholder"></div>`,
    },
    color: "black",
    href: e.url,
    id: e.locationId,
    imageCaption: { html: "" },
    img: e.image,
    intro: { html: "\n" },
    parentLocationId: subjectLocationId,
    priority: e.priority,
    shortTitle: "",
    template: "UImageWithTextArticle",
    title: e.name,
    type: "article",
    restricted: true,
  }))

  return restrictedArticles
}

export const formatSubjectPage = (
  subject: Subject,
): Article | undefined => {
  if (!subject) return
  const {
    img,
    id,
    parentLocationId,
    title,
    shortTitle,
    subjectPageTitlePosition,
    colorTag,
    href,
    priority,
    // introPages
  } = subject
  if (subject.introPages && subject.introPages.length > 0) {
    return {
      id: (id as number) + parentLocationId,
      parentLocationId: id as number,
      template: "USlidesArticle",
      bg: "paper",
      img,
      title,
      shortTitle,
      priority,
      subjectPageTitlePosition,
      // introPages,
      colorTag,
      href: `${href}/forside`,
      isDark: false,
    }
  } else return
}

<script setup>
const route = useRoute()
const router = useRouter()
const searchQuery = ref(route.query.q ?? "")

function submitSearch() {
  const q = searchQuery.value
  const from = route.name === "search" ? undefined : route.fullPath

  // if (q.length < 1) return
  if (isOnline.value) {
    router.push({ name: "search", query: { q, from } })
    return
  } else {
    router.push({ name: "search-offline", query: { q, from } })
    return
  }
}

watch(
  () => route.query.q,
  q => {
    searchQuery.value = q
  },
)
</script>

<template>
  <form
    class="flex rounded-md border-2 border-white bg-transparent text-white focus-within:border-u-contrast"
    :class="{ '!bg-white !text-black': route.query.q }"
    @submit.prevent="submitSearch()"
  >
    <input
      id="searchbar"
      v-model="searchQuery"
      style="color: inherit"
      class="w-full flex-1 px-4 py-2 font-national2 focus:outline-none"
      placeholder="Søk i alt innhold"
      type="text"
      data-pendo="USearchBar-input"
    />
    <button class="flex-none p-2" data-pendo="USearchBar-submit">
      <u-icon style="stroke: currentColor" name="search" size="sm" />
    </button>
  </form>
</template>

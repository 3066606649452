import type { Subject } from "./../types/subject.d"
import { ref, watch } from "vue"
import { acceptHMRUpdate, defineStore } from "pinia"
import { set, get } from "~/hooks/external/user-key-val"
import type { Article } from "~/types/article"

interface ReadArticle {
  id: number
  productId: number
  subjectId: number
  priority: number
  articleTitle: string
  subjectTitle: string
  href: string
  date?: string
  done: boolean
}

interface Settings {
  autoShowProgress: boolean
  featureFlagAccess: boolean
}

export const usePersonalizationStore = defineStore(
  "personalization",
  () => {
    // State
    const data = useDataStore()
    const licence = useLicenceStore()

    const readHistory: Ref<ReadArticle[]> = ref([])
    const lastReadArticle = computed(() => {
      const doneArticles = readHistory.value.filter(
        ({ productId }) => data.product.id === productId,
      )
      if (!doneArticles.length) return undefined
      return doneArticles[0]
    })
    const activeArticle: Ref<Article | undefined> = ref(undefined)
    const lastActiveArticle: Ref<Article | undefined> = ref(undefined)
    const settings: Ref<Settings> = ref({
      autoShowProgress: true,
      featureFlagAccess: false,
    })
    const elapsedSeconds = ref(0)

    // Local State
    const { user } = useAuth()
    let cacheKeyReadHistory = "anonymous-read-history"
    let cacheKeySettings = "anonymous-settings"
    const hydrateReadHistoryInProgress = ref(false)
    const hydrateSettingsInProgress = ref(false)

    const timerRef: Ref<NodeJS.Timeout | null> = ref(null)

    // Actions
    function clearReadHistory() {
      readHistory.value = []
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }

    function addArticleToReadHistory(
      articleId: number,
      done?: boolean,
    ) {
      const article = data.articles.find(
        (article: Article) => article.id === articleId,
      )
      const subject = data.subjects.find(
        (subject: Subject) =>
          subject.id === article?.parentLocationId,
      )

      const articleInReadHistoryIndex = readHistory.value.findIndex(
        article => {
          return article.id === articleId
        },
      )
      if (articleInReadHistoryIndex !== -1) {
        const [articleInReadHistory] = readHistory.value.splice(
          articleInReadHistoryIndex,
          1,
        )
        articleInReadHistory.done =
          done !== undefined ? done : articleInReadHistory.done
        readHistory.value.unshift(articleInReadHistory)
      } else if (
        article &&
        subject &&
        articleInReadHistoryIndex === -1
      ) {
        const articleToUpdate: ReadArticle = {
          id: article.id,
          productId: data.product.id,
          subjectId: subject.id,
          priority: subject.priority,
          articleTitle: article.title,
          subjectTitle: subject.title,
          href: article.href,
          done: done !== undefined ? done : false,
        }
        readHistory.value.unshift(articleToUpdate)
      } else {
        console.warn(
          "addArticleToReadHistory: article or subject not found, this is a bug.",
        )
      }
    }

    watch(
      user,
      async (newUser, oldUser) => {
        if (!oldUser?.uid && newUser?.uid) {
          cacheKeyReadHistory = `${newUser.uid}-read-history-v2`
          cacheKeySettings = `${newUser.uid}-settings`
          const value = (await get(
            cacheKeyReadHistory,
          )) as ReadArticle[]
          const settingsValue = (await get(
            cacheKeySettings,
          )) as Settings
          if (value) {
            hydrateReadHistoryInProgress.value = true
            readHistory.value = value
          }
          if (settingsValue) {
            hydrateSettingsInProgress.value = true
            settings.value = settingsValue
          }
        }
      },
      { immediate: true },
    )

    watch(
      () => readHistory.value,
      () => {
        if (hydrateReadHistoryInProgress.value) {
          hydrateReadHistoryInProgress.value = false
          return
        }
        set(cacheKeyReadHistory, readHistory.value, user.value?.uid)
        monitorHistoryArraySize(readHistory.value)
      },
      { deep: true },
    )

    watch(
      () => settings,
      newValue => {
        if (hydrateSettingsInProgress.value) {
          hydrateSettingsInProgress.value = false
          return
        }
        set(cacheKeySettings, newValue.value, user.value?.uid)
      },
      { deep: true },
    )

    watch(
      activeArticle,
      (newArticle, oldArticle) => {
        if (newArticle) {
          lastActiveArticle.value = newArticle
        }

        if (!licence.hasValidLicence) return
        if (timerRef.value) clearTimeout(timerRef.value)

        if (newArticle && newArticle?.id !== oldArticle?.id) {
          addArticleToReadHistory(newArticle.id)
          if (!settings.value?.autoShowProgress) return

          elapsedSeconds.value = 0
          timerRef.value = setInterval(() => {
            elapsedSeconds.value++
            if (elapsedSeconds.value >= 8) {
              if (timerRef.value) clearTimeout(timerRef.value)
              elapsedSeconds.value = 0
              addArticleToReadHistory(newArticle.id, true)
            }
          }, 1000)
        }
      },
      { immediate: true },
    )

    return {
      readHistory,
      settings,
      activeArticle,
      lastActiveArticle,
      elapsedSeconds,
      lastReadArticle,
      clearReadHistory,
      addArticleToReadHistory,
    }
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(usePersonalizationStore, import.meta.hot),
  )

<script setup lang="ts">
import type { PropType } from "vue"
import { formatCurrency } from "~/utilities/format"
import { checkoutPageUrl } from "~/utilities/urls"

const props = defineProps({
  shortText: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const audioStore = useAudioStore()

if (props.shortText) {
  audioStore.activePlayer = false
}

const route = useRoute<"product">()
const productParam = computed(() => route.params.product)
const variantParam = computed(() => route.params.variant)
const products = useProductVariantsStore()
const productPrice = useProductPrice()
const price = computed(() =>
  productPrice.value ? formatCurrency(productPrice.value) : undefined,
)

const opened = ref(false)
const windowWidth = ref(window.innerWidth)
const showModal = ref(false)
const checkoutError = ref(false)
const showShortText = ref(props.shortText)

onMounted(() => {
  window.onresize = () => {
    windowWidth.value = window.innerWidth
  }
})

const curriculumText = computed(() => {
  const prefixText = "Dekker pensum for"

  if (products.product === "exphil") {
    if (products.variant === "uio") {
      return `${prefixText} UiO og UiA`
    }
    if (products.variant === "ntnu") {
      return `${prefixText} etikk og politisk filosofi for ${products.activeVariant?.shortVariantLabel}`
    }
  }

  return `${prefixText} ${products.activeVariant?.shortVariantLabel}`
})

const examGuarantee = computed(
  () => products.product === "exphil" && products.variant === "uio",
)
</script>

<template>
  <u-modal
    v-if="checkoutError"
    v-model="showModal"
    close-icon="close-circle"
    close-icon-size="30"
  >
    <section class="body">
      <h1>Opps, noe gikk galt...</h1>
      <p>
        Du har ikke blitt belastet. Prøv igjen litt senere eller ta
        kontakt med
        <a
          href="https://om.kunne.universitetsforlaget.no/hjelpesider/"
          rel="noreferrer"
          target="_blank"
          >Kunne kundeservice</a
        >.
      </p>
    </section>
  </u-modal>

  <u-modal
    v-if="!checkoutError"
    v-model="showModal"
    close-icon="close-circle"
    close-icon-size="30"
  >
    <section
      v-if="!checkoutError"
      id="vipps-checkout-frame-container"
    />
  </u-modal>
  <u-footer
    v-if="price"
    id="footer-pay-banner"
    class="pay-banner-footer cursor-pointer text-black"
    :class="{ '!bottom-0': audioStore.activePlayer }"
    @click="opened = !opened"
  >
    <div class="banner-container">
      <div class="payment-short">
        <u-button pill>
          <template #prepend>
            <u-icon :name="opened ? 'minus' : 'plus'" size="sm" />
          </template>
        </u-button>
        <p class="md-screen whitespace-nowrap text-xl">
          <b>Forstå faget og bestå eksamen</b> med Kunne
          <b>{{ price }}</b> for hele semesteret
        </p>
        <p v-show="!opened" class="sm-screen ml-3">
          <b>Forstå faget og bestå eksamen</b> med Kunne
          <b>{{ price }}</b> for hele semesteret
        </p>
        <p v-show="opened" class="sm-screen ml-2">Lukk</p>
        <u-button
          v-show="!opened"
          pill
          variant="filled"
          theme="skin"
          cta
          size="lg"
          style="--color: white"
        >
          <div class="font-bold italic <2xl:hidden">Kjøp tilgang</div>
          <div class="font-bold italic 2xl:hidden">Kjøp</div>
        </u-button>
      </div>
      <div v-if="showShortText && !opened" class="short-text-wrapper">
        <div class="w-[36px] md:w-[52px]" />
        <div class="short-text">
          <u-button
            class="absolute right-2 top-2 self-end !text-[--u-color-light]"
            pill
            @click="showShortText = false"
          >
            <template #prepend>
              <u-icon name="close" size="sm" class="m-[3px]" />
            </template>
          </u-button>
          <p class="text-[--u-color-light]">
            For å lese dette kapittelet må du kjøpe tilgang til
            produktet. Du betaler kun en engangssum for alt innholdet
            for hele semesteret, med korte tekster, videoer og
            interaktive oppgaver. Hvis du vil vite mer kan du lese alt
            stoffet som er markert som prøvekapitler gratis.
          </p>
        </div>
      </div>
      <div v-show="opened" class="payment-detail">
        <div class="item ml-[1.2rem] mt-[2.5rem] md:ml-0 md:mt-0">
          <p class="description">
            Kunne gjør pensum forståelig og relevant.
          </p>
          <ul>
            <li>
              Du betaler bare én gang, og vil ikke bli belastet på
              nytt
            </li>
            <li v-if="examGuarantee">
              Vi gir eksamensgaranti: 99% prosent av våre brukere står
              på eksamen
            </li>
            <li>Korte tekster og relevante eksempler</li>
            <li>Interaktive oppgaver</li>
            <li>
              {{ curriculumText }}
            </li>
          </ul>
        </div>
        <div class="item md:mt-[-2rem]">
          <div class="price-wrapper">
            <div
              class="mb-4 flex flex-col items-center justify-center"
            >
              <p class="price">
                {{ price }}
              </p>
              <p class="explainer">For hele semesteret</p>
            </div>
            <u-button
              pill
              variant="filled"
              cta
              size="lg"
              style="--color: white"
              :to="checkoutPageUrl(productParam)"
            >
              <div class="font-bold italic">Kjøp tilgang</div>
            </u-button>
          </div>
          <div class="logo-wrapper">
            <div class="cards" />
          </div>
        </div>
      </div>
    </div>
  </u-footer>
</template>

<style scoped lang="postcss">
.pay-banner-footer {
  @apply fixed
  bottom-0 z-50 max-h-[90vh] min-h-[4rem]
  w-full overflow-y-scroll bg-[--u-color-light]
  px-3 md:overflow-auto lg:min-h-[4rem]
  lg:px-[74px] xl:min-h-[4rem] 2xl:px-0;
  .banner-container {
    @apply container flex-col items-start md:flex;
  }
  .accordion-btn {
    @apply flex self-center;
  }
  .pay-btn {
    @apply bg-blue-60
    font-national2
    text-[18px]
    font-bold
    italic
    text-white md:px-[42px] md:pb-[8px]
    md:pt-[7px] lg:text-[21px] xl:text-[28px];
  }
  .payment-short {
    grid-template-columns: auto 1fr max-content;
    @apply grid
    w-full
    items-center
    py-[0.7rem] md:flex md:flex-wrap md:justify-start md:gap-4 xl:gap-6;
    p,
    span {
      @apply font-national2 text-[20px]
      text-portal-indigo-900
      md:text-[24px]
      lg:text-[22px]
      xl:text-[28px];
    }

    .sm-screen {
      @apply block self-center xl:hidden;
    }
    .md-screen {
      @apply hidden self-center xl:block;
    }
    .pay-btn {
      @apply ml-0
      rounded-[20px]
      px-[20px] py-0
      md:ml-6 md:rounded-[45px] md:px-[42px] md:pb-[8px] md:pt-[7px];
      > .md-screen {
        @apply hidden md:block;
      }
      > .sm-screen {
        @apply block md:hidden;
      }
    }
  }
  .short-text-wrapper {
    @apply my-8
    w-full
    gap-3 md:my-16
    md:flex
    md:max-w-[42rem]
    md:justify-start
    md:gap-12
    lg:max-w-[50rem]
    xl:max-w-[62rem]
    xl:gap-14;
    > .short-text {
      @apply relative
      flex
      flex-col
      rounded-[5px]
      bg-[--u-color-medium]
      px-6
      py-9;
      > p {
        @apply pr-0 md:pr-40;
      }
      > p,
      a {
        @apply text-[18px] leading-normal
        md:text-[20px] md:leading-normal
        xl:text-[25px] xl:leading-normal;
      }
      > p > a {
        @apply underline;
      }
    }
  }
  .payment-detail {
    @apply grid w-full grid-cols-1 md:grid-cols-[2fr,1fr] md:gap-20 md:py-16 md:pl-16;
    .description {
      @apply font-national2 text-[20px]
      font-bold leading-normal
      lg:text-[21px] lg:leading-normal
      xl:text-[28px] xl:leading-normal;
    }
    ul {
      @apply mt-2 list-disc;
      > li {
        @apply text-[18px] leading-normal
        xl:text-[25px] xl:leading-normal;
      }
    }
    .disclaimer,
    .disclaimer > a {
      @apply mt-12 font-national2
      !text-[16px] !leading-normal
      xl:text-[22px] xl:leading-normal;
    }
    .disclaimer > a {
      @apply underline;
    }
    .md-screen {
      @apply hidden md:block;
    }
    .sm-screen {
      @apply mb-6 mt-0 block md:hidden;
    }
    .price {
      @apply mb-4
      flex
      w-full
      items-center
      justify-center
      font-national2condensed
      text-[70px]
      font-black
      leading-none
      text-[#120B2B]
      md:text-[90px] lg:text-[135px]
      xl:text-[180px];
    }
    .explainer {
      @apply mb-3
      ml-3
      flex w-full items-center
      justify-center
      self-end
      font-national2
      text-[18px] font-bold
      uppercase

      md:mb-0 md:ml-0 xl:text-[22px];
    }
    label {
      @apply mb-6
      flex;
      input {
        transform: scale(1.6, 1.6);
        @apply mr-4 self-center;
      }
      span,
      a {
        @apply font-national2 text-[18px]
        !leading-normal xl:text-[22px]
        xl:!leading-normal;
      }
      a {
        @apply underline;
      }
    }
    .frame {
      @apply my-5 md:my-12;
      &.frame-active {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        @apply my-5 ml-0
        rounded-[10px]
        bg-[#F7DCD7]
        p-5 md:my-10
        md:ml-[-1.5rem];
        > p {
          @apply pl-8
          font-national2 text-[18px]
          font-bold leading-normal
          xl:text-[22px]
          xl:leading-normal;
        }
      }
    }
    .pay-btn {
      @apply rounded-[45px]
      px-[20px] pb-[5px] pt-[4px]
      md:px-[33px] md:pb-[18px] md:pt-[17px]
      xl:px-[45px] xl:pb-[24px] xl:pt-[23px];
    }
    .pay-btn:disabled {
      @apply pointer-events-none opacity-70;
    }
    .price-wrapper {
      @apply my-5 flex flex-col items-center justify-center rounded-lg border-2 border-black px-6 py-10
      md:my-0 md:border-0 md:px-0 md:py-0;
    }
  }
  .logo-wrapper {
    @apply mb-6 mt-6 pl-[1.375rem]
    md:mb-0 md:mt-12 md:pl-0;
    .cards {
      background-image: url("~/assets/images/vipps-master-visa.svg");
      @apply h-[30px]
      bg-contain
      bg-no-repeat md:h-[40px];
    }
  }
}
.body {
  @apply flex
    flex-col
    items-center
    justify-center
    gap-y-10
    pb-28
    text-center
    text-[16px]
    md:text-[18px];

  h1 {
    @apply font-national2
      text-[24px]
      font-bold
      leading-10
      text-portal-indigo-900
      md:text-[40px];
  }

  p,
  span,
  a {
    @apply max-w-md
      font-national2
      text-[20px]
      leading-relaxed
      md:text-[32px] md:leading-relaxed;
  }
  a {
    @apply underline;
  }
}
</style>

<script setup lang="ts">
import type { Product } from "~/types/product"
import { computed, PropType, ref } from "vue"
import { KsSkeleton, KsSkeletonWrapper } from "@aschehoug/kloss"
import "@aschehoug/kloss/dist/index.css"
import { isAuthorized } from "~/utilities/auth"
import { variantFullNameMap } from "~/utilities/constants"
import { checkoutPageUrl } from "~/utilities/urls"

defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
})

const route = useRoute<"product">()
const productParam = computed(() => route.params.product)
const data = useDataStore()
const showVariantModal = ref(false)
const products = useProductVariantsStore()
const groupedProductVariants = products.product
  ? products.groupedProductVariants?.[products.product]
  : undefined

const hasValidLicence = computed(() => {
  if (products.activeVariant) {
    return products.activeVariant?.userHasLicence
  } else {
    return products.productVariants.some(p => p.userHasLicence)
  }
})

const productInformation = useProductInformationStore()
await productInformation.setProductData()
const productPageData = computed(() => data.getProductPage().value)

// Get product from ibexa
const ibexaProductEAN = computed(() => {
  const foundProduct = products?.productVariants?.find(product =>
    product.url.includes(route.params.product),
  )

  return foundProduct ? [foundProduct] : []
})

//Ibexa product filtered by EAN
const productData = computed(() => {
  const ibexaProduct = ibexaProductEAN.value?.[0]
  const ean = ibexaProduct?.ean
  return ean
    ? groupedProductVariants?.variants.find(v => v.ean?.includes(ean))
    : undefined
})

//Different product states
const productReleased = computed(() => {
  if (products.activeVariant) {
    return products.activeVariant.isReleased
  } else {
    return products.productVariants.some(p => p.isReleased)
  }
})

const productIsFree = computed(() => {
  if (products.activeVariant) {
    return (
      products.activeVariant.isReleased &&
      products.activeVariant.isFree
    )
  } else {
    return false
  }
})

const getProductImage = () => {
  const selectedProduct = productInformation.productData.find(p =>
    p.name
      .toLowerCase()
      .includes(productParam.value.replaceAll("-", " ")),
  )
  return selectedProduct?.imageUrl
}

const licenceImgDesc = computed(() => {
  const productVariant = productData.value
  return productVariant?.eCommerce?.imageDescription
})
const windowAvailWidth = ref(window.screen.availWidth)

const isSmall = computed(() => windowAvailWidth.value <= 744)

//Check if product data is loaded
const productDataLoaded = ref(false)

watch(productPageData, () => {
  productDataLoaded.value = !!productPageData.value
})

const router = useRouter()
function goToProductContentButtonHandler() {
  if (products.activeVariant) {
    router.push(
      `/produkter/${products.activeProduct?.productKey ?? "exphil"}/${
        products.activeVariant.variantKey
      }`,
    )
  } else {
    showVariantModal.value = true
  }
}

window.addEventListener("resize", () => {
  windowAvailWidth.value = window.screen.availWidth
})

const imgTimeout = ref(false)
setTimeout(() => {
  if (!imgTimeout.value) {
    imgTimeout.value = true
  }
}, 5000)
</script>

<template>
  <section class="flex h-dvh w-full items-center justify-center">
    <u-modal
      v-model="showVariantModal"
      @close="showVariantModal = false"
    >
      <template #header>
        <h1
          class="bold font-national2condensed text-h-1-national-2-c text-blue-80"
        >
          Hvor studerer du?
        </h1>
      </template>
      <div class="flex flex-col gap-2">
        <u-button
          v-for="variant in products.activeProduct?.variants.filter(
            v => v.isReleased,
          )"
          :key="variant.shortVariantLabel"
          :to="`/produkter/${products.activeProduct?.productKey}/${variant.variantKey}`"
          size="md"
          theme="blue"
          class="mb-4 w-fit"
          style="--color: white"
          >{{
            variantFullNameMap[
              variant.shortVariantLabel.toLowerCase()
            ] ?? variant.name
          }}</u-button
        >
      </div>
      Finner du ikke ditt studiested?
      <a
        class="underline"
        href="https://om.kunne.universitetsforlaget.no/hjelpesider/kb-tickets/new"
        >Kontakt oss</a
      >
    </u-modal>
    <div
      class="flex h-full min-h-[25vh] w-full max-w-[90em] flex-col-reverse items-center justify-center gap-6 overflow-hidden md:min-h-[45vh] md:w-full md:justify-between lg:min-h-[50vh] lg:flex-row xl:min-h-[65vh]"
    >
      <div
        class="flex h-full w-full flex-col items-center justify-center gap-8 px-4 lg:items-start lg:px-0"
      >
        <h2
          v-if="productPageData"
          class="flex w-full items-center justify-center font-numeraWebRegular text-[24px] leading-relaxed text-[--u-color-dark] sm:text-[30px] lg:max-w-[30vw] lg:justify-start lg:text-[35px] 2xl:text-[40px]"
          v-html="productPageData.header"
        />
        <KsSkeletonWrapper
          v-else
          class="skeleton-wrapper flex h-full w-full flex-col gap-4"
        >
          <KsSkeleton width="35rem" height="3rem" />
          <KsSkeleton width="25rem" height="3rem" />
        </KsSkeletonWrapper>
        <div
          class="flex w-full items-center justify-start gap-2 lg:w-fit"
        >
          <KsSkeletonWrapper
            v-if="!productPageData"
            class="skeleton-wrapper flex h-full w-fit gap-4"
          >
            <KsSkeleton
              border-radius="8px"
              width="9rem"
              height="3rem"
            />
            <KsSkeleton
              border-radius="30px"
              width="9rem"
              height="3rem"
            />
          </KsSkeletonWrapper>

          <u-button
            v-if="productReleased"
            size="md"
            :cta="isAuthorized && hasValidLicence"
            :pill="isAuthorized && hasValidLicence"
            :variant="!hasValidLicence ? 'outline' : 'filled'"
            theme="skin"
            :style="`${isAuthorized ? '--color: white' : ''}`"
            @click="goToProductContentButtonHandler"
          >
            {{
              !isAuthorized
                ? "Prøv gratis"
                : `${isSmall ? "Til produktet" : "Gå til produktet"}`
            }}
          </u-button>

          <u-button
            v-if="
              !hasValidLicence &&
              productReleased &&
              !productIsFree &&
              !isFeatureEnabled
            "
            pill
            cta
            :to="checkoutPageUrl(productParam)"
            size="md"
            variant="filled"
            theme="skin"
            style="--color: white"
          >
            {{ isSmall ? "Kjøp" : "Kjøp tilgang" }}
          </u-button>

          <h3
            v-show="productDataLoaded"
            v-if="productReleased === false"
            class="font-national2condensed text-4xl font-medium text-[--u-color-medium]"
          >
            Produktet lanseres snart, da kan du prøve oss helt gratis
          </h3>
        </div>
      </div>
      <figure
        class="flex h-fit max-w-[90vw] items-center justify-center md:w-full"
      >
        <img
          v-if="getProductImage()"
          loading="eager"
          :src="getProductImage()"
          class="h-full max-h-[35em] w-fit max-w-full rounded-lg object-contain lg:max-h-full"
          :class="{
            'rounded-2xl': !getProductImage(),
          }"
          :alt="licenceImgDesc"
        />
        <KsSkeletonWrapper
          v-else-if="!imgTimeout"
          class="skeleton-wrapper h-full w-full rounded-2xl bg-[--u-color-light]"
        >
          <KsSkeleton class="lg:max-w-[50rem]" height="34em" />
        </KsSkeletonWrapper>
      </figure>
    </div>
  </section>
</template>

<style lang=""></style>

export const useProductValidTo = async () => {
  const store = useDataStore()
  const licence = useLicenceStore()

  const EAN = ref(store.product.ean)

  const licenceData = computed(() => licence.licenceData)
  const getValidTo = () =>
    licenceData.value?.find(i => i.ean === EAN.value)?.endDate || ""

  const validTo = ref(getValidTo())

  watch(store.product, () => {
    EAN.value = store.product.ean
    validTo.value = getValidTo()
  })

  return validTo
}

<script setup lang="ts">
// TODO: Remove this, no longer used

import type { AxiosError } from "axios"
import type { SignupForm } from "~/types/auth"

const { signup, login } = useAuth()
const email = ref("")
const emailSent = ref(false)
const signupError = ref(false)
const showModal = ref(false)

function toggleModal() {
  showModal.value = !showModal.value
}

async function submitSignup(form: SignupForm) {
  signupError.value = false
  email.value = form.email
  try {
    const result = await signup(form)
    if (result) emailSent.value = true
  } catch (error) {
    const e = error as AxiosError
    if (e?.response?.status === 409) {
      signupError.value = true
      toggleModal()
    }
  }
}
</script>

<template>
  <div
    class="pl-9.5 pr-7.5 container flex min-h-[calc(100vh-10rem)] gap-x-20 p-10 py-10 md:px-8"
  >
    <div
      class="justify-left flex h-full w-full items-center rounded-sm text-white"
    >
      <div v-if="!emailSent" class="!xl:max-w-[648px] !max-w-[557px]">
        <p>
          {{
            "Få tilgang til gratis kapittel ved å registrere deg nedenfor."
          }}
          <i>Kunne exphil</i>
          {{
            "hjelper deg å forstå pensum gjennom korte tekster, videoer og oppgaver."
          }}
        </p>

        <u-signup
          class="md:my-5"
          :signup-error="signupError"
          @submit="submitSignup"
        />

        <div class="my-5 flex gap-x-1">
          <p class="small">
            {{ "Har du en bruker allerede?" }}
          </p>
          <u-button
            class="font-numera-regular text-white underline hover:text-u-contrast"
            @click="login()"
          >
            <p class="small">
              {{ "Logg inn her" }}
            </p>
          </u-button>
        </div>

        <p>
          {{
            `Ved å registrere deg som bruker på Kunne exphil samtykker du til vår personvernerklæring,
        og til at vi kan kontakte deg i forbindelse med brukerundersøkelser og informasjon om nytt innhold / endringer
        i funksjonaliteter.`
          }}
        </p>
      </div>

      <div v-else class="!max-w-[448px] text-white">
        <div class="heading-3 mb-5">
          {{ "E-postbekreftelse sendt" }}
        </div>
        <p>
          {{ "En e-post har blitt sendt til" }}
          <strong>{{ email }}</strong
          >.
          <br />
          {{
            "Vennligst sjekk e-posten din for å bekrefte registreringen."
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
h1 {
  @apply pb-8
    font-national2
    text-[40px]
    font-bold
    text-portal-indigo-900;
}

p,
span,
a {
  @apply font-numeraWebRegular
    text-[18px]
    leading-normal
    md:text-[20px] md:leading-normal
    xl:text-[25px] xl:leading-normal;
}
p.small {
  @apply font-national2 text-[22px] leading-normal;
}
a {
  @apply underline;
}
</style>

<route lang="yaml">
name: signup
meta:
  title: Registrer ny bruker
  layout: public
</route>

import type {
  ArticleNodeContent,
  ArticleNodeContentData,
} from "~/types/ibexa.article"
import type { Article, Relation } from "~/types/article"

import createVideo, { formatVideoData } from "./create-video"
import createAudio from "./create-audio"
import createQuiz from "./create-quiz"
import brightnessFormat from "~/utilities/brightness-format"
import { cloneDeep, merge } from "lodash-es"

/**
 *
 * @param node
 * @returns
 */
export function formatData(node: ArticleNodeContent): Article {
  const {
    id,
    hidden,
    priority,
    content,
    parentLocationId,
    parentLocation,
  } = node

  const {
    template,
    title,
    shortTitle,
    intro,
    body,
    relatedArticles,
    _type: { identifier },
    _url,
    bg,
    color,
    image,
    imageCaption,
    imageSize,
    hasPictureFrame,
    pictureColor,
    pictureText,
    _info: {
      currentVersion: {
        versionNumber,
        creationDate,
        modificationDate,
      },
      relations,
    },
  } = content as ArticleNodeContentData

  const [video] = createVideo(relatedArticles)
  const [audio] = createAudio(relatedArticles)
  const [quiz] = createQuiz(relatedArticles)

  const formatedRelations = relations.map(
    ({ destinationContent: content }): Relation => {
      switch (content._type?.identifier) {
        case "source": {
          return {
            identifier: content._type.identifier,
            resourceUrl: content._url,
            title: content.title,
            html: null,
            resource: {
              type: content.type,
              author: content.author,
              title: content.title,
              edition: content.edition,
              publisher: content.publisher,
              publishYear: content.publishYear,
              publishDate: content.publishDate
                ? new Date(content.publishDate.timestamp)
                : null,
              url: content.url,
            },
          }
        }

        case "term": {
          return {
            identifier: content._type.identifier,
            resourceUrl: content._url,
            title: content.title,
            html: null,
            resource: {
              definition: content.definition.html5,
              explanation: content.explanation.html5,
              isFlashcard: content.isFlashcard,
            },
          }
        }

        case "person": {
          return {
            identifier: content._type.identifier,
            resourceUrl: content._url,
            title: content.name,
            html: null,
            resource: {
              birthYear: content.birthYear,
              deceasedYear: content.deceasedYear,
              about: content.about.html5,
            },
            image: content.image,
            imageCaption: content.imageCaption,
          }
        }

        default: {
          return {
            identifier: "sidenote",
            title: content?.title ?? content.id,
            html: content?.richtext?.html5 ?? "<Tomt notat>",
            resourceUrl: content._url,
            image: content.image,
            video: content.video
              ? formatVideoData(content.video)
              : null,
            ...(content.richtext === null
              ? null
              : { html: content.richtext?.html5 }),
          }
        }
      }
    },
  )

  /** Function to format links and references. This also mutates relations! */
  function formatHtml(html: string) {
    let autoIncrementId = 0
    let autoIncrementSourceTitle = 0
    const parsed = new DOMParser().parseFromString(html, "text/html")

    parsed.querySelectorAll("a").forEach(el => {
      const [isRef = false, appendText] =
        el.textContent?.trim().match(/^\[(?:\*|\d+)(.+)?\]$/) ?? []
      let relation = formatedRelations.find(
        ({ resourceUrl }) => resourceUrl === el.getAttribute("href"),
      )

      if (relation !== null) {
        autoIncrementId++

        relation = merge(cloneDeep(relation), {
          resourceUrl: `${autoIncrementId}.${relation?.resourceUrl}`,
        })

        el.href = `#${relation.resourceUrl}`
        el.classList.add("text-link", "text-link--sidenote")

        formatedRelations.push(relation)

        if (isRef) {
          relation.appendText = appendText
          relation.identifier = "source"
        }

        switch (relation.identifier) {
          case "source": {
            autoIncrementSourceTitle++
            relation.title = `${autoIncrementSourceTitle}.`
            el.innerHTML = `<sup>[${autoIncrementSourceTitle}]</sup>`
            el.classList.add("text-link--source")

            if (
              relation.appendText !== null &&
              relation.html !== null
            ) {
              const { html, appendText } = relation
              const index = html?.lastIndexOf(".")
              if (index >= 0) {
                relation.html = `${html.substring(
                  0,
                  index,
                )}, ${appendText}.${html.substring(index + 1)}`
              }
            }
            break
          }

          case "explainer":
          case "sidenote": {
            relation.identifier = "explainer"
            el.classList.add("text-link--explainer")
            break
          }
        }
      }
    })

    return parsed.body.innerHTML
  }

  return {
    id,
    hidden,
    priority,
    parentLocationId,
    parentLocation,
    template:
      "U" + onlyChar(capitalizeChar(camalize(template))) + "Article",
    title,
    shortTitle,
    intro: {
      html: formatHtml(intro.html5),
    },
    body: {
      html: formatHtml(body.html5),
      plaintext: body.plaintext,
    },
    img: {
      id: image?.id,
      src: image?.variation?.uri,
      alt: image?.alternativeText,
      focalPointX: image?.additionalData?.focalPointX,
      focalPointY: image?.additionalData?.focalPointY,
      width: image?.width,
      height: image?.height,
    },
    imageCaption: {
      html: imageCaption?.html5 === "\n" ? "" : imageCaption?.html5,
    },
    imageSize,
    hasPictureFrame,
    pictureColor,
    pictureText: {
      html: pictureText?.html5 === "\n" ? "" : pictureText?.html5,
    },
    bg,
    color,
    isDark: brightnessFormat(bg),
    type: identifier,
    video,
    audio,
    quiz,
    created: creationDate?.timestamp,
    modified: modificationDate?.timestamp,
    versionNumber,
    relations: formatedRelations,
    href: _url.replace(new RegExp(/[a-zA-Z]+\//i), ""),
  }
}

<script setup>
import { setBodyBackground } from "../utilities/theme"
import UPageHeader from "~/components/UPageHeader.vue"

setBodyBackground("dark")
</script>

<template>
  <div
    class="flex min-h-screen flex-col items-center justify-between"
  >
    <div class="w-[90vw] md:w-[80vw]">
      <UPageHeader :dark="false" />
      <main>
        <router-view />
      </main>
    </div>
    <UPublicFooter />
  </div>
</template>
